import { Redirect, Route, Switch } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Routes } from '../../Routes';
import { LogsPage } from './Logs';
import { OrganizationRolesPage } from './OrganizationRoles';
import { RolesPage } from './Roles';
import { TokensPage } from './Tokens';

interface IAdminPageProps {
    appState: AppState;
}

export function AdminPage(props: IAdminPageProps) {
    return (
        <Switch>
            <Route path={Routes.admin(props.appState.memberId)} exact>
                <Redirect to={Routes.adminRoles(props.appState.memberId)} />
            </Route>
            <Route path={Routes.adminRoles(props.appState.memberId)}>
                <RolesPage {...props} />
            </Route>
            <Route path={Routes.adminOrganizationRoles(props.appState.memberId)}>
                <OrganizationRolesPage {...props} />
            </Route>
            <Route path={Routes.adminLogs(props.appState.memberId)}>
                <LogsPage {...props} />
            </Route>
            <Route path={Routes.adminTokens(props.appState.memberId)}>
                <TokensPage {...props} />
            </Route>
            <Route>
                <Redirect to={Routes.admin(props.appState.memberId)} />
            </Route>
        </Switch>
    );
}
