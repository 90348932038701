import { observer } from 'mobx-react';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppState } from '../AppState';
import { Auth } from './routes/auth/Auth';
import { Routes } from './routes/Routes';
import Login from './routes/unauthorized/Login';
import Register from './routes/unauthorized/Register';
import { RegisterConfirm } from './routes/unauthorized/RegisterConfirm';
import { Resend } from './routes/unauthorized/Resend';
import { Reset } from './routes/unauthorized/Reset';
import { ResetConfirm } from './routes/unauthorized/ResetConfirm';
import { ShadowRegister } from './routes/unauthorized/ShadowRegister';
import { Loading } from './utils/Loading';
import { RedirectPopBacklink } from './utils/RedirectPopBacklink';
import { RedirectStoreBacklink } from './utils/RedirectStoreBaclink';

interface IAppProps {}

interface IAppState {}

@observer
export default class App extends React.Component<IAppProps, IAppState> {
    state: IAppState = {};

    private appState: AppState = new AppState();

    public constructor(props: IAppProps) {
        super(props);

        this.appState.checkAuthorization();
    }

    render() {
        if (this.appState.loading) {
            return <Loading />;
        }

        // TODO: Do title changes here

        if (this.appState.authorized) {
            return (
                <Switch>
                    <Route path={Routes.auth()}>
                        <Auth appState={this.appState} />
                    </Route>
                    <Route>
                        <RedirectPopBacklink to={Routes.auth()} />
                    </Route>
                </Switch>
            );
        } else {
            return (
                <Switch>
                    <Route path={Routes.login()}>
                        <Login appState={this.appState} />
                    </Route>
                    <Route path={Routes.register()} component={Register} />
                    <Route path={Routes.passwordReset()} component={Reset} />
                    <Route path={Routes.registerConfirm()} component={RegisterConfirm} />
                    <Route path={Routes.resetConfirm()} component={ResetConfirm} />
                    <Route path={Routes.resend()} component={Resend} />
                    <Route path={Routes.shadowRegister()} component={ShadowRegister} />
                    <Route>
                        <RedirectStoreBacklink to={Routes.login()} />
                    </Route>
                </Switch>
            );
        }
    }
}
