import { gql, useMutation, useQuery } from '@apollo/client';
import { IconCheckbox, IconQuestionMark, IconSquareX } from '@tabler/icons';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AppState } from '../../../../AppState';
import { Meeting } from '../../../../api/entities/Meeting';
import { Translate } from '../../../../utils/Translate';
import { TWIX_FORMAT } from '../../../../utils/twixFormat';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { ListEndText } from '../../../utils/ListEndText';
import { Spinner } from '../../../utils/Spinner';
import { Form } from '../../../utils/inputs/Form';
import { FormInput } from '../../../utils/inputs/FormInput';
import { date, required } from '../../../utils/inputs/inputValidators/validators';
import { getRegularMeetings } from './getRegularMeetings';

interface IMeetingsPlannerPageProps {
    appState: AppState;
}

type Schedule = {
    from: Moment;
    to: Moment;
    happening: 'yes' | 'no' | 'undecided';
    meeting: Meeting;
    note?: string | null;
    color: string;
    id?: number;
};

const colors = ['primary', 'danger', 'success', 'warning', 'secondary', 'dark', 'light'];

export function MeetingsPlannerPage(props: IMeetingsPlannerPageProps) {
    const [selected, setSelected] = useState<Schedule | null>(null);

    const {
        loading: l1,
        error: e1,
        data,
        refetch,
    } = useQuery(
        gql`
            query MeetingScheduler($memberId: Int!) {
                auth {
                    as(memberId: $memberId) {
                        meetings(filter: { current: true }) {
                            items {
                                id
                                current
                                title
                                seasonFrom
                                seasonTo
                                firstFrom
                                firstTo
                                frequency
                                frequencyEvery
                                schedules {
                                    id
                                    note
                                    from
                                    to
                                    happening
                                    note
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                memberId: props.appState.activeMember.id,
            },
        },
    );

    const [create, { loading: l3, error: e3 }] = useMutation(gql`
        mutation MeetingSchedulerCreate($memberId: Int!, $value: MeetingScheduleInput!) {
            auth {
                as(memberId: $memberId) {
                    createMeetingSchedule(value: $value) {
                        id
                    }
                }
            }
        }
    `);

    const [update, { loading: l2, error: e2 }] = useMutation(gql`
        mutation MeetingSchedulerUpdate($memberId: Int!, $id: Int!, $value: MeetingScheduleInput!) {
            auth {
                as(memberId: $memberId) {
                    updateMeetingSchedule(meetingScheduleId: $id, value: $value) {
                        id
                    }
                }
            }
        }
    `);

    const [remove, { loading: l4, error: e4 }] = useMutation(gql`
        mutation MeetingSchedulerDelete($memberId: Int!, $id: Int!) {
            auth {
                as(memberId: $memberId) {
                    deleteMeetingSchedule(meetingScheduleId: $id)
                }
            }
        }
    `);

    if (l1) return <Spinner />;
    if (e1) return <ErrorDisplay error={e1} />;
    if (e2) return <ErrorDisplay error={e2} />;
    if (e3) return <ErrorDisplay error={e3} />;
    if (e4) return <ErrorDisplay error={e4} />;

    const meetings: Meeting[] = data.auth.as.meetings.items;

    if (meetings.length === 0) {
        return (
            <div className="col-12">
                <h1>{Translate.message('meetings.planner.title', 'Plánovač schůzek')}</h1>
                <h6>
                    {Translate.message(
                        'meetings.planner.subtitle',
                        'Je důležté, aby všichni věděli kdy mají na schůzku dorazit!',
                    )}
                </h6>
                <ListEndText>
                    {Translate.message(
                        'meetings.planner.noMeetings',
                        'Žádné schůzky pro aktuální období nebyly nalezeny!',
                        { gender: props.appState.memberDetails!.gender },
                    )}
                </ListEndText>
            </div>
        );
    }

    const eventToClass = (event: Schedule) => {
        if (event.happening === 'yes') {
            return `event-yes text-white bg-${event.color} border-${event.color}`;
        }
        if (event.happening === 'no') {
            return `event-no text-${event.color} border-${event.color}`;
        }
        if (event.happening === 'undecided') {
            return `event-undecided text-${event.color} border-${event.color}`;
        }
    };

    const firstWeek = moment.max(
        moment(
            meetings.reduce((prev, meet) =>
                moment(prev.seasonFrom).unix() < moment(meet.seasonFrom).unix() ? prev : meet,
            ).seasonFrom,
        ).startOf('week'),
        moment().startOf('week'),
    );
    const lastWeek = moment(
        meetings.reduce((prev, meet) => (moment(prev.seasonTo).unix() < moment(meet.seasonTo).unix() ? meet : prev))
            .seasonTo,
    ).endOf('week');

    let weeks = [];
    for (let current = firstWeek; current.isBefore(lastWeek); current.add(1, 'week')) {
        weeks.push(moment(current));
    }

    return (
        <div className="col-12">
            <h1>{Translate.message('meetings.planner.title', 'Plánovač schůzek')}</h1>
            <h6>
                {Translate.message(
                    'meetings.planner.subtitle',
                    'Je důležté, aby všichni věděli kdy mají na schůzku dorazit!',
                )}
            </h6>

            <div className="mt-4">
                {Translate.message('meetings.planner.addDifferent', 'Přidat jiný plán pro schůzku')}
                <ul>
                    {meetings
                        .sort((a, b) => moment(a.firstFrom).unix() - moment(b.firstFrom).unix())
                        .map((meeting) => (
                            <li
                                className="link"
                                onClick={() =>
                                    setSelected({
                                        from: moment(),
                                        to: moment(),
                                        color: null as any,
                                        happening: 'undecided',
                                        meeting,
                                    })
                                }
                            >
                                {meeting.title}
                            </li>
                        ))}
                </ul>
            </div>

            <Modal show={!!selected} centered onHide={() => setSelected(null)}>
                {selected && (
                    <Form
                        defaultValues={{
                            from: selected.from.format('YYYY-MM-DD HH:mm'),
                            to: selected.to.format('YYYY-MM-DD HH:mm'),
                            note: selected.note || null,
                            happening: { yes: 1, no: -1, undecided: 0 }[selected.happening],
                        }}
                        validators={(data) => ({
                            from: [required, date],
                            to: [required, date],
                            note: [],
                            happening: [],
                        })}
                        onSubmit={async (data) => {
                            const result = {
                                from: moment(data.from).toISOString(),
                                to: moment(data.to).toISOString(),
                                note: !data.note || data.note.length === 0 ? null : data.note,
                                happening: { [-1]: 'no', 1: 'yes', 0: 'undecided' }[(data.happening || 0) as number],
                            };

                            if (selected!.id) {
                                await update({
                                    variables: {
                                        memberId: props.appState.activeMember.id,
                                        id: selected!.id,
                                        value: result,
                                    },
                                });
                            } else {
                                await create({
                                    variables: {
                                        memberId: props.appState.activeMember.id,
                                        value: { ...result, meeting: selected.meeting.id },
                                    },
                                });
                            }
                            refetch();
                            setSelected(null);
                        }}
                    >
                        {(inputProps, state, buttonProps) => (
                            <>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {selected && (
                                            <>
                                                <h3>
                                                    {Translate.message(
                                                        'meetings.planner.modal.title',
                                                        'Upravit plán schůzky',
                                                    )}{' '}
                                                    - {selected.meeting.title}
                                                </h3>
                                            </>
                                        )}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {l2 || l3 || l4 ? (
                                        <Spinner />
                                    ) : (
                                        <div className="">
                                            <FormInput
                                                {...inputProps('from')}
                                                className="mt-0"
                                                type="datetime-local"
                                                label={Translate.message('meetings.planner.fields.from', 'Začátek')}
                                            />
                                            <FormInput
                                                {...inputProps('to')}
                                                className="mt-3"
                                                type="datetime-local"
                                                label={Translate.message('meetings.planner.fields.to', 'Konec')}
                                            />
                                            <FormInput
                                                {...inputProps('happening')}
                                                className="mt-3"
                                                type="triway"
                                                label={Translate.message(
                                                    'meetings.planner.fields.happening',
                                                    'Uskuteční se schůzka?',
                                                )}
                                            />
                                            <FormInput
                                                {...inputProps('note')}
                                                className="mt-4"
                                                type="textarea"
                                                label={Translate.message('meetings.planner.fields.note', 'Poznámka')}
                                            />
                                        </div>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    {selected!.id && (
                                        <ConfirmButton
                                            label={Translate.message('meetings.planner.modal.delete', 'Odstranit plán')}
                                            onConfirm={async () => {
                                                await remove({
                                                    variables: {
                                                        memberId: props.appState.activeMember.id,
                                                        id: selected!.id,
                                                    },
                                                });
                                                refetch();
                                                setSelected(null);
                                            }}
                                        />
                                    )}
                                    <Button {...buttonProps} variant="primary">
                                        {Translate.message('meetings.planner.modal.save', 'Uložit plán')}
                                    </Button>
                                </Modal.Footer>
                            </>
                        )}
                    </Form>
                )}
            </Modal>

            <div>
                {weeks.map((week, i) => (
                    <div className="block col-xl-8 col-12">
                        <h3 className="mb-3 mx-3">
                            {Translate.message('meetings.planner.weekFrom', 'Týden od ')} {week.format('D. MMMM YYYY')}
                        </h3>
                        <div className="mt-2">
                            {meetings
                                .filter(
                                    (meeting) =>
                                        moment(meeting.seasonFrom).isBefore(moment(week).add(1, 'week')) &&
                                        moment(meeting.seasonTo).isAfter(week),
                                )
                                .map((meeting) => getRegularMeetings(meeting, week, moment(week).add(1, 'week')))
                                .reduce(
                                    (prev, curr, i) => [
                                        ...prev,
                                        ...curr.map((schedule) => ({
                                            from: moment(schedule.from),
                                            to: moment(schedule.to),
                                            happening: schedule.happening,
                                            meeting: meetings[i],
                                            note: schedule.note,
                                            color: colors[i % colors.length],
                                            id: schedule.id,
                                        })),
                                    ],
                                    [] as Schedule[],
                                )
                                .sort((a, b) => a.from.unix() - b.from.unix())
                                .map((schedule) => (
                                    <div
                                        className={`link calendar-event event-detail d-flex flex-row ${eventToClass(
                                            schedule,
                                        )}`}
                                        onClick={() => setSelected(schedule)}
                                    >
                                        {
                                            {
                                                no: <IconSquareX className="flex-shrink-0 mt-1 me-3" size="2.5em" />,
                                                yes: <IconCheckbox className="flex-shrink-0 mt-1 me-3" size="2.5em" />,
                                                undecided: (
                                                    <IconQuestionMark
                                                        className="flex-shrink-0 mt-1 me-3"
                                                        size="2.5em"
                                                    />
                                                ),
                                            }[schedule.happening]
                                        }
                                        <div>
                                            <div>{schedule.meeting.title}</div>
                                            <div className="details">
                                                {schedule.from.twix(schedule.to).format(TWIX_FORMAT)}
                                            </div>
                                            <div
                                                className="details"
                                                style={{ whiteSpace: 'normal', fontStyle: 'italic' }}
                                            >
                                                {schedule.note}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
