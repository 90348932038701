import { IconUser } from '@tabler/icons';
import { AppState } from '../../AppState';
import { Event } from '../../api/entities/Event';
import { ProfilePreview } from './ProfilePreview';

interface IEventAttendeesProps {
    appState: AppState;
    event: Event;
}

export function EventAttendees(props: IEventAttendeesProps) {
    const { appState, event } = props;

    const attending = event.attendees
        .filter((attendance) => attendance.answer === 'yes')
        .map((attendee, key) => ({
            info: attendee.member,
            jsx: <ProfilePreview member={attendee.member} key={key} appState={appState} />,
        }));
    const external = event.attendeesExternal
        .filter((attendance) => attendance.answer === 'yes')
        .map((attendee, key) => ({
            info: attendee,
            jsx: (
                <div className="profile-preview">
                    <div
                        title={attendee.nickname}
                        className="shadow rounded-circle bg-white d-inline-block text-light text-center me-3 my-2"
                        style={{ width: 40, height: 40 }}
                    >
                        <IconUser className="h-100" />
                    </div>
                    <span className="pe-2">
                        {attendee.firstname} "{attendee.nickname}" {attendee.lastname}
                    </span>
                </div>
            ),
        }));

    // const children = attending.filter((attendee) => !attendee.member.hasPublicInfo);
    // const counselors = attending.filter((attendee) => attendee.member.hasPublicInfo);

    return (
        <>
            <div className="my-4 cols-1 cols-xl-4 cols-lg-2">
                {[...attending, ...external]
                    .sort(
                        (a, b) =>
                            a.info.lastname.localeCompare(b.info.lastname) ||
                            a.info.firstname.localeCompare(b.info.firstname),
                    )
                    .map((item) => item.jsx)}
            </div>
        </>
    );
}
