import { TablerIcon } from '@tabler/icons';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppState } from '../../../AppState';
import { classNames } from '../../../utils/classNames';

export enum EventTaskState {
    DONE = 'done',
    MISSED = 'missed',
    DUE = 'due',
    UPCOMING = 'upcoming',
}

interface IEventTaskProps {
    appState: AppState;
    icon: TablerIcon;
    state: EventTaskState;
    title: string;
    link?: string;
    big?: boolean;
}

export function EventTask(props: IEventTaskProps) {
    const opts = {
        done: 'success',
        missed: 'danger',
        due: 'warning',
        upcoming: 'dark-50',
    };

    const Icon = props.icon;

    if (props.link) {
        if (props.big) {
            return (
                <Link
                    to={props.link}
                    className={classNames('event-task', 'bg-' + opts[props.state], 'big')}
                    title={props.title}
                >
                    <Icon size={36} strokeWidth={1.7} />
                </Link>
            );
        } else {
            return (
                <Link
                    to={props.link}
                    className={classNames('event-task', 'bg-' + opts[props.state])}
                    title={props.title}
                >
                    <Icon />
                </Link>
            );
        }
    }

    if (props.big) {
        return (
            <div className={classNames('event-task', 'bg-' + opts[props.state], 'big')} title={props.title}>
                <Icon size={36} strokeWidth={1.7} />
            </div>
        );
    } else {
        return (
            <div className={classNames('event-task', 'bg-' + opts[props.state])} title={props.title}>
                <Icon />
            </div>
        );
    }
}
