import * as tabler from '@tabler/icons';

interface IIconByNameProps extends tabler.TablerIconProps {
    icon: keyof typeof icons;
}

export function IconByName(props: IIconByNameProps) {
    const icon = icons[(props.icon as string).replaceAll('-', '').toLowerCase() as keyof typeof icons];
    return <>{icon ? icon(props) : icons['bug'](props)}</>;
}

export const icons: { [key: string]: (props: tabler.TablerIconProps) => tabler.TablerIcon } = Object.entries(tabler)
    .filter(([key]) => key.startsWith('Icon'))
    .reduce(
        (prev, [key, Component]) => ({
            ...prev,
            [key.slice(4).toLowerCase()]: (props: tabler.TablerIconProps) => <Component {...props} />,
        }),
        {},
    );
