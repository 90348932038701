import * as React from 'react';
import { Api } from '../../../../api/Api';
import { Translate } from '../../../../utils/Translate';
import { LiveInput } from '../../../utils/inputs/LiveInput';
import { IProfileEditorProps } from './ProfileEditor';

export function BasicInfoEditor(props: IProfileEditorProps) {
    const { appState, member } = props;

    const [isStudent, setStudent] = React.useState(member.isStudent);
    const [isEmployee, setEmployee] = React.useState(member.isEmployee);

    return (
        <div>
            <h3>{Translate.message('profilePage.contacts', 'Kontakty')}</h3>
            <div className="columns">
                <div className="col-12 col-md-6 d-inline-block align-top">
                    <LiveInput
                        type="email"
                        initialValue={member.email}
                        label={Translate.message('profilePage.email', 'Email')}
                        mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'email', 'String')}
                    />
                </div>
                <div className="col-12 col-md-6 d-inline-block align-top">
                    <LiveInput
                        type="text"
                        initialValue={member.phone}
                        label={Translate.message('profilePage.phone', 'Telefon')}
                        mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'phone', 'String')}
                    />
                </div>
                <LiveInput
                    type="textarea"
                    initialValue={member.address}
                    label={Translate.message('profilePage.address', 'Bydliště')}
                    mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'address', 'String')}
                    className="w-100"
                />
            </div>
            <h3 className="section-heading">{Translate.message('profilePage.studyWork', 'Studium a zaměstnání')}</h3>
            <div className="columns">
                <div className="col-12 col-md-6 d-inline-block">
                    <LiveInput
                        type="checkbox"
                        initialValue={member.isStudent}
                        label={Translate.message('profilePage.isStudent', 'Jsem student', {
                            gender: member.gender,
                        })}
                        mutation={Api.mutations.member.update(
                            appState.activeMember.id,
                            member.id,
                            'isStudent',
                            'Boolean',
                        )}
                        onChange={(value) => setStudent(value)}
                    />
                    <LiveInput
                        type="text"
                        initialValue={member.school}
                        label={Translate.message('profilePage.school', 'Škola')}
                        mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'school', 'String')}
                        disabled={!isStudent}
                    />
                    <LiveInput
                        type="text"
                        initialValue={member.schoolClass}
                        label={Translate.message('profilePage.schoolClass', 'Třída')}
                        mutation={Api.mutations.member.update(
                            appState.activeMember.id,
                            member.id,
                            'schoolClass',
                            'String',
                        )}
                        disabled={!isStudent}
                    />
                </div>
                <div className="col-12 col-md-6 d-inline-block">
                    <LiveInput
                        type="checkbox"
                        initialValue={member.isEmployee}
                        label={Translate.message('profilePage.isEmployee', 'Jsem zaměstnanec', {
                            gender: member.gender,
                        })}
                        mutation={Api.mutations.member.update(
                            appState.activeMember.id,
                            member.id,
                            'isEmployee',
                            'Boolean',
                        )}
                        onChange={(value) => setEmployee(value)}
                    />
                    <LiveInput
                        type="text"
                        initialValue={member.job}
                        label={Translate.message('profilePage.job', 'Zaměstnání')}
                        mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'job', 'String')}
                        disabled={!isEmployee}
                    />
                    <LiveInput
                        type="text"
                        initialValue={member.employer}
                        label={Translate.message('profilePage.employer', 'Zaměstnavatel')}
                        mutation={Api.mutations.member.update(
                            appState.activeMember.id,
                            member.id,
                            'employer',
                            'String',
                        )}
                        disabled={!isEmployee}
                    />
                </div>
            </div>
            <h3 className="section-heading">{Translate.message('profilePage.otherInfoSection', 'Další sdělení')}</h3>
            <div className="columns">
                <LiveInput
                    type="textarea"
                    initialValue={member.otherInfo}
                    label={Translate.message('profilePage.otherInfo', 'Další důležitá zdravotní a jiná sdělení')}
                    mutation={Api.mutations.member.update(appState.activeMember.id, member.id, 'otherInfo', 'String')}
                    className="w-100"
                />
            </div>
        </div>
    );
}
