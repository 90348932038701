import { gql, useMutation } from '@apollo/client';
import { Redirect } from 'react-router';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { Routes } from '../../Routes';
import { MeetingInfoEditor } from './editors/MeetingInfoEditor';

interface IMeetingsNewProps {
    appState: AppState;
}

export function MeetingsNewPage(props: IMeetingsNewProps) {
    const [create, { loading, error, data }] = useMutation(gql`
        mutation CreateMeeting($memberId: Int!, $value: MeetingInput!) {
            auth {
                as(memberId: $memberId) {
                    createMeeting(value: $value) {
                        id
                    }
                }
            }
        }
    `);

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;
    if (data) return <Redirect to={Routes.meetingsMyDetail(props.appState.memberId, data.auth.as.createMeeting.id)} />;

    return (
        <div className="col-12">
            <h1>{Translate.message('meetings.new.title', 'Nová schůzka')}</h1>
            <h6>{Translate.message('meetings.new.subtitle', 'Vytvoř novou schůzku a doplň k ní základní údaje')}</h6>

            <MeetingInfoEditor
                appState={props.appState}
                onSubmit={(value) =>
                    create({
                        variables: {
                            memberId: props.appState.activeMember.id,
                            value,
                        },
                    })
                }
            />
        </div>
    );
}
