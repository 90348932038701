import * as React from 'react';
import ReactQuill from 'react-quill';
import { v4 as uuid } from 'uuid';
import { AppState } from '../../../AppState';
import { classNames } from '../../../utils/classNames';
import { quillProps } from '../../../utils/quillProps';

export type inputWrapperTypes =
    | 'text'
    | 'email'
    | 'number'
    | 'textarea'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'password'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'editor'
    | 'color'
    | 'triway';

export interface IFormInputProps {
    type: inputWrapperTypes;
    value?: string | number | boolean | null | undefined;
    label?: string | JSX.Element;
    options?: {
        title: string;
        value: any;
    }[];
    formattedType?: 'quill' | 'html' | 'text' | 'md';
    disabled?: boolean;
    className?: string;
    error?: string;
    onChange?: (value: any) => void;
    onBlur?: (value: any) => void;
    onSubmit?: (value: any) => void;
    additionalJSX?: JSX.Element;
    style?: React.CSSProperties;
    appState?: AppState;
}

export function FormInput(props: IFormInputProps) {
    const [id] = React.useState('input-' + uuid());
    const [quillPropsRes] = React.useState(props.appState ? quillProps(props.appState) : {});

    const onChange = (value: any) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    const onBlur = (value: any) => {
        if (props.onBlur) {
            props.onBlur(value);
        }
    };

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        if (props.onSubmit) {
            props.onSubmit(value);
        }
    };

    let { value, disabled, error, label, type, className, additionalJSX, options, appState } = props;
    label = label || '';
    options = options || [];

    const stringLabel = typeof label === 'string' ? label : '';

    switch (type) {
        case 'textarea':
            return (
                <form
                    className={classNames('form-floating align-top', className)}
                    style={props.style}
                    onSubmit={onSubmit}
                >
                    <textarea
                        className={classNames('form-control', error && 'is-invalid')}
                        value={value as any}
                        id={id}
                        placeholder={stringLabel}
                        onChange={({ target: { value } }) => {
                            onChange(value);
                        }}
                        onBlur={({ target: { value } }) => {
                            onBlur(value);
                        }}
                        disabled={disabled}
                        style={{ height: 110 }}
                        onKeyDown={(event: React.KeyboardEvent) => {
                            if (event.key === 'Enter' && event.ctrlKey) {
                                onSubmit(event as any);
                            }
                        }}
                    />
                    <label htmlFor={id}>{label}</label>
                    {error && <div className="invalid-feedback">{error}</div>}
                    {additionalJSX}
                </form>
            );

        case 'checkbox':
            return (
                <form
                    className={classNames('form-check mt-4 align-top', className)}
                    style={{ position: 'relative', ...props.style }}
                    onSubmit={onSubmit}
                >
                    <input
                        className={classNames('form-check-input', error && 'is-invalid')}
                        type="checkbox"
                        name="isEmployee"
                        id="isEmployee"
                        checked={value as any}
                        onChange={({ target: { checked } }) => {
                            onChange(checked);
                            onBlur(checked);
                        }}
                        disabled={disabled}
                    />
                    <label className="form-check-label" htmlFor={id}>
                        {label}
                    </label>
                    {error && <div className="invalid-feedback">{error}</div>}
                    {additionalJSX}
                </form>
            );

        case 'triway':
            return (
                <form
                    className={classNames('form-check mt-4 align-top', className)}
                    style={{ position: 'relative', ...props.style }}
                    onSubmit={onSubmit}
                >
                    <TriwayCheckbox
                        {...{
                            error,
                            onChange,
                            onBlur,
                            disabled: !!disabled,
                            value: typeof value === 'number' ? value : 0,
                        }}
                    />
                    <label className="form-check-label" htmlFor={id} style={disabled ? { opacity: 0.5 } : {}}>
                        {label}
                    </label>
                    {error && <div className="invalid-feedback">{error}</div>}
                    {additionalJSX}
                </form>
            );

        case 'radio':
            return (
                <form onSubmit={onSubmit}>
                    {options.map((opt, i) => (
                        <div
                            key={i}
                            className={classNames('form-check mt-4 align-top', className)}
                            style={{ position: 'relative', ...props.style }}
                        >
                            <input
                                className={classNames('form-check-input', error && 'is-invalid')}
                                type="radio"
                                name="isEmployee"
                                id="isEmployee"
                                checked={opt.value === value}
                                onChange={({ target: { checked } }) => {
                                    checked && onChange(opt.value);
                                    checked && onBlur(opt.value);
                                }}
                                disabled={disabled}
                            />
                            <label className="form-check-label" htmlFor={id}>
                                {opt.title}
                            </label>
                            {i === options!.length - 1 && error && <div className="invalid-feedback">{error}</div>}
                            {i === options!.length - 1 && additionalJSX}
                        </div>
                    ))}
                </form>
            );

        case 'select':
            return (
                <form
                    className={classNames('form-floating align-top', className)}
                    style={props.style}
                    onSubmit={onSubmit}
                >
                    <select
                        className={classNames('form-control', error && 'is-invalid')}
                        id={id}
                        onChange={({ target: { value } }) => {
                            onChange(value);
                        }}
                        onBlur={({ target: { value } }) => {
                            onBlur(value);
                        }}
                        disabled={disabled}
                        defaultValue={value as any}
                    >
                        {options.map((opt, i) => (
                            <option key={i} value={opt.value}>
                                {opt.title}
                            </option>
                        ))}
                    </select>
                    <label htmlFor={id}>{label}</label>
                    {error && <div className="invalid-feedback">{error}</div>}
                    {additionalJSX}
                </form>
            );

        case 'editor':
            if (!appState) {
                console.error('[Quill] AppState not passed into FormInput!');
                return <></>;
            }
            return (
                <div className={classNames('form-floating align-top', className)} style={props.style}>
                    {(() => {
                        let type = props.formattedType || 'quill';
                        let content = undefined;
                        if (value) {
                            const match = (value as any).match(/^!([a-z]*)\s(.*)/s);
                            if (match) {
                                type = match[1];
                                content = match[2];
                            } else {
                                type = 'invalid' as any;
                            }
                        }
                        console.log(type);
                        switch (type) {
                            /* text, html, quill, md */
                            case 'quill':
                                const json = content ? JSON.parse(content) : undefined;
                                return (
                                    <ReactQuill
                                        {...quillPropsRes}
                                        theme="snow"
                                        className={classNames(error && 'is-invalid', disabled && 'disabled')}
                                        defaultValue={json as any}
                                        id={id}
                                        onChange={(content, delta, source, editor) =>
                                            onChange('!quill ' + JSON.stringify(editor.getContents().ops))
                                        }
                                        onBlur={(prevRange, source, editor) =>
                                            onBlur('!quill ' + JSON.stringify(editor.getContents().ops))
                                        }
                                        readOnly={disabled}
                                        key={id}
                                        onKeyDown={(event: React.KeyboardEvent) => {
                                            if (event.key === 'Enter' && event.ctrlKey) {
                                                onSubmit(event as any);
                                            }
                                        }}
                                    />
                                );
                            case 'html':
                            case 'text':
                            case 'md':
                                return (
                                    <>
                                        <textarea
                                            className={classNames('form-control', error && 'is-invalid')}
                                            value={content as any}
                                            id={id}
                                            placeholder={
                                                stringLabel + { md: ' (Markdown)', text: '', html: ' (HTML)' }[type]
                                            }
                                            onChange={({ target: { value } }) => {
                                                onChange(`!${type} ` + value);
                                            }}
                                            onBlur={({ target: { value } }) => {
                                                onBlur(`!${type} ` + value);
                                            }}
                                            disabled={disabled}
                                            style={{ height: 110 }}
                                            onKeyDown={(event: React.KeyboardEvent) => {
                                                if (event.key === 'Enter' && event.ctrlKey) {
                                                    onSubmit(event as any);
                                                }
                                            }}
                                        />
                                        <label htmlFor={id}>
                                            {label + { md: ' (Markdown)', text: '', html: ' (HTML)' }[type]}
                                        </label>
                                    </>
                                );
                        }
                    })()}

                    {error && <div className="invalid-feedback">{error}</div>}
                    {additionalJSX}
                </div>
            );

        default:
            return (
                <form
                    className={classNames('form-floating align-top', className)}
                    style={props.style}
                    onSubmit={onSubmit}
                >
                    <input
                        type={type}
                        className={classNames('form-control', error && 'is-invalid')}
                        value={value as any}
                        id={id}
                        placeholder={stringLabel}
                        onChange={({ target: { value } }) => {
                            onChange(value);
                        }}
                        onBlur={({ target: { value } }) => {
                            onBlur(value);
                        }}
                        disabled={disabled}
                    />
                    <label htmlFor={id}>{label}</label>
                    {error && <div className="invalid-feedback">{error}</div>}
                    {additionalJSX}
                </form>
            );
    }
}

export function TriwayCheckbox({
    value,
    error,
    onChange,
    onBlur,
    disabled,
}: {
    value: number;
    error: any;
    onChange: (value: number) => void;
    onBlur: (value: number) => void;
    disabled: boolean;
}) {
    const [_value, setValue] = React.useState(value + 1);

    return (
        <div
            className={classNames(
                'form-check-input input-triway shadow rounded',
                ['triway-no', 'triway-maybe', 'triway-yes'][_value],
                error && 'is-invalid',
                disabled && 'disabled',
            )}
            style={disabled ? { opacity: 0.5 } : {}}
            id="isEmployee"
            onClick={() => {
                if (disabled) return;
                const newValue = (_value + 1) % 3;
                setValue(newValue);
                onChange(newValue - 1);
                onBlur(newValue - 1);
                console.log(newValue - 1);
            }}
            // disabled={disabled}
        />
    );
}
