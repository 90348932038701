import * as React from 'react';
import { Translate } from '../../utils/Translate';
import { classNames } from '../../utils/classNames';

const FPS = 50;
const MAX_TIME = 3000;

interface IConfirmButtonProps {
    color?: string;
    label: string | JSX.Element;
    onConfirm: () => void;
    className?: string;
    disabled?: boolean;
}

interface IConfirmButtonState {
    remaining: number;
}

export class ConfirmButton extends React.Component<IConfirmButtonProps, IConfirmButtonState> {
    state: IConfirmButtonState = {
        remaining: 0,
    };

    setupTimeout() {
        setTimeout(() => {
            if (this.state.remaining > 0) {
                this.setState({ remaining: this.state.remaining - 1000 / FPS });
                this.setupTimeout();
            }
        }, 1000 / FPS);
    }

    /*/
     constructor(props: IConfirmButtonProps) {
         super(props);
     }
     /**/

    render() {
        return (
            <button
                className={classNames(
                    'btn confirm-button',
                    `btn-${this.props.color || 'danger'}`,
                    this.props.className,
                )}
                type="button"
                onClick={() => {
                    if (this.props.disabled) return;
                    if (this.state.remaining <= 0) {
                        this.setState({ remaining: MAX_TIME });
                        this.setupTimeout();
                    } else {
                        this.props.onConfirm();
                        this.setState({ remaining: 0 });
                    }
                }}
                onBlur={() => {
                    this.setState({ remaining: 0 });
                }}
                disabled={this.props.disabled}
            >
                {this.props.label}
                {this.state.remaining > 0 && (
                    <div className="overlay">
                        <div className="overlay-label">
                            {Translate.message('confirmButton.confirmation', 'Opravdu?')}
                        </div>
                        <div className="overlay-countdown">
                            <div
                                className="overlay-countdown-bar"
                                style={{ width: (this.state.remaining / MAX_TIME) * 100 + '%' }}
                            />
                        </div>
                    </div>
                )}
            </button>
        );
    }
}
