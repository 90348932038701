import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { AppState } from '../../../../AppState';
import { OrganizationRole } from '../../../../api/entities/OrganizationRole';
import { Translate } from '../../../../utils/Translate';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { IconByName, icons } from '../../../utils/IconByName';
import { FormInput } from '../../../utils/inputs/FormInput';

export interface IOrganizationRoleEditorProps {
    role?: OrganizationRole;
    appState: AppState;
    onSubmit?: () => void;
}

export function OrganizationRoleEditor({ role, appState, onSubmit }: IOrganizationRoleEditorProps) {
    const [icon, setIcon] = useState(role?.icon || 'file');
    const [title, setTitle] = useState(
        role?.title || Translate.message('roles.organizationRoles.newRole', 'Nová role'),
    );
    const [publicInfo, setPublicInfo] = useState(role?.publicInfo || false);

    const [save, { loading: l1, error: e1 }] = useMutation(gql`
        mutation OrganizationRoleEditor($memberId: Int!, $id: Int!, $value: OrganizationRoleInput!) {
            auth {
                as(memberId: $memberId) {
                    updateOrganizationRole(id: $id, value: $value) {
                        id
                    }
                }
            }
        }
    `);

    const [create, { loading: l2, error: e2 }] = useMutation(gql`
        mutation OrganizationRoleEditorCreate($memberId: Int!, $value: OrganizationRoleInput!) {
            auth {
                as(memberId: $memberId) {
                    createOrganizationRole(value: $value) {
                        id
                    }
                }
            }
        }
    `);

    const [remove, { loading: l3, error: e3 }] = useMutation(gql`
        mutation OrganizationRoleEditorDelete($memberId: Int!, $id: Int!) {
            auth {
                as(memberId: $memberId) {
                    deleteOrganizationRole(id: $id)
                }
            }
        }
    `);

    if (e1) return <ErrorDisplay error={e1} />;
    if (e2) return <ErrorDisplay error={e2} />;
    if (e3) return <ErrorDisplay error={e3} />;

    return (
        <div>
            <FormInput
                type="text"
                value={title}
                onChange={(value) => setTitle(value)}
                label={Translate.message('roles.organizationRoles.titleField', 'Název role')}
            />
            <FormInput
                type="checkbox"
                value={publicInfo}
                onChange={(value) => setPublicInfo(value)}
                label={Translate.message(
                    'roles.organizationRoles.publicInfo',
                    'Zobrazuje se tato role na stránce vedoucích? (veřejné datum narození, email, telefon)',
                )}
            />
            <div className="row mt-4">
                <div className="col-6">
                    <FormInput
                        type="select"
                        options={Object.keys(icons).map((key) => ({
                            title: key,
                            value: key,
                        }))}
                        value={icon}
                        onChange={(value) => setIcon(value)}
                        label={Translate.message('roles.organizationRoles.icon', 'Ikonka role')}
                    />
                </div>
                <div className="col-6">
                    <IconByName icon={icon as any} size={45} />
                </div>
            </div>
            <p className="mt-2" style={{ opacity: 0.5 }}>
                {Translate.message('roles.organizationRoles.iconTablerLink', 'Ikonky si můžeš prohlédnout na stránce ')}
                <a href="https://tabler-icons.io/" target="_blank" rel="noreferrer">
                    https://tabler-icons.io/
                </a>
                .
            </p>
            <ConfirmButton
                className="mt-5"
                label={<>{Translate.message('roles.editor.save', 'Uložit')} </>}
                onConfirm={async () => {
                    if (role) {
                        await save({
                            variables: {
                                memberId: appState.activeMember.id,
                                id: role.id,
                                value: {
                                    title,
                                    icon,
                                    publicInfo,
                                },
                            },
                        });
                    } else {
                        await create({
                            variables: {
                                memberId: appState.activeMember.id,
                                value: {
                                    title,
                                    icon,
                                    publicInfo,
                                },
                            },
                        });
                    }
                    onSubmit && onSubmit();
                }}
                disabled={l1 || l2}
                color="primary"
            />
            {role && (
                <ConfirmButton
                    className="mt-5 ms-3"
                    label={<>{Translate.message('roles.editor.delete', 'Odstranit roli')} </>}
                    onConfirm={async () => {
                        await remove({
                            variables: {
                                memberId: appState.activeMember.id,
                                id: role.id,
                            },
                        });
                        onSubmit && onSubmit();
                    }}
                    disabled={l3}
                    color="danger"
                />
            )}
        </div>
    );
}
