import {
    IconBook,
    IconCamera,
    IconFile,
    IconFileInfo,
    IconKey,
    IconMessage,
    IconPin,
    IconReportMoney,
    IconUsers,
    TablerIcon,
} from '@tabler/icons';
import { Event } from '../../../api/entities/Event';
import { AppState } from '../../../AppState';
import { Translate } from '../../../utils/Translate';
import { Routes } from '../../routes/Routes';

type task = {
    title: string;
    icon: TablerIcon;
    due: number;
    validator?: (appState: AppState, event: Event) => boolean;
    link?: (appState: AppState, event: Event) => string;
};

export const tasks: task[] = [
    {
        title: Translate.message('eventTasks.tasks.basicInfo', 'Zadat základní informace'),
        icon: IconFile,
        due: -4,
        link: (appState, event) => `${Routes.eventsEdit(appState.memberId, event.id)}/basic`,
        validator: (appState, event) => true,
    },
    {
        title: Translate.message('eventTasks.tasks.informativeArticle', 'Poslat informační lísteček'),
        icon: IconPin,
        due: -3,
        link: (appState, event) => `${Routes.articlesNew(appState.memberId)}`,
        validator: (appState, event) => event.articles.length > 0,
    },
    {
        title: Translate.message('eventTasks.tasks.forumThread', 'Vytvořit vlákno na fóru'),
        icon: IconMessage,
        due: -3,
    },
    {
        title: Translate.message('eventTasks.tasks.detailedInfo', 'Zadat detailní informace'),
        icon: IconFileInfo,
        due: -2,
        link: (appState, event) => `${Routes.eventsEdit(appState.memberId, event.id)}/basic`,
        validator: (appState, event) => event.allDay === false,
    },
    {
        title: Translate.message('eventTasks.tasks.detailedArticle', 'Poslat podrobný lísteček'),
        icon: IconPin,
        due: -2,
        link: (appState, event) => `${Routes.articlesNew(appState.memberId)}`,
        validator: (appState, event) => event.articles.length > 1,
    },
    {
        title: Translate.message('eventTasks.tasks.registration', 'Vytvořit přihlašování'),
        icon: IconKey,
        due: -2,
        link: (appState, event) => `${Routes.eventsEdit(appState.memberId, event.id)}/registration`,
        validator: (appState, event) => !!event.registrationStart,
    },
    {
        title: Translate.message('eventTasks.tasks.attendees', 'Zadat účastníky'),
        icon: IconUsers,
        due: 1,
    },
    {
        title: Translate.message('eventTasks.tasks.photos', 'Nahrát fotky'),
        icon: IconCamera,
        due: 1,
        link: (appState, event) => `${Routes.eventsEdit(appState.memberId, event.id)}/after`,
        validator: (appState, event) => !!event.linkPhoto,
    },
    {
        title: Translate.message('eventTasks.tasks.article', 'Zadat kroniky'),
        icon: IconBook,
        due: 1,
    },
    {
        title: Translate.message('eventTasks.tasks.accounts', 'Vytvořit vyúčtování'),
        icon: IconReportMoney,
        due: 2,
        link: (appState, event) => `${Routes.eventsEdit(appState.memberId, event.id)}/after`,
    },
];

export const taskGroups: { [key: number]: string } = {
    [-4]: Translate.message('eventTasks.groups.b4', 'Základní informace'),
    [-3]: Translate.message('eventTasks.groups.b3', 'Informační lísteček'),
    [-2]: Translate.message('eventTasks.groups.b2', 'Detailní informace'),
    1: Translate.message('eventTasks.groups.a1', 'Fotky, kroniky'),
    2: Translate.message('eventTasks.groups.a2', 'Vyúčtování'),
};
