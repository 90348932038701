import { gql, useMutation } from '@apollo/client';
import moment from 'moment';
import { useState } from 'react';
import { AppState } from '../../../../../AppState';
import { Api } from '../../../../../api/Api';
import { Event, EventAttendeeExternal } from '../../../../../api/entities/Event';
import { Member } from '../../../../../api/entities/Member';
import { Translate } from '../../../../../utils/Translate';
import { ConfirmButton } from '../../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { RoleIcons } from '../../../../utils/RoleIcons';
import { Spinner } from '../../../../utils/Spinner';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { SearchInput } from '../../../../utils/inputs/SearchInput';
import { date, inRange, requiredIf } from '../../../../utils/inputs/inputValidators/validators';

export interface IRegistrationEditorProps {
    appState: AppState;
    event: Event;
    onSubmit: (value: { [key: string]: any }) => void;
}

export function RegistrationEditor(props: IRegistrationEditorProps) {
    const { event, onSubmit } = props;
    const [memberId, setMemberId] = useState<number | null>(null);
    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [nickname, setNickname] = useState<string>('');
    const [birthdate, setBirthdate] = useState<string>(moment().format('YYYY-MM-DD'));
    const [from, setFrom] = useState<number | null>(null);

    const attend = async (...attrs: any[]) => {
        await _attend(...(attrs as any));
        onSubmit({});
    };

    const attendExternal = async (...attrs: any[]) => {
        await _attendExternal(...(attrs as any));
        onSubmit({});
    };

    const [_attend, { loading: l1, error: e1 }] = useMutation(gql`
        mutation AttendMember($id: Int!, $answer: Answer!) {
            auth {
                as(memberId: ${props.appState.activeMember.id}) {
                    attendEvent(eventId: ${props.event.id}, memberId: $id, answer: $answer)
                }
            }
        }
    `);

    const [_attendExternal, { loading: l2, error: e2 }] = useMutation(gql`
    mutation AttendMember($from: Int, $answer: Answer!, $firstname: String!, $lastname: String!, $nickname: String! $birthdate: String!, $attendeeId: Int) {
        auth {
            as(memberId: ${props.appState.activeMember.id}) {
                attendEventExternal(eventId: ${props.event.id}, fromId: $from, answer: $answer, firstname: $firstname, lastname: $lastname, nickname: $nickname, birthdate: $birthdate, attendeeId: $attendeeId)
            }
        }
    }
    `);

    const attending = event.attendees
        .filter((attendance) => attendance.answer === 'yes')
        .map((attendee, key) => attendee.member);
    const external = event.attendeesExternal
        .filter((attendance) => attendance.answer === 'yes')
        .map((attendee, key) => ({ ...attendee, external: true }));

    return (
        <>
            <Form
                onSubmit={(value) => {
                    const result = {
                        registrationStart: moment(value.registrationStart).toISOString(),
                        registrationEnd: moment(value.registrationEnd).toISOString(),
                        allowExternal: !!value.allowExternal,
                        maxAttendees: value.limited ? parseInt(value.maxAttendees) : null,
                    };
                    onSubmit(result);
                }}
                validators={(state) => ({
                    registrationStart: [date],
                    registrationEnd: [date],
                    limited: [],
                    allowExternal: [],
                    maxAttendees: [requiredIf(state.limited?.value), inRange(1, 1000, true)],
                })}
                defaultValues={{
                    registrationStart: event.registrationStart
                        ? moment(event.registrationStart).format('YYYY-MM-DDTHH:mm')
                        : null,
                    registrationEnd: event.registrationEnd
                        ? moment(event.registrationEnd).format('YYYY-MM-DDTHH:mm')
                        : null,
                    limited: event.maxAttendees !== null && event.maxAttendees !== undefined,
                    allowExternal: event.allowExternal,
                    maxAttendees: event.maxAttendees || 10,
                }}
            >
                {(inputProps, state, buttonProps) => (
                    <>
                        <div className="d-flex align-items-start">
                            <FormInput
                                {...inputProps('registrationStart')}
                                type="datetime-local"
                                label={Translate.message('events.fields.registrationStart', 'Začátek přihlašování')}
                            />
                            <FormInput
                                {...inputProps('registrationEnd')}
                                type="datetime-local"
                                label={Translate.message('events.fields.registrationEnd', 'Konec přihlašování')}
                            />
                        </div>
                        <div className="mt-5">
                            <FormInput
                                {...inputProps('limited')}
                                type="checkbox"
                                label={Translate.message('events.fields.limited', 'Omezit počet účastníků')}
                            />
                            <FormInput
                                {...inputProps('maxAttendees', 'd-block')}
                                type="number"
                                label={Translate.message('events.fields.maxAttendees', 'Maximální počet účastníků')}
                                disabled={!state.limited.value}
                            />
                        </div>
                        <div className="mt-5">
                            <FormInput
                                {...inputProps('allowExternal')}
                                type="checkbox"
                                label={Translate.message('events.fields.allowExternal', 'Povolit neduhové účastníky')}
                            />
                        </div>
                        <div className="mt-5">
                            <button {...buttonProps}>{Translate.message('events.edit.save', 'Uložit')}</button>
                        </div>
                    </>
                )}
            </Form>
            <div className="mt-5">
                <h3 className="section-heading">
                    {Translate.message('events.edit.attendance.attendees', 'Účastníci')}
                </h3>
                {l1 || l2 ? (
                    <Spinner />
                ) : e1 ? (
                    <ErrorDisplay error={e1} />
                ) : e2 ? (
                    <ErrorDisplay error={e2} />
                ) : (
                    <>
                        <div className="mt-3">
                            {Translate.message(
                                'events.edit.attendance.going',
                                '{{going?plural|Přihlášený je|Přihlášení jsou|Přihlášených je}} {{going}} {{going?plural|účastník|účastníci|účastníků}}',
                                {
                                    going: [...attending, ...external].length,
                                },
                            )}{' '}
                            {props.event.maxAttendees
                                ? Translate.message('events.edit.attendance.of', '{{of?plural|z|ze|z}} {{of}}', {
                                      of: props.event.maxAttendees,
                                  })
                                : ''}
                        </div>
                        <div className="block bg-white mt-3">
                            <table className="table table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th className="ps-3">{Translate.message('members.firstname')}</th>
                                        <th>{Translate.message('members.lastname')}</th>
                                        <th>{Translate.message('members.nickname')}</th>
                                        <th>{Translate.message('members.age')}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...attending, ...external]
                                        .sort(
                                            (a, b) =>
                                                moment(b.birthdate).unix() - moment(a.birthdate).unix() ||
                                                a.lastname.localeCompare(b.lastname) ||
                                                a.firstname.localeCompare(b.firstname),
                                        )
                                        .map((item) => (
                                            <tr style={{ verticalAlign: 'middle' }}>
                                                <td className="ps-3">{item.firstname}</td>
                                                <td>{item.lastname}</td>
                                                <td>{item.nickname}</td>
                                                <td>{item.birthdate ? moment().diff(item.birthdate, 'years') : '?'}</td>
                                                <td>
                                                    {(item as any).external ? (
                                                        <>
                                                            <em>
                                                                {Translate.message(
                                                                    'events.edit.attendance.external',
                                                                    'Neduhový účastník',
                                                                ) +
                                                                    ((item as EventAttendeeExternal).from
                                                                        ? ' ' +
                                                                          Translate.message(
                                                                              'events.edit.attendance.from',
                                                                              'od',
                                                                          ) +
                                                                          ' ' +
                                                                          (item as EventAttendeeExternal).from?.nickname
                                                                        : '')}
                                                            </em>
                                                        </>
                                                    ) : (
                                                        <RoleIcons member={item as any} />
                                                    )}
                                                </td>
                                                <td style={{ width: 125, minWidth: 125, maxWidth: 125 }}>
                                                    <ConfirmButton
                                                        label={Translate.message(
                                                            'events.edit.attendance.unattend',
                                                            'Odhlásit',
                                                        )}
                                                        onConfirm={() =>
                                                            (item as any).external
                                                                ? attendExternal({
                                                                      variables: {
                                                                          attendeeId: (item as EventAttendeeExternal)
                                                                              .id,
                                                                          firstname: (item as EventAttendeeExternal)
                                                                              .firstname,
                                                                          lastname: (item as EventAttendeeExternal)
                                                                              .lastname,
                                                                          nickname: (item as EventAttendeeExternal)
                                                                              .nickname,
                                                                          birthdate: (item as EventAttendeeExternal)
                                                                              .birthdate,
                                                                          answer: 'no',
                                                                      },
                                                                  })
                                                                : attend({
                                                                      variables: {
                                                                          id: (item as Member).id,
                                                                          answer: 'no',
                                                                      },
                                                                  })
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <h4 className="section-heading">
                            {Translate.message('events.edit.attendance.attendMember', 'Přihlásit Duhového účastníka')}
                        </h4>
                        <SearchInput<Member>
                            type="text"
                            label={Translate.message('events.edit.attendance.selectMember', 'Vybrat účastníka')}
                            appState={props.appState}
                            query={Api.queries.member.search}
                            resultResolver={(data) => data?.auth?.as?.memberById}
                            searchResolver={(data) => data?.auth?.as?.members}
                            itemDisplayJSX={(member) => (
                                <>
                                    {member.firstname} "{member.nickname}" {member.lastname}
                                </>
                            )}
                            itemDisplayText={(member) => `${member.firstname} "${member.nickname}" ${member.lastname}`}
                            onChange={(value) => {
                                setMemberId(value);
                            }}
                            value={memberId}
                            className="mb-3"
                            clearAfterInsert
                        />
                        <ConfirmButton
                            color="primary"
                            label={Translate.message('events.edit.attendance.attend', 'Přihlásit')}
                            onConfirm={() => attend({ variables: { id: memberId, answer: 'yes' } })}
                            disabled={!memberId}
                        />

                        <h4 className="section-heading">
                            {Translate.message(
                                'events.edit.attendance.attendExternal',
                                'Přihlásit neduhového účastníka',
                            )}
                        </h4>
                        <div className="row mt-2">
                            <div className="col-12 col-xl-6">
                                <FormInput
                                    type="text"
                                    label={Translate.message('events.edit.attendance.external.firstname', 'Jméno')}
                                    value={firstname}
                                    onChange={(value) => setFirstname(value)}
                                />
                            </div>
                            <div className="col-12 col-xl-6">
                                <FormInput
                                    type="text"
                                    label={Translate.message('events.edit.attendance.external.lastname', 'Příjmení')}
                                    value={lastname}
                                    onChange={(value) => setLastname(value)}
                                />
                            </div>
                        </div>
                        <div className="row mt-2 mb-4">
                            <div className="col-12 col-xl-6">
                                <FormInput
                                    type="text"
                                    label={Translate.message('events.edit.attendance.external.nickname', 'Přezdívka')}
                                    value={nickname}
                                    onChange={(value) => setNickname(value)}
                                />
                            </div>
                            <div className="col-12 col-xl-6">
                                <FormInput
                                    type="date"
                                    label={Translate.message(
                                        'events.edit.attendance.external.birthdate',
                                        'Datum narození',
                                    )}
                                    value={birthdate}
                                    onChange={(value) => setBirthdate(value)}
                                />
                            </div>
                        </div>

                        <SearchInput<Member>
                            type="text"
                            label={Translate.message('events.edit.attendance.external.from', 'Od')}
                            appState={props.appState}
                            query={Api.queries.member.search}
                            resultResolver={(data) => data?.auth?.as?.memberById}
                            searchResolver={(data) => data?.auth?.as?.members}
                            itemDisplayJSX={(member) => (
                                <>
                                    {member.firstname} "{member.nickname}" {member.lastname}
                                </>
                            )}
                            itemDisplayText={(member) => `${member.firstname} "${member.nickname}" ${member.lastname}`}
                            onChange={(value) => setFrom(value)}
                            value={from}
                            className="mb-3"
                            clearAfterInsert
                        />
                        <ConfirmButton
                            color="primary"
                            label={Translate.message('events.edit.attendance.attend', 'Přihlásit')}
                            onConfirm={() =>
                                attendExternal({
                                    variables: {
                                        firstname: firstname,
                                        lastname: lastname,
                                        nickname: nickname,
                                        birthdate: birthdate,
                                        answer: 'yes',
                                        fromId: from,
                                    },
                                })
                            }
                            disabled={
                                !(
                                    firstname &&
                                    firstname.length > 0 &&
                                    lastname &&
                                    lastname.length > 0 &&
                                    nickname &&
                                    nickname.length > 0 &&
                                    birthdate &&
                                    birthdate.length > 0
                                )
                            }
                        />
                    </>
                )}
            </div>
        </>
    );
}
