import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Api } from '../../../../../api/Api';
import { Translate } from '../../../../../utils/Translate';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { Spinner } from '../../../../utils/Spinner';
import { IStepProps } from '../Apply';

export function StepMedical(props: IStepProps) {
    const [iterator, forceUpdate] = React.useState(0);
    const [update, { loading, error, data }] = useMutation(
        Api.mutations.member.update(props.memberId!, props.memberId!, 'otherInfo', 'String'),
    );

    if (loading) return <Spinner />;
    if (error)
        return (
            <>
                <ErrorDisplay error={error} />
                <div className="mt-5 d-flex flex-row align-items-center">
                    <div className="d-inline-block text-start">
                        <span className="link blue" onClick={() => forceUpdate(iterator + 1)}>
                            Zpět
                        </span>
                    </div>
                    <div className="d-inline-block text-end  flex-grow-1"></div>
                </div>
            </>
        );

    if (data) {
        props.next({});
        return <Spinner />;
    }

    return (
        <Form
            defaultValues={{
                otherInfo: '',
            }}
            onSubmit={(values) => {
                const data = values.otherInfo || '';
                props.setValues({ otherInfo: data });
                update({
                    variables: {
                        value: data,
                    },
                });
            }}
            validators={(data) => ({
                otherInfo: [],
            })}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <h2 className="mb-4">
                        <Translate.Component name="application.medical.title" alt="Už tam skoro jsme!" />
                    </h2>
                    <div className="mb-5">
                        <p className="mb-4">
                            <Translate.Component
                                name="application.medical.subtitle"
                                alt="Je ještě něco důležitého, <<strong&co bychom o tobě měli vědět>>? Může to být cokoli od <<em&bezlepkové diety>>, přes <<em&silnou alergii na včely>>, nebo třeba to, že <<em&nejíš nějaké ovoce>>."
                            />
                        </p>
                        <FormInput
                            {...inputProps('otherInfo')}
                            type="textarea"
                            label={Translate.message('application.medical.input', 'Závažná zdravotní či jiná sdělení')}
                        />
                    </div>
                    <div className="mt-5 d-flex flex-row align-items-center">
                        <div className="d-inline-block text-start"></div>
                        <div className="d-inline-block text-end  flex-grow-1">
                            <button {...buttonProps}>{Translate.message('application.next', 'Další')}</button>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
}
