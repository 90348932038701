import { Translate } from '../../../../utils/Translate';
import { filter } from '../../../utils/dataDisplayComponents/pagedView/Page';
import { FormInput } from '../../../utils/inputs/FormInput';

export const meetingFilters: filter<any>[] = [
    {
        id: 'from',
        title: 'filter.from',
        createFilter: (value) => (value && value.length > 0 ? { from: value } : {}),
        toggle: (value, onChange) => (
            <>
                <FormInput
                    type="date"
                    label={Translate.message('events.from', 'Od')}
                    value={value}
                    onChange={onChange}
                />
            </>
        ),
    },
    {
        id: 'to',
        title: 'filter.to',
        createFilter: (value) => (value && value.length > 0 ? { to: value } : {}),
        toggle: (value, onChange) => (
            <>
                <FormInput type="date" label={Translate.message('events.to', 'Do')} value={value} onChange={onChange} />
            </>
        ),
    },
    {
        id: 'current',
        title: 'filter.current',
        createFilter: (value) => (value && value.length > 0 ? { current: value === 'true' ? true : false } : {}),
        toggle: (value, onChange) => (
            <>
                <FormInput
                    type="select"
                    label={Translate.message('members.current', 'Aktuální rok')}
                    value={value}
                    onChange={onChange}
                    options={[
                        {
                            title: '',
                            value: '',
                        },
                        {
                            title: Translate.message('general.true', 'Ano'),
                            value: 'true',
                        },
                        {
                            title: Translate.message('general.false', 'Ne'),
                            value: 'false',
                        },
                    ]}
                />
            </>
        ),
    },
];
