import { gql, useMutation } from '@apollo/client';
import { startRegistration } from '@simplewebauthn/browser';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppState } from '../../../AppState';
import { Translate } from '../../../utils/Translate';
import { ErrorDisplay } from '../../utils/ErrorDisplay';
import { PasswordFeatures, validatePassword } from '../../utils/PasswordFeatures';
import { Spinner } from '../../utils/Spinner';
import { FormInput } from '../../utils/inputs/FormInput';
import { Routes } from '../Routes';
import { LS_REMEMBERED_USERS } from '../unauthorized/Login';

export interface IAccountProps {
    appState: AppState;
}

export function Account(props: IAccountProps) {
    const [password, setPassword] = useState('');
    const [passwordValidation, setPasswordValidation] = useState('');

    const [message, setMessage] = useState('');
    const [awaitingUserInput, setAwaitingUserInput] = useState(false);

    const rememberedUsers = JSON.parse(localStorage.getItem(LS_REMEMBERED_USERS) || '[]');

    const [changePassword, { loading: l1, error: e1, data: d1 }] = useMutation(gql`
        mutation ChangePassword($password: String!, $passwordValidation: String!) {
            auth {
                changePassword(password: $password, passwordValidation: $passwordValidation)
            }
        }
    `);

    const [generateRegistrationOptions, { loading: l2, error: e2 }] = useMutation(gql`
        mutation generateRegistrationOptions {
            auth {
                generateRegistrationOptions
            }
        }
    `);

    const [verifyRegistrationResponse, { loading: l3, error: e3 }] = useMutation(gql`
        mutation verifyRegistrationResponse($response: String!) {
            auth {
                verifyRegistrationResponse(response: $response)
            }
        }
    `);

    return (
        <div className="wrapper center-screen center-screen-wide spot-rh">
            <main>
                <h1 className="mb-4">{Translate.message('account.title', 'Nastavení účtu')}</h1>
                <p>
                    {Translate.message(
                        'account.subtitle',
                        'Zde si můžete změnit heslo či nastavit přihlašování otiskem prstu. Tyto možnosti se vztahují pouze k právě přihlášenému účtu, nikoli ke všem účtům přidrzuženým členovi.',
                    )}
                </p>

                <div className="block text-start p-5">
                    <h2 className="mb-4">{Translate.message('account.password', 'Změna hesla')}</h2>
                    {e1 ? (
                        <ErrorDisplay error={e1} />
                    ) : l1 ? (
                        <Spinner />
                    ) : (
                        <>
                            <FormInput
                                type="password"
                                className="mb-2"
                                label={Translate.message('register.password', 'Heslo')}
                                value={password}
                                onChange={(value) => setPassword(value)}
                                error={
                                    password.length === 0 || validatePassword(password).length === 0
                                        ? undefined
                                        : Translate.message('error.passwordTooWeak')
                                }
                            />
                            <PasswordFeatures password={password} />
                            <FormInput
                                type="password"
                                className="mb-3 mt-3"
                                label={Translate.message('register.passwordValidation', 'Heslo znovu')}
                                value={passwordValidation}
                                onChange={(value) => setPasswordValidation(value)}
                                error={
                                    passwordValidation.length === 0 || password === passwordValidation
                                        ? undefined
                                        : Translate.message('error.passwordsDontMatch')
                                }
                            />
                            {d1 ? (
                                <button className="btn btn-success">
                                    {Translate.message('account.changePasswordSuccess', 'Heslo bylo změněno')}
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary"
                                    disabled={
                                        password.length === 0 ||
                                        password !== passwordValidation ||
                                        validatePassword(password).length > 0
                                    }
                                    onClick={() => changePassword({ variables: { password, passwordValidation } })}
                                >
                                    {Translate.message('account.changePassword', 'Změnit heslo')}
                                </button>
                            )}
                        </>
                    )}
                </div>
                <div className="block text-start p-5">
                    <h2 className="mb-4">
                        {Translate.message('account.webauthn.title', 'Přihlašování otiskem prstu')}
                    </h2>
                    {l2 || l3 || awaitingUserInput ? (
                        <Spinner />
                    ) : e2 ? (
                        <ErrorDisplay error={e2} />
                    ) : e3 ? (
                        <ErrorDisplay error={e3} />
                    ) : (
                        <>
                            <p>
                                {Translate.message(
                                    'account.webauthn.subtitle',
                                    'Pro rychlejší a pohodlnější přihlášení si můžete na svém zařízení povolit přihlášení otiskem prstu. Přihlášení bude fungovat pouze na zařízeních, kde jste tuto funkci povolili.',
                                )}
                            </p>
                            {rememberedUsers.includes(props.appState.email) ? (
                                <button className="btn btn-success">
                                    {Translate.message('account.webauthn.enabled', 'Přihlašování otiskem je aktivní')}
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary"
                                    onClick={async () => {
                                        setAwaitingUserInput(true);
                                        const option = (await generateRegistrationOptions()).data.auth
                                            .generateRegistrationOptions;

                                        console.log(option);

                                        let attResp;
                                        try {
                                            attResp = await startRegistration(JSON.parse(option));

                                            const result = await verifyRegistrationResponse({
                                                variables: { response: JSON.stringify(attResp) },
                                            });

                                            if (result) {
                                                if (result.data.auth.verifyRegistrationResponse) {
                                                    setMessage('');
                                                    localStorage.setItem(
                                                        LS_REMEMBERED_USERS,
                                                        JSON.stringify([...rememberedUsers, props.appState.email]),
                                                    );
                                                } else {
                                                    setMessage(
                                                        Translate.message(
                                                            'error.webauthnError',
                                                            'Nastala chyba při povolování přihlašování otiskem prstu',
                                                        ),
                                                    );
                                                }
                                            }
                                        } catch (error: any) {
                                            if (error.name === 'InvalidStateError') {
                                                setMessage(
                                                    Translate.message(
                                                        'error.webauthnAlreadyRegistered',
                                                        'Vypadá to, že jste tuto funkci již povolili',
                                                    ),
                                                );
                                                localStorage.setItem(
                                                    LS_REMEMBERED_USERS,
                                                    JSON.stringify([...rememberedUsers, props.appState.email]),
                                                );
                                            } else {
                                                setMessage(
                                                    Translate.message(
                                                        'error.webauthnError',
                                                        'Nastala chyba při povolování přihlašování otiskem prstu',
                                                    ),
                                                );
                                            }
                                            console.log(error);
                                        } finally {
                                            setAwaitingUserInput(false);
                                        }
                                    }}
                                >
                                    {Translate.message('account.webauthn.enable', 'Povolit přihlašování otiskem prstu')}
                                </button>
                            )}
                            {message.length > 0 && <div className="invalid-feedback d-block">{message}</div>}
                        </>
                    )}
                </div>
                <div className="text-start">
                    <Link to={Routes.auth()}>
                        {Translate.message('authAs.membershipRequested.back', 'Zpět na výběr člena')}
                    </Link>
                </div>
            </main>
        </div>
    );
}
