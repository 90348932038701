import * as React from 'react';
import { Translate } from '../../utils/Translate';
import { ErrorDisplay } from './ErrorDisplay';

interface IErrorBoundaryProps {}

interface IErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    state: IErrorBoundaryState = {
        hasError: false,
    };

    componentDidCatch(error: any, errorInfo: any) {
        console.error(error, errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) return <ErrorDisplay error={Translate.message('error.unknownError')} />;

        return this.props.children;
    }
}
