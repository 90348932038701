import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { AppState } from '../../../../AppState';
import { Calendar } from '../../Calendar';
import { ErrorDisplay } from '../../ErrorDisplay';
import { Spinner } from '../../Spinner';

interface IEventsCalendarViewProps {
    appState: AppState;
    date: Date;
    maxRows?: number;
    controls?: JSX.Element;
    display?: {
        events: boolean;
        meetings: boolean;
    };
}

export function EventsCalendarView(props: IEventsCalendarViewProps) {
    const { loading, error, data } = useQuery(
        gql`
            query EventList($eventFilter: EventFilter, $meetingFilter: MeetingFilter) {
                auth {
                    as(memberId: ${props.appState.activeMember.id}) {
                        events(filter: $eventFilter) {
                            items {
                                id
                                from
                                to
                                title
                                allDay
                                attendees {
                                    answer
                                    member {
                                        id
                                    }
                                }
                            }
                        }
                        meetings(filter: $meetingFilter) {
                            items {
                                id
                                title
                                seasonFrom
                                seasonTo
                                firstFrom
                                firstTo
                                frequency
                                frequencyEvery
                                schedules {
                                    from
                                    to
                                    happening
                                    note
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                eventFilter: {
                    from: moment(props.date).startOf('month').subtract(1, 'week').format('YYYY-MM-DD'),
                    to: moment(props.date).startOf('month').add(1, 'month').add(1, 'week').format('YYYY-MM-DD'),
                },
            },
        },
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    return (
        <Calendar
            maxRows={props.maxRows}
            date={props.date}
            events={data.auth.as.events.items}
            meetings={data.auth.as.meetings.items}
            appState={props.appState}
            controls={props.controls}
            display={props.display || { events: true, meetings: true }}
        />
    );
}
