import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { DocumentNode } from 'graphql';
import * as React from 'react';
import { Api } from '../../../api/Api';
import { classNames } from '../../../utils/classNames';
import { Translate } from '../../../utils/Translate';
import { FormInput, IFormInputProps } from './FormInput';

interface ILiveInputProps extends IFormInputProps {
    initialValue: string | number | boolean | null | undefined;
    mutation: DocumentNode | TypedDocumentNode;
}

export function LiveInput(props: ILiveInputProps) {
    const [value, setValue] = React.useState(props.initialValue);
    const [status, setStatus] = React.useState<'ready' | 'waiting' | 'saving' | 'error'>('ready');
    const [error, setError] = React.useState<null | string>(null);
    const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);

    const disabled = props.disabled || status === 'saving';
    const showLoading = status === 'waiting' || status === 'saving';

    const save = (passedValue?: any) => {
        if (timer) {
            clearTimeout(timer);
        }
        setStatus('saving');

        const val = passedValue === undefined ? value : passedValue;

        Api.client
            .mutate({
                mutation: props.mutation,
                variables: {
                    value: val,
                },
                errorPolicy: 'all',
            })
            .then((result) => {
                if (result.errors) {
                    setStatus('error');
                    setError(
                        Translate.message(
                            `error.${result.errors[0].extensions?.messageId || 'unknownError'}`,
                            result.errors[0].message,
                            result.errors[0].extensions?.context,
                        ),
                    );
                } else {
                    setStatus('ready');
                    setError(null);
                    if (props.onChange) {
                        props.onChange(val);
                    }
                }
            });
    };

    const onChange = (value: any) => {
        setValue(value);

        if (timer) {
            clearTimeout(timer);
        }

        setTimer(setTimeout(save, 3000));
        setStatus('waiting');
    };

    return (
        <FormInput
            {...props}
            disabled={disabled}
            additionalJSX={
                showLoading ? (
                    <div className="spinner-border saving" role="status">
                        <span className="visually-hidden">{Translate.message('LiveInput.saving', 'Ukládání...')}</span>
                    </div>
                ) : undefined
            }
            error={error ? error : undefined}
            onBlur={(value) => {
                setValue(value);
                save(value);
            }}
            onChange={(value) => {
                if (!['checkbox', 'radio'].includes(props.type)) {
                    onChange(value);
                }
            }}
            value={value}
            className={classNames(props.className, 'input-fill mt-4')}
        />
    );
}
