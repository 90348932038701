import { gql, useMutation } from '@apollo/client';
import { IconAlertTriangle, IconCheck } from '@tabler/icons';
import moment from 'moment';
import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AppState } from '../../AppState';
import { Api } from '../../api/Api';
import { Event } from '../../api/entities/Event';
import { Translate } from '../../utils/Translate';
import { gqlErrorToString } from '../../utils/gqlErrorToString';
import { ConfirmButton } from './ConfirmButton';
import { FormInput } from './inputs/FormInput';

interface IEventAttendButtomProps {
    appState: AppState;
    event: Event;
    onUpdate?: () => void;
}

export function EventAttendButtom(props: IEventAttendButtomProps): JSX.Element {
    const [stage, setStage] = React.useState(0);

    const [firstname, setFirstname] = React.useState<string>('');
    const [lastname, setLastname] = React.useState<string>('');
    const [nickname, setNickname] = React.useState<string>('');
    const [birthdate, setBirthdate] = React.useState<string>(moment().format('YYYY-MM-DD'));

    const [attend, { loading: l1, error: e1, data }] = useMutation(Api.mutations.event.attend, {
        variables: {
            memberId: props.appState.activeMember.id,
            eventId: props.event.id,
        },
    });

    const [attendExternal, { loading: l2, error: e2, data: d2 }] = useMutation(gql`
        mutation AttendMember($from: Int, $answer: Answer!, $firstname: String!, $lastname: String!, $nickname: String! $birthdate: String!, $attendeeId: Int) {
            auth {
                as(memberId: ${props.appState.activeMember.id}) {
                    attendEventExternal(eventId: ${props.event.id}, fromId: $from, answer: $answer, firstname: $firstname, lastname: $lastname, nickname: $nickname, birthdate: $birthdate, attendeeId: $attendeeId)
                }
            }
        }
    `);

    const { event, appState, onUpdate } = props;

    const matching = event.attendees.filter((relation) => relation.member.id === appState.activeMember.id);
    const attendance = matching.length > 0 ? matching[0] : undefined;

    const attending = event.attendees.filter((relation) => relation.answer === 'yes');

    if (event.registrationStart && moment(event.registrationStart).isAfter())
        return (
            <button className="btn bg-light text-dark flex-shrink-0" disabled>
                {Translate.message('eventAttendButtom.notOpenYet', 'Přihlašování {{date}}', {
                    date: moment(event.registrationStart).calendar(),
                })}
            </button>
        );

    if (!(!event.registrationEnd || moment(event.registrationEnd).isSameOrAfter()))
        return (
            <button className="btn bg-light text-dark flex-shrink-0" disabled>
                {Translate.message('eventAttendButtom.closed', 'Přihlašování skončilo')}
            </button>
        );

    return (
        <>
            {!!data || (attendance && attendance.answer === 'yes') ? (
                <button className="btn bg-success flex-shrink-0" onClick={() => setStage(4)}>
                    <IconCheck style={{ marginLeft: -6, marginRight: 6 }} />
                    {Translate.message(
                        'eventsListView.attending',
                        '{{gender?gender|Jsi přihlášený|Jsi přihlášená|Jedeš na akci}}',
                        { gender: appState.activeMember.gender },
                    )}
                </button>
            ) : !event.maxAttendees || attending.length < event.maxAttendees ? (
                <button onClick={() => setStage(1)} className="btn btn-primary flex-shrink-0">
                    {Translate.message('eventAttendButtom.attend', 'Přihlásit se')}
                </button>
            ) : (
                <button className="btn bg-light text-dark flex-shrink-0" disabled>
                    {Translate.message('eventAttendButtom.full', 'Akce je plná')}
                </button>
            )}

            <Modal show={stage > 0} centered onHide={() => setStage(0)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3>
                            {Translate.message('eventAttendButtom.title', 'Přihlásit se na akci')} "{event.title}"
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                {stage === 1 && (
                    <>
                        <Modal.Body>
                            <Translate.Component
                                name="eventAttendButtom.body"
                                alt="Přihlášením na akci souhlasíte se <<link&storno podmínkami>>."
                                wrappers={{
                                    link: (children, key) => (
                                        <a
                                            href="https://azduha.cz/doc/storno-podminky.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                            key={key}
                                        >
                                            {children}
                                        </a>
                                    ),
                                }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={() => setStage(0)}>
                                {Translate.message('eventAttendButtom.reject', 'Zrušit')}
                            </Button>
                            {l1 ? (
                                <button className="btn bg-primary flex-shrink-0" disabled>
                                    <span className="spinner-border spinner-border mx-auto" />
                                </button>
                            ) : e1 ? (
                                <button className="btn bg-primary flex-shrink-0" disabled>
                                    <IconAlertTriangle /> {gqlErrorToString(e1)}
                                </button>
                            ) : (
                                <Button
                                    variant="primary"
                                    onClick={async () => {
                                        await attend();
                                        onUpdate && onUpdate();
                                        setStage(event.allowExternal ? 2 : 3);
                                    }}
                                >
                                    {Translate.message('eventAttendButtom.accept', 'Přihlásit se jako')}{' '}
                                    {appState.activeMember.nickname}
                                </Button>
                            )}
                        </Modal.Footer>
                    </>
                )}
                {stage === 2 && (
                    <>
                        <Modal.Body>
                            {Translate.message(
                                'eventAttendButtom.external.title',
                                'Na tuto akci můžou jet i neduháci, chceš přihlásit nějakého neduhového účastníka?',
                            )}

                            <div className="row mt-2">
                                <div className="col-12 col-xl-6">
                                    <FormInput
                                        type="text"
                                        label={Translate.message('events.edit.attendance.external.firstname', 'Jméno')}
                                        value={firstname}
                                        onChange={(value) => setFirstname(value)}
                                    />
                                </div>
                                <div className="col-12 col-xl-6">
                                    <FormInput
                                        type="text"
                                        label={Translate.message(
                                            'events.edit.attendance.external.lastname',
                                            'Příjmení',
                                        )}
                                        value={lastname}
                                        onChange={(value) => setLastname(value)}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2 mb-4">
                                <div className="col-12 col-xl-6">
                                    <FormInput
                                        type="text"
                                        label={Translate.message(
                                            'events.edit.attendance.external.nickname',
                                            'Přezdívka',
                                        )}
                                        value={nickname}
                                        onChange={(value) => setNickname(value)}
                                    />
                                </div>
                                <div className="col-12 col-xl-6">
                                    <FormInput
                                        type="date"
                                        label={Translate.message(
                                            'events.edit.attendance.external.birthdate',
                                            'Datum narození',
                                        )}
                                        value={birthdate}
                                        onChange={(value) => setBirthdate(value)}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={() => setStage(3)}>
                                {Translate.message('eventAttendButtom.external.cancel', 'Nechci přihlásit neduháka')}
                            </Button>
                            {l2 ? (
                                <button className="btn bg-primary flex-shrink-0" disabled>
                                    <span className="spinner-border spinner-border mx-auto" />
                                </button>
                            ) : e2 ? (
                                <button className="btn bg-primary flex-shrink-0" disabled>
                                    <IconAlertTriangle /> {gqlErrorToString(e2)}
                                </button>
                            ) : (
                                <ConfirmButton
                                    color="primary"
                                    onConfirm={async () => {
                                        await attendExternal({
                                            variables: {
                                                from: appState.activeMember.id,
                                                answer: 'yes',
                                                firstname,
                                                lastname,
                                                nickname,
                                                birthdate,
                                            },
                                        });
                                        onUpdate && onUpdate();
                                        setFirstname('');
                                        setLastname('');
                                        setNickname('');
                                        setBirthdate(moment().format('YYYY-MM-DD'));
                                        setStage(2);
                                    }}
                                    disabled={
                                        !(
                                            firstname &&
                                            firstname.length > 0 &&
                                            lastname &&
                                            lastname.length > 0 &&
                                            nickname &&
                                            nickname.length > 0 &&
                                            birthdate &&
                                            birthdate.length > 0
                                        )
                                    }
                                    label={
                                        d2
                                            ? Translate.message(
                                                  'eventAttendButtom.external.registerNext',
                                                  'Přihlásit dalšího neduháka',
                                              )
                                            : Translate.message(
                                                  'eventAttendButtom.external.register',
                                                  'Přihlásit neduháka',
                                              )
                                    }
                                />
                            )}
                        </Modal.Footer>
                    </>
                )}
                {stage === 3 && (
                    <>
                        <Modal.Body>
                            <p>
                                {Translate.message(
                                    'eventAttendButtom.success',
                                    'Paráda! Jsi {{gender?gender|přihlášený|přihlášená}} na akci "{{event}}". Těšíme se na tebe!',
                                    { gender: appState.activeMember.gender, event: event.title },
                                )}
                            </p>
                            <p>
                                {Translate.message(
                                    'eventAttendButtom.note',
                                    'V případě, že se akce nebudete moct z nějakého důvodu zúčastnit, informujte prosím neprodleně organizátora akce. Děkujeme!',
                                )}
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setStage(0);
                                }}
                            >
                                {Translate.message('eventAttendButtom.done', 'Hotovo')}
                            </Button>
                        </Modal.Footer>
                    </>
                )}
                {stage === 4 && (
                    <>
                        <Modal.Body>
                            <p>
                                {Translate.message(
                                    'eventAttendButtom.alreadyRegistered',
                                    'Paráda! Na akci už jsi {{gender?gender|přihlášený|přihlášená}}. Těšíme se na tebe!',
                                    { gender: appState.activeMember.gender, event: event.title },
                                )}
                            </p>
                            <p>
                                {Translate.message(
                                    'eventAttendButtom.note',
                                    'V případě, že se akce nebudete moct z nějakého důvodu zúčastnit, informujte prosím neprodleně organizátora akce. Děkujeme!',
                                )}
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            {event.allowExternal && (
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setStage(2);
                                    }}
                                >
                                    {Translate.message('eventAttendButtom.goAttendExternal', 'Chci přihlásit neduháka')}
                                </Button>
                            )}
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setStage(0);
                                }}
                            >
                                {Translate.message('eventAttendButtom.done', 'Hotovo')}
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        </>
    );
}
