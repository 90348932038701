import { IconCalendarEvent, IconCheck, IconClock, IconPencil } from '@tabler/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { EventAttendee, EventAttendeeExternal } from '../../../../api/entities/Event';
import { TWIX_FORMAT } from '../../../../utils/twixFormat';
import { columnDefinition } from '../../../utils/dataDisplayComponents/pagedView/Page';
import { Routes } from '../../Routes';

export const eventColumns: (appState: AppState) => columnDefinition[] = (appState) => [
    {
        width: 0,
        getter: (row) => (moment(row['from'] as string).isSameOrBefore() ? <IconCheck /> : <IconClock />),
    },
    // @translation events.title: "Title"
    { title: 'events.title', width: 5, getter: (row) => row['title'] as string, sortBy: 'title' },
    // @translation events.date: "Datum"
    {
        title: 'events.date',
        width: 4,
        getter: (row) =>
            moment(row['from'] as string)
                .twix(row['to'] as any, row['allDay'] as boolean)
                .format({ ...TWIX_FORMAT, monthFormat: 'M.' }),
        sortBy: 'from',
    },
    // @translation events.leaders: "Event leaders"
    {
        title: 'events.leaders',
        width: 3,
        getter: (row) => (row.leaders as any as { nickname: string }[]).map((leader) => leader.nickname).join(', '),
    },
    // @translation events.numAttendees: "Number of attendees"
    {
        title: 'events.numAttendees',
        width: 2,
        getter: (row) =>
            (row.attendees as any as EventAttendee[]).filter((attendee) => attendee.answer === 'yes').length +
            (row.attendeesExternal as any as EventAttendeeExternal[]).filter((attendee) => attendee.answer === 'yes')
                .length,
    },
    {
        width: 0,
        getter: (row) => (
            <div className="actions">
                <Link to={Routes.eventsDetail(appState.memberId, row.id as number)}>
                    <IconCalendarEvent />
                </Link>
                <Link to={Routes.eventsEdit(appState.memberId, row.id as number)}>
                    <IconPencil />
                </Link>
            </div>
        ),
    },
];
