import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { AppState } from '../../AppState';
import { Member } from '../../api/entities/Member';
import { classNames } from '../../utils/classNames';
import { gqlErrorToString } from '../../utils/gqlErrorToString';
import { MemberHead } from './MemberHead';
import { RoleIcons } from './RoleIcons';

interface IProfilePreviewProps {
    appState: AppState;
    id?: number;
    member?: Member;
    className?: string;
    onClick?: () => void;
    roles?: boolean;
}

export function ProfilePreview(props: IProfilePreviewProps) {
    const [fetch, { loading, error, data }] = useLazyQuery(
        gql`
            query MemberBasicPlus($asId: Int!, $id: Int!) {
                auth {
                    as(memberId: $asId) {
                        memberById(id: $id) {
                            id
                            firstname
                            lastname
                            nickname
                            image
                            organizationRoles {
                                icon
                                title
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                asId: props.appState.activeMember.id,
                id: props.id,
            },
        },
    );

    useEffect(() => {
        if (props.id) fetch();
        console.log(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    const onClick = () => {
        if (props.onClick) props.onClick();
    };

    if (error)
        return (
            <div className={classNames('profile-preview', props.className)} onClick={onClick}>
                {props.id} - {gqlErrorToString(error)}
            </div>
        );

    const member = props.member || (data?.auth.as.memberById as Member);

    if (loading || !member)
        return (
            <div className={classNames('profile-preview', props.className)} onClick={onClick}>
                {props.id}
            </div>
        );

    return (
        <div className={classNames('profile-preview', props.className)} onClick={onClick}>
            <MemberHead appState={props.appState} member={member} className="my-2 me-3" noLink size={40} />
            <span className="pe-2">
                {member.firstname} "{member.nickname}" {member.lastname}
            </span>
            {props.roles && <RoleIcons hideMembership member={member} />}
        </div>
    );
}
