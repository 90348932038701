import { useQuery } from '@apollo/client';
import { IconEye, IconEyeOff, IconUserCheck, IconUserX } from '@tabler/icons';
import moment from 'moment';
import { AppState } from '../../../../../AppState';
import { Api } from '../../../../../api/Api';
import { Report } from '../../../../../api/entities/Report';
import { Translate } from '../../../../../utils/Translate';
import { classNames } from '../../../../../utils/classNames';
import { Accordion } from '../../../../utils/Accordion';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { ListEndText } from '../../../../utils/ListEndText';
import { Spinner } from '../../../../utils/Spinner';
import { ReportEditor } from './ReportEditor';

export interface IPublicReportsEditorProps {
    appState: AppState;
}

export function PublicReportsEditor(props: IPublicReportsEditorProps) {
    const { appState } = props;

    const { data, loading, error, refetch } = useQuery(Api.queries.report.my(appState.activeMember.id));

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const reports = (data.auth.as.me.reports.items as Report[])
        .sort((a, b) => moment(b.date).diff(a.date) || b.id - a.id)
        .reduce(
            (prev, curr) => ({
                ...prev,
                [curr.id]: {
                    title: (
                        <div
                            className={classNames(
                                !curr.ready ? 'text-danger' : !curr.public ? 'text-warning' : false,
                                'd-flex',
                                'align-items-center',
                            )}
                        >
                            <div className="me-2">{curr.ready ? <IconUserCheck /> : <IconUserX />}</div>
                            <div className="me-2">{curr.public ? <IconEye /> : <IconEyeOff />}</div>
                            {Translate.message('reportsEditor.item.title', 'Kronika ') +
                                (curr.date ? moment(curr.date).format('dddd DD.MM.YYYY') : '') +
                                Translate.message('reportsEditor.item.event', ' z akce ') +
                                '"' +
                                curr.event.title +
                                "'"}
                        </div>
                    ),
                    content: (
                        <>
                            <ReportEditor
                                appState={appState}
                                event={curr.event}
                                onSave={() => refetch()}
                                report={curr}
                            />
                        </>
                    ),
                },
            }),
            {},
        );

    return (
        <div className="col-12">
            <h1>{Translate.message('publicReportsEditor.title', 'Moje kroniky')}</h1>
            <h6>{Translate.message('publicReportsEditor.subtitle', 'Ale ale ale, že by tu někdo dlužil kroniky?')}</h6>
            <div className="mt-5">
                {Object.keys(reports).length > 0 ? (
                    <Accordion
                        tabs={{
                            ...reports,
                        }}
                    />
                ) : (
                    <ListEndText>
                        {Translate.message(
                            'publicReportsEditor.noItems',
                            'Vypadá to, že na tebe ještě žádné kroniky nepřipadly...',
                        )}
                    </ListEndText>
                )}
            </div>
        </div>
    );
}
