import { useMutation } from '@apollo/client';
import { IconAlertTriangle, IconCheck, IconSquareCheck, IconSquareX, IconX } from '@tabler/icons';
import * as React from 'react';
import { Api } from '../../../../api/Api';
import { MembershipRequest } from '../../../../api/entities/Member';
import { AppState } from '../../../../AppState';

interface IRequestResolveButtonsProps {
    appState: AppState;
    request: MembershipRequest;
}

export function RequestStatus(props: IRequestResolveButtonsProps) {
    const options = {
        variables: {
            memberId: props.appState.activeMember.id,
            requestId: props.request.id,
        },
    };
    const [accept, acceptStatus] = useMutation(Api.mutations.member.acceptMembershipRequest, options);
    const [decline, declineStatus] = useMutation(Api.mutations.member.declineMembershipRequest, options);

    if (acceptStatus.loading || declineStatus.loading)
        return <span className="badge bg-primary me-2">Ukládání...</span>;
    if (acceptStatus.error || declineStatus.error) return <IconAlertTriangle />;

    let status = props.request.status;
    if (acceptStatus.data) {
        status = 'accepted';
    }
    if (declineStatus.data) {
        status = 'declined';
    }

    switch (status) {
        case 'pending':
            return (
                <>
                    <span className="badge bg-danger me-2">Čeká</span>
                    <IconSquareCheck className="link blue" onClick={() => accept()} />
                    <IconSquareX className="link blue" onClick={() => decline()} />
                </>
            );
        case 'accepted':
            return <IconCheck />;
        case 'declined':
            return <IconX />;
    }
    return <></>;
}
