import { IconCheck, IconX } from '@tabler/icons';
import * as React from 'react';
import { Translate } from '../../utils/Translate';

export function validatePassword(password: string) {
    type requirement = (letter: string, string: string) => boolean;

    const requirements: { [key: string]: requirement } = {
        length: (_, string) => string.length >= 6,
        lowercase: (char) => char >= 'a' && char <= 'z',
        uppercase: (char) => char >= 'A' && char <= 'Z',
        symbol: (char) => '~`!@#$%^&*()_-+={[}]|\\:;"\'<,>.?/1234567890'.includes(char),
    };

    let fullfilled: string[] = [];
    let failed: string[] = [];

    Object.entries(requirements).forEach(([key, validator]) =>
        [...password].reduce((prev, curr) => prev || validator(curr, password), false)
            ? fullfilled.push(key)
            : failed.push(key),
    );

    return failed;
}

interface IPasswordFeaturesProps {
    password: string;
}

export function PasswordFeatures(props: IPasswordFeaturesProps) {
    const failed = validatePassword(props.password);
    const tests = {
        length: Translate.message('register.passwordFeatures.length', 'Délka alespoň 6 znaků'),
        lowercase: Translate.message('register.passwordFeatures.lowercase', 'Alespoň jedno malé písmeno'),
        uppercase: Translate.message('register.passwordFeatures.uppercase', 'Alespoň jedno velké písmeno'),
        symbol: Translate.message('register.passwordFeatures.symbol', 'Alespoň jeden speciální znak, nebo jedno číslo'),
    };

    return (
        <>
            {Object.entries(tests).map(([key, value]) => (
                <div key={key} className="passwordValidation">
                    {failed.includes(key) ? <IconX /> : <IconCheck />}
                    {value}
                </div>
            ))}
        </>
    );
}
