import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Api } from '../../../../../api/Api';
import { Translate } from '../../../../../utils/Translate';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { Spinner } from '../../../../utils/Spinner';
import { IStepProps } from '../Apply';

export function StepSubmit(props: IStepProps) {
    const [iterator, forceUpdate] = React.useState(0);
    const [request, { loading, error, data }] = useMutation(Api.mutations.member.createMembershipRequest);

    if (loading) return <Spinner />;
    if (error)
        return (
            <>
                <ErrorDisplay error={error} />
                <div className="mt-5 d-flex flex-row align-items-center">
                    <div className="d-inline-block text-start">
                        <span className="link blue" onClick={() => forceUpdate(iterator + 1)}>
                            {Translate.message('application.prev', 'Zpět')}
                        </span>
                    </div>
                    <div className="d-inline-block text-end  flex-grow-1"></div>
                </div>
            </>
        );

    if (data) {
        props.next({});
    }

    return (
        <>
            <h2 className="mb-4">
                {' '}
                <Translate.Component name="application.submit.title" alt="Poslední krok" />
            </h2>
            <div className="mb-5">
                <p>
                    <Translate.Component
                        name="application.submit.subtitle"
                        alt="Nyní můžeš oficiálně podat žádost o členství v Duze."
                    />
                </p>
                <p>
                    <Translate.Component
                        name="application.submit.disclaimer"
                        alt="Požádáním souhlasíš se zpracováním osobních údajů."
                    />
                </p>
            </div>
            <p className="text-center text-success">
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        request({
                            variables: {
                                memberId: props.memberId,
                            },
                        });
                    }}
                >
                    <Translate.Component name="application.submit.button" alt="Podat přihlášku" />
                </button>
            </p>
        </>
    );
}
