import { useQuery } from '@apollo/client';
import { IconPlus } from '@tabler/icons';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Api } from '../../../../api/Api';
import { Member } from '../../../../api/entities/Member';
import { Rights } from '../../../../api/Rights';
import { AppState } from '../../../../AppState';
import { hasRights } from '../../../../utils/hasRights';
import { Translate } from '../../../../utils/Translate';
import { IPagedViewProps, PagedView } from '../../../utils/dataDisplayComponents/pagedView/PagedView';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { Routes } from '../../Routes';
import { Counselors } from './Counselors';
import { memberColumns } from './memberColumns';
import { memberFilterPresets } from './memberFilterPresets';
import { memberFilters } from './memberFilters';
import { MembersDetail } from './MembersDetail';
import { MembersEdit } from './MembersEdit';
import { MembersNew } from './MembersNew';
import { requestColumns } from './requestColumns';

interface IMembersPageProps {
    appState: AppState;
}

export function MembersPage(props: IMembersPageProps) {
    const {
        loading,
        error,
        data: orgRolesData,
    } = useQuery(Api.queries.organizationRoles(props.appState.activeMember.id));

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const pagedViewProps: IPagedViewProps<Member> = {
        appState: props.appState,
        query: Api.queries.member.list(props.appState.activeMember.id),
        presets: memberFilterPresets,
        dataResolver: (data) => data.auth.as.members,
        columns: memberColumns(props.appState),
        defaultSortOrder: {
            column: 'lastname',
            direction: 'ASC',
        },
        searchFilter: 'names',
        filters: memberFilters(orgRolesData),
        actions: [
            {
                icon: (
                    <>
                        <IconPlus /> {Translate.message('members.newItem', 'Nový člen')}
                    </>
                ),
                linkTo: Routes.membersNew(props.appState.memberId),
            },
        ],
    };

    const requestPagedViewProps: IPagedViewProps<Member> = {
        appState: props.appState,
        query: Api.queries.member.requests(props.appState.activeMember.id),
        dataResolver: (data) => data.auth.as.membershipRequests,
        columns: requestColumns(props.appState),
        defaultSortOrder: {
            column: 'id',
            direction: 'DESC',
        },
    };

    return (
        <Switch>
            <Route path={Routes.members(props.appState.memberId)} exact>
                {hasRights(props.appState, Rights.MEMBER_DETAIL) ? (
                    <Redirect to={Routes.activeMembers(props.appState.memberId)} />
                ) : (
                    <Redirect to={Routes.counselors(props.appState.memberId)} />
                )}
            </Route>
            <Route path={Routes.membersNew(props.appState.memberId)}>
                <MembersNew {...props} />
            </Route>
            <Route path={Routes.membersEdit(props.appState.memberId)}>
                <MembersEdit {...props} />
            </Route>
            <Route path={Routes.membersDetail(props.appState.memberId)}>
                <MembersDetail {...props} />
            </Route>
            <Route path={Routes.counselors(props.appState.memberId)}>
                <Counselors {...props} />
            </Route>
            <Route path={Routes.activeMembers(props.appState.memberId)}>
                <div className="col-12">
                    <h1>{Translate.message('members.active.title', 'Současní členové')}</h1>
                    <h6>{Translate.message('members.active.subtitle', 'Seznam všech současných členů')}</h6>
                    <PagedView
                        {...pagedViewProps}
                        defaultPreset={{
                            validMembership: 'true',
                        }}
                    />
                </div>
            </Route>
            <Route path={Routes.membersList(props.appState.memberId)}>
                <div className="col-12">
                    <h1>{Translate.message('members.all.title', 'Všichni členové')}</h1>
                    <h6>{Translate.message('members.all.subtitle', 'Seznam všech členů')}</h6>
                    <PagedView {...pagedViewProps} />
                </div>
            </Route>
            <Route path={Routes.membersRequests(props.appState.memberId)}>
                <div className="col-12">
                    <h1>{Translate.message('members.requests.title', 'Žádosti o členství')}</h1>
                    <h6>{Translate.message('members.requests.subtitle', 'Seznam všech žádostí o členství')}</h6>
                    <PagedView {...requestPagedViewProps} />
                </div>
            </Route>
            <Route>
                <Redirect to={Routes.members(props.appState.memberId)} />
            </Route>
        </Switch>
    );
}
