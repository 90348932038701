import { useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Event } from '../../../../api/entities/Event';
import { Translate } from '../../../../utils/Translate';
import { capitalize } from '../../../../utils/capitalize';
import { classNames } from '../../../../utils/classNames';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { EventHeading } from '../../../utils/EventHeading';
import { ListEndText } from '../../../utils/ListEndText';
import { Spinner } from '../../../utils/Spinner';
import { EventsCalendarView } from '../../../utils/dataDisplayComponents/events/EventsCalendarView';

interface IEventsUpcomingProps {
    appState: AppState;
}

export function EventsUpcoming(props: IEventsUpcomingProps) {
    const { loading, error, data } = useQuery(Api.queries.event.list(props.appState.activeMember.id), {
        variables: {
            filter: {
                from: moment().startOf('month').subtract(1, 'week').format('YYYY-MM-DD'),
            },
            sortOrder: [
                {
                    column: 'from',
                    direction: 'ASC',
                },
            ],
        },
    });

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const events = data.auth.as.events.items as Event[];
    const months = Object.values(
        events.reduce(
            (prev, cur) => ({ ...prev, [moment(cur.from).format('YYYY-MM')]: moment(cur.from).startOf('month') }),
            {} as { [key: string]: Moment },
        ),
    )
        .sort((a, b) => a.valueOf() - b.valueOf())
        .filter((month) => moment(month).endOf('month').isSameOrAfter());

    return (
        <div className="col-12">
            <h1>{Translate.message('events.upcoming.title', 'Plánované akce')}</h1>
            <h6>
                {Translate.message(
                    'events.upcoming.subtitle',
                    'Tady můžeš najít všechny důležité informace o plánovaných akcích',
                )}
            </h6>

            {months.map((month, i) => (
                <div key={i} className="w-100 d-flex flex-column flex-lg-row mt-5">
                    <div className="d-none d-lg-block col-5">
                        <div className="block sticky-top">
                            <EventsCalendarView date={month.toDate()} appState={props.appState} />
                        </div>
                    </div>
                    <div className="col-lg-7 col-12 ps-lg-5 ps-0">
                        <h2 className="d-block d-lg-none">{capitalize(month.format('MMMM'))}</h2>
                        {(() => {
                            const eventsThisMonth = events.filter(
                                (event) =>
                                    moment(event.from).isBetween(month, moment(month).endOf('month')) &&
                                    moment(event.to).endOf('day').isSameOrAfter(),
                            );

                            if (eventsThisMonth.length === 0) {
                                return (
                                    <ListEndText>
                                        {Translate.message(
                                            'events.noMoreThisMonth',
                                            'Tento měsíc nás už žádné další akce nečekají, ale koukni dál, určitě tu pro tebe něco máme!',
                                        )}
                                    </ListEndText>
                                );
                            }

                            return eventsThisMonth.map((event, i) => (
                                <div key={i} className={classNames('block')}>
                                    <EventHeading appState={props.appState} event={event} key={i} details={true} />
                                </div>
                            ));
                        })()}
                    </div>
                </div>
            ))}

            <ListEndText>
                {Translate.message('events.lidtEnd', 'A to je zatím všechno... ale časem sem doplníme další akce!')}
            </ListEndText>
        </div>
    );
}
