import { Api } from '../api/Api';
import { AppState } from '../AppState';

export const quillProps: (appState: AppState) => {
    formats: string[];
    modules: { [key: string]: any };
} = (appState) => ({
    modules: {
        toolbar: [
            [{ header: ['1', '2', '3', false] }],
            ['bold', 'italic', 'underline', 'clean'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
        ],
        imageUploader: {
            upload: async (file: any) => {
                const data = await Api.client.mutate({
                    mutation: Api.mutations.uploadFile,
                    variables: {
                        memberId: appState.activeMember.id,
                        file,
                    },
                });
                if (!data?.data?.auth?.as?.uploadFile?.url) throw new Error('[Quill] Upload failed!');

                const url = data.data.auth.as.uploadFile.url as string;
                return url;
            },
        },
        blotFormatter: {},
    },
    formats: [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'imageBolt',
    ],
});
