import { useMutation, useQuery } from '@apollo/client';
import { IconMail } from '@tabler/icons';
import * as React from 'react';
import { Api } from '../../../../api/Api';
import { User } from '../../../../api/entities/User';
import { gqlErrorToString } from '../../../../utils/gqlErrorToString';
import { Translate } from '../../../../utils/Translate';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { FormInput } from '../../../utils/inputs/FormInput';
import { Spinner } from '../../../utils/Spinner';
import { IProfileEditorProps } from './ProfileEditor';

export function ManagersEditor(props: IProfileEditorProps) {
    const member = props.appState.activeMember;

    const [email, setEmail] = React.useState('');
    const { loading, error, data, refetch } = useQuery(Api.queries.member.managers(member.id));
    const [add, addStatus] = useMutation(Api.mutations.member.managers.add, { onCompleted: () => refetch() });
    const [remove] = useMutation(Api.mutations.member.managers.remove, { onCompleted: () => refetch() });

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    return (
        <>
            <p>
                {Translate.message(
                    'managersEditor.description',
                    'Tady můžeš k tomuto úču přidat další správce, kteří budou moct spravovat tvůj účet.',
                )}
            </p>
            <p>
                <strong className="text-danger">
                    {Translate.message(
                        'managersEditor.warning.1',
                        'Pozor! kdokoliv, koho sem přidáš bude mít všechna práva, která máš na tomto účtu ty, tedy i přidávat další správce, nebo třeba odebrat tebe.',
                    )}
                    <br />
                    {Translate.message('managersEditor.warning.2', 'Zkontroluj si, prosím, několikrát zadaný email!')}
                </strong>
            </p>
            <h3 className="section-heading">
                {Translate.message('managersEditor.title2', 'Správci člena {{name}}', {
                    name: `${member.firstname} "${member.nickname}" ${member.lastname}`,
                })}
            </h3>
            <table className="w-100 mt-4">
                <tbody>
                    {(data.auth.as.memberById.managingUsers as { user: User }[]).map((user, i) => (
                        <tr key={i}>
                            <td className="pb-2 text-nowrap">
                                <IconMail className="ms-1 me-3" />
                                {user.user.email}
                                {user.user.email === data.auth.user.email && (
                                    <em> ({Translate.message('general.managersSelf', 'tento účet')})</em>
                                )}
                            </td>
                            <td className="pb-2 text-nowrap">
                                <ConfirmButton
                                    label={Translate.message('general.remove', 'Remove')}
                                    disabled={data.auth.as.memberById.managingUsers.length === 1}
                                    onConfirm={() =>
                                        remove({
                                            variables: {
                                                memberId: member.id,
                                                email: user.user.email,
                                            },
                                        })
                                    }
                                    className="ms-4"
                                />
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td className="pt-3 align-top">
                            <FormInput
                                type="text"
                                label={Translate.message('profilePage.email', 'Email')}
                                value={email}
                                onChange={(value) => setEmail(value)}
                                error={gqlErrorToString(addStatus.error)}
                            />
                        </td>
                        <td className="pt-3 align-top">
                            <ConfirmButton
                                label={Translate.message('general.add', 'Přidat')}
                                color="primary"
                                onConfirm={() => {
                                    add({
                                        variables: {
                                            memberId: member.id,
                                            email,
                                        },
                                    });
                                    setEmail('');
                                }}
                                className="ms-4"
                                disabled={addStatus.loading}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
