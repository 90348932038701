import { gql, useMutation } from '@apollo/client';
import { AppState } from '../../../../../AppState';
import { Api } from '../../../../../api/Api';
import { Meeting } from '../../../../../api/entities/Meeting';
import { Member } from '../../../../../api/entities/Member';
import { Translate } from '../../../../../utils/Translate';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { ProfilePreview } from '../../../../utils/ProfilePreview';
import { SearchInput } from '../../../../utils/inputs/SearchInput';

interface IMeetingMembersEditorProps {
    appState: AppState;
    meeting: Meeting;
    refetch: () => void;
}

export function MeetingMembersEditor(props: IMeetingMembersEditorProps) {
    const { meeting, appState } = props;
    const [attend, { error: e1 }] = useMutation(
        gql`
            mutation attendMeeting($memberId: Int!, $meeting: Int!, $member: Int!) {
                auth {
                    as(memberId: $memberId) {
                        attendMeeting(meetingId: $meeting, memberId: $member)
                    }
                }
            }
        `,
    );

    const [unattend, { error: e2 }] = useMutation(
        gql`
            mutation unattendMeeting($memberId: Int!, $meeting: Int!, $member: Int!) {
                auth {
                    as(memberId: $memberId) {
                        unattendMeeting(meetingId: $meeting, memberId: $member)
                    }
                }
            }
        `,
    );

    if (e1) return <ErrorDisplay error={e1} />;
    if (e2) return <ErrorDisplay error={e2} />;

    return (
        <>
            <SearchInput<Member>
                type="text"
                label={Translate.message('events.fields.leaders', 'Vedoucí akce')}
                appState={appState}
                query={Api.queries.member.search}
                resultResolver={(data) => data?.auth?.as?.memberById}
                searchResolver={(data) => data?.auth?.as?.members}
                itemDisplayJSX={(member) => (
                    <>
                        {member.firstname} "{member.nickname}" {member.lastname}
                    </>
                )}
                itemDisplayText={(member) => `${member.firstname} "${member.nickname}" ${member.lastname}`}
                onChange={async (value) => {
                    if (value) {
                        await attend({
                            variables: {
                                memberId: appState.activeMember.id,
                                meeting: meeting.id,
                                member: value,
                            },
                        });
                        props.refetch();
                    }
                }}
                className="mb-3"
                clearAfterInsert
            />
            <div>
                {meeting.members.map((member, i) => (
                    <ProfilePreview
                        roles
                        key={i}
                        appState={appState}
                        id={member.id}
                        className="link cross-on-hover"
                        onClick={async () => {
                            await unattend({
                                variables: {
                                    memberId: appState.activeMember.id,
                                    meeting: meeting.id,
                                    member: member.id,
                                },
                            });
                            props.refetch();
                        }}
                    />
                ))}
            </div>
        </>
    );
}
