import * as React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { IPageProps, Page } from './Page';

export interface IPagedViewProps<T> extends IPageProps<T> {}

export function PagedView<T>(props: IPagedViewProps<T>) {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/:pageNum`}>
                <Page {...props} />
            </Route>
            <Route>
                <Redirect to={`${url}/0`} />
            </Route>
        </Switch>
    );
}
