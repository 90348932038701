import { useQuery } from '@apollo/client';
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { Api } from '../../../../api/Api';
import { Article } from '../../../../api/entities/Article';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { ArticlesEditor } from './ArticlesEditor';

interface IArticlesEditProps {
    appState: AppState;
}

export function ArticlesEdit(props: IArticlesEditProps) {
    const {
        params: { detailId },
    } = useRouteMatch<{ detailId: string }>();
    const id = parseInt(detailId);

    const { loading, error, data } = useQuery(Api.queries.article.detail(props.appState.activeMember.id), {
        variables: {
            id,
        },
    });

    if (isNaN(id))
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const article = data.auth.as.articleById as Article;

    return <ArticlesEditor appState={props.appState} article={article} />;
}
