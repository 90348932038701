import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Api } from '../../../../api/Api';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { ProfileEditor } from './ProfileEditor';

interface IProfilePageProps {
    appState: AppState;
}

export function ProfilePage(props: IProfilePageProps) {
    const { appState } = props;
    const { loading, error, data, refetch } = useQuery(Api.queries.member.detail(appState.activeMember.id), {
        variables: {
            id: appState.activeMember.id,
        },
    });

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    return (
        <div className="col-12">
            <h1>{Translate.message('profilePage.title', 'Tvůj profil')}</h1>
            <h6>
                {Translate.message(
                    'profilePage.description',
                    'Tady si můžeš aktualizovat osobní údaje a další informace',
                )}
            </h6>

            <ProfileEditor appState={appState} member={data.auth.as.memberById} refetch={() => refetch()} />
        </div>
    );
}
