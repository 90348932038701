import * as React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from '../../../../../utils/Translate';
import { Routes } from '../../../Routes';
import { IStepProps } from '../Apply';

export function StepIntro(props: IStepProps) {
    return (
        <>
            <h2 className="mb-4">
                <Translate.Component name="application.intro.title" alt="Ještě nejste členem?" />
            </h2>
            <div className="mb-5">
                <p>
                    <Translate.Component
                        name="application.intro.subtitle"
                        alt="Vyplňte tuto přihlášku a my se vám obratem ozveme s dalšími informacemi."
                    />
                </p>
                <p>
                    <Translate.Component
                        name="application.intro.disclaimer"
                        alt="Prosíme o <<strong&pečlivé vyplnění všech osobních údajů>>. Tuto přihlášku bereme, <<strong&jako závaznou>>."
                    />
                </p>
            </div>
            <div className="mt-5 d-flex flex-row align-items-center">
                <div className="d-inline-block text-start">
                    <Link to={Routes.auth()}>{Translate.message('application.close', 'Zpět na výběr člena')}</Link>
                </div>
                <div className="d-inline-block text-end  flex-grow-1">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            props.setStep(props.step + 1);
                        }}
                    >
                        {Translate.message('application.start', 'Začít')}
                    </button>
                </div>
            </div>
        </>
    );
}
