type error = { messageId: string; context: { [key: string]: any } };
export type validator = (value: string) => error | void;

export function validate(value: any, validators: validator[]) {
    return validators.reduce((prev, curr) => {
        const result = curr(value);
        if (result) {
            prev.push(result);
        }
        return prev;
    }, [] as error[]);
}
