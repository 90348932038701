import { gql, useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { AppState } from '../../../../../AppState';
import { Meeting } from '../../../../../api/entities/Meeting';
import { Translate } from '../../../../../utils/Translate';
import { ConfirmButton } from '../../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { Spinner } from '../../../../utils/Spinner';
import { Routes } from '../../../Routes';

interface IMeetingOtherEditorProps {
    appState: AppState;
    meeting: Meeting;
}

export function MeetingOtherEditor(props: IMeetingOtherEditorProps) {
    const { meeting, appState } = props;
    const [del, { loading, error, data }] = useMutation(
        gql`
            mutation deleteMeeting($memberId: Int!, $id: Int!) {
                auth {
                    as(memberId: $memberId) {
                        deleteMeeting(meetingId: $id)
                    }
                }
            }
        `,
        {
            variables: {
                memberId: appState.activeMember.id,
                id: meeting.id,
            },
        },
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    if (data) return <Redirect to={Routes.meetingsAdmin(appState.memberId)} />;

    return (
        <>
            <h3>{Translate.message('meetings.editor.remove.title', 'Odstranit schůzku')}</h3>
            <p className="text-danger">
                {Translate.message(
                    'meetings.editor.remove.desc',
                    'Kliknutím na tlačítko dojde k odstranění této schůzky. Tato operace je nevratná, prosím postupujte opatrně!',
                )}
            </p>
            <ConfirmButton
                label={Translate.message('meetings.editor.remove.button', 'Nenávratně odstranit schůzku')}
                onConfirm={() => del()}
            />
        </>
    );
}
