import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';
import { Api } from '../../../../api/Api';
import { OrganizationRole } from '../../../../api/entities/OrganizationRole';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { FormInput } from '../../../utils/inputs/FormInput';
import { RoleIcon } from '../../../utils/RoleIcon';
import { Spinner } from '../../../utils/Spinner';
import { IProfileEditorProps } from './ProfileEditor';

export function OrganizationRoleEditor(props: IProfileEditorProps) {
    const { appState, member, refetch } = props;
    const queryStatus = useQuery(Api.queries.organizationRoles(props.appState.activeMember.id));
    const [add, addStatus] = useMutation(Api.mutations.organizationRoles.add, {
        onCompleted: () => refetch(),
    });
    const [remove, removeStatus] = useMutation(Api.mutations.organizationRoles.remove, {
        onCompleted: () => refetch(),
    });

    const loading = queryStatus.loading || addStatus.loading || removeStatus.loading;
    const error = queryStatus.error || addStatus.error || removeStatus.error;

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const roles = queryStatus.data.auth.as.organizationRoles.items as OrganizationRole[];

    return (
        <>
            {roles.map((role, i) => (
                <FormInput
                    type="checkbox"
                    label={
                        <>
                            <RoleIcon role={role} className="ms-1 me-2" /> {role.title}
                        </>
                    }
                    value={member.organizationRoles.some((r) => r.id === role.id)}
                    onChange={(value) => {
                        const options = {
                            variables: {
                                asId: appState.activeMember.id,
                                memberId: member.id,
                                roleId: role.id,
                            },
                        };
                        value ? add(options) : remove(options);
                    }}
                    key={i}
                />
            ))}
        </>
    );
}
