import { gql, useQuery } from '@apollo/client';
import { IconRefresh } from '@tabler/icons';
import { useState } from 'react';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { LoggerConsole } from '../../../../logs/Logger';

interface ILogsProps {
    appState: AppState;
}

export function LogsPage(props: ILogsProps) {
    const [r, setR] = useState(false);
    const update = () => setR(!r);

    const { error, loading, data } = useQuery(
        gql`
            {
                version
            }
        `,
    );

    return (
        <div className="col-12">
            <h1>Welcome developer!</h1>
            <h6>Congratulations! The server is still not in flames!</h6>
            <h2 className="section-heading">Info</h2>
            <p>
                <strong>Frontend: </strong>
                accessed from{' '}
                <a href={window.location.origin} target="_blank" rel="noreferrer">
                    {window.location.origin}
                </a>{' '}
                - version {props.appState.appVersion}
                <br />
                <strong>Backend: </strong>
                running on{' '}
                <a href={Api.apiUrl} target="_blank" rel="noreferrer">
                    {Api.apiUrl}
                </a>{' '}
                -{' '}
                {error && (
                    <>
                        Error vetching from API:
                        <br />
                        <pre>
                            <code>{error}</code>
                        </pre>
                    </>
                )}
                {loading && 'Loading...'}
                {data && 'version ' + data.version}
            </p>
            <p>
                {(document as any).bonfire ? (
                    <button type="button" className="btn btn-success">
                        Bonfire published to console as document.bonfire
                    </button>
                ) : (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            (document as any).bonfire = {
                                appState: props.appState,
                                api: Api,
                            };
                            update();
                        }}
                    >
                        Publish Bonfire to console
                    </button>
                )}
            </p>
            {/* <h2 className="section-heading">AppState</h2>
            <pre>
                <code>{JSON.stringify(props.appState, undefined, 4)}</code>
            </pre> */}
            <h2 className="section-heading">
                Logs <IconRefresh onClick={() => update()} style={{ cursor: 'pointer' }} />
            </h2>
            {(console as LoggerConsole)._logs.map((log) => (
                <div
                    className={'alert alert-' + { log: 'light', warn: 'warning', error: 'danger' }[log.severity]}
                    role="alert"
                >
                    <pre className="mb-0">
                        <code>{`${log.timestamp.toISOString()} (${log.url})\n${log.content.join('\n')}`}</code>
                    </pre>
                </div>
            ))}
        </div>
    );
}
