import moment from 'moment';

const prefix = '[Translate]';

export class Translate {
    private static languages: { [key: string]: localeStrings } = {};
    private static rules: { [key: string]: localeRules } = {};
    private static locale: bcp47 = 'en-US';

    private static missingTranslations: Set<string> = new Set();

    public static importLanguage(strings: localeStrings, rules: localeRules, locale: bcp47) {
        Translate.languages[locale] = strings;
        Translate.rules[locale] = rules;
    }

    public static selectLanguage(locale: bcp47) {
        this.locale = locale;
        console.log(`${prefix} Translations locale set to ${locale}`);
        moment.locale(locale.split('-')[0]);
        console.log(`${prefix} Moment.js locale set to ${moment.locale()}`);
    }

    public static message(name: string, alt?: string, context?: { [key: string]: string | number }) {
        const path = name.split('.');
        const locale = Translate.languages[Translate.locale] ? Translate.locale : 'en-US';
        if (locale !== Translate.locale) {
            // console.warn(`${prefix} Language '${Translate.locale}' not imported, using default 'en-US'`);
        }

        let result: string | localeStrings = Translate.languages[locale];

        path.forEach((step) => {
            if (!result) {
                // console.warn(`${prefix} Key ${step} not found on path ${name}!`);
                return alt || name;
            }
            result = (result as any)[step] || {};
        });

        if (typeof result !== 'string') {
            Translate.missingTranslations.add(name);
            (window as any).missingTranslations = Translate.missingTranslations;
            // console.warn(`${prefix} Missing translation message is '${Translate.locale}' for '${name}'`);
            result = alt || name;
        }

        if (context) {
            const regex = /{{([^}|?]+?)(\?([a-zA-Z]+?)((\|[^}|]+?)+?))?}}/m;
            let match;
            while ((match = (result as string).match(regex))) {
                const [whole, variable, isRuled, rule, options] = match as string[];
                const varValue = context[variable];

                if (isRuled) {
                    // Ruled match
                    const evaluator = Translate.rules[locale][rule] as ((value: any) => number) | null;
                    const split = options.split('|');
                    let chosen = split[1];
                    if (evaluator) {
                        chosen = split[((evaluator(varValue) - 1) % (split.length - 1)) + 1];
                    }
                    result = result.replace(whole, chosen);
                } else {
                    // Basic match
                    result = result.replace(
                        whole,
                        varValue !== null && varValue !== undefined ? varValue.toString() : variable,
                    );
                }
            }
        }

        return result;
    }

    public static Component(props: {
        name: string;
        alt?: string;
        context?: { [key: string]: string | number };
        wrappers?: { [key: string]: (children: string, key: number | string) => JSX.Element };
    }) {
        const elements: (JSX.Element | string)[] = [Translate.message(props.name, props.alt, props.context)];

        const defaultWrappers: { [key: string]: (children: string, key: number | string) => JSX.Element } = {
            strong: (children, key) => <strong key={key}>{children}</strong>,
            em: (children, key) => <em key={key}>{children}</em>,
        };

        const wrappers = props.wrappers ? { ...defaultWrappers, ...props.wrappers } : defaultWrappers;

        if (wrappers) {
            const regex = /<<([a-zA-Z0-9]+?)&([^}]+?)>>/m;
            for (let i = 0; i < elements.length; i++) {
                if (typeof elements[i] !== 'string') continue;
                const string = elements[i] as string;

                let match = string.match(regex);
                if (!match) continue;
                const [whole, key, children] = match as string[];
                const wrapper = wrappers[key];

                const [before, after] = string.split(whole, 2);
                elements[i] = before;
                elements.push(wrapper ? wrapper(children, i) : children);
                elements.push(after);
            }
        }

        return <>{elements}</>;
    }
}

type localeRules = { [key: string]: (value: any) => number };

type localeStrings = {
    [key: string]: string | localeStrings;
};

type bcp47 =
    | 'ar-SA' //Arabic - Saudi Arabia
    | 'bn-BD' //Bangla - Bangladesh
    | 'bn-IN' //Bangla - India
    | 'cs-CZ' //Czech - Czech Republic
    | 'da-DK' //Danish - Denmark
    | 'de-AT' //German - Austria
    | 'de-CH' //German - Switzerland
    | 'de-DE' //German - Germany
    | 'el-GR' //Greek - Greece
    | 'en-AU' //English - Australia
    | 'en-CA' //English - Canada
    | 'en-GB' //English - United Kingdom
    | 'en-IE' //English - Ireland
    | 'en-IN' //English - India
    | 'en-NZ' //English - New Zealand
    | 'en-US' //English - United States
    | 'en-ZA' //English - South Africa
    | 'es-AR' //Spanish - Argentina
    | 'es-CL' //Spanish - Chile
    | 'es-CO' //Spanish - Columbia
    | 'es-ES' //Spanish - Spain
    | 'es-MX' //Spanish - Mexico
    | 'es-US' //Spanish - United States
    | 'fi-FI' //Finnish - Finland
    | 'fr-BE' //French - Belgium
    | 'fr-CA' //French - Canada
    | 'fr-CH' //French - Switzerland
    | 'fr-FR' //French - France
    | 'he-IL' //Hebrew - Israel
    | 'hi-IN' //Hindi - India
    | 'hu-HU' //Hungarian - Hungary
    | 'id-ID' //Indonesian - Indonesia
    | 'it-CH' //Italian - Switzerland
    | 'it-IT' //Italian - Italy
    | 'jp-JP' //Japanese - Japan
    | 'ko-KR' //Korean - Republic of Korea
    | 'nl-BE' //Dutch - Belgium
    | 'nl-NL' //Dutch - The Netherlands
    | 'no-NO' //Norwegian - Norway
    | 'pl-PL' //Polish - Poland
    | 'pt-BR' //Portugese - Brazil
    | 'pt-PT' //Portugese - Portugal
    | 'ro-RO' //Romanian - Romania
    | 'ru-RU' //Russian - Russian Federation
    | 'sk-SK' //Slovak - Slovakia
    | 'sv-SE' //Swedish - Sweden
    | 'ta-IN' //Tamil - India
    | 'ta-LK' //Tamil - Sri Lanka
    | 'th-TH' //Thai - Thailand
    | 'tr-TR' //Turkish - Turkey
    | 'zh-CN' //Chinese - China
    | 'zh-HK' //Chinese - Hond Kong
    | 'zh-TW'; //Chinese - Taiwan
