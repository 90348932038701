import { gql } from '@apollo/client';
import { IconPlus } from '@tabler/icons';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { PagedView } from '../../../utils/dataDisplayComponents/pagedView/PagedView';
import { Routes } from '../../Routes';
import { MeetingsCurrentPage } from './MeetingsCurrent';
import { MeetingsEditPage } from './MeetingsEdit';
import { MeetingsMyPage } from './MeetingsMy';
import { MeetingsMyDetailPage } from './MeetingsMyDetail';
import { MeetingsNewPage } from './MeetingsNew';
import { MeetingsPlannerPage } from './MeetingsPlanner';
import { meetingColumns } from './meetingColumns';
import { meetingFilters } from './meetingFilters';

interface IMeetingsPageProps {
    appState: AppState;
}

export function MeetingsPage(props: IMeetingsPageProps) {
    return (
        <Switch>
            <Route path={Routes.meetings(props.appState.memberId)} exact>
                <Redirect to={Routes.meetingsMy(props.appState.memberId)} />
            </Route>
            <Route path={Routes.meetingsMy(props.appState.memberId)}>
                <MeetingsMyPage {...props} />
            </Route>
            <Route path={Routes.meetingsCurrent(props.appState.memberId)}>
                <MeetingsCurrentPage {...props} />
            </Route>
            <Route path={Routes.meetingsPlanner(props.appState.memberId)}>
                <MeetingsPlannerPage {...props} />
            </Route>
            <Route path={Routes.meetingsNew(props.appState.memberId)}>
                <MeetingsNewPage {...props} />
            </Route>
            <Route path={Routes.meetingsPlanner(props.appState.memberId)}>Hello</Route>
            <Route path={Routes.meetingsAdmin(props.appState.memberId)}>
                <div className="col-12">
                    <h1>{Translate.message('meetings.all.title', 'Všechny schůzky')}</h1>
                    <h6>{Translate.message('meetings.all.subtitle', 'Seznam všech schůzek')}</h6>
                    <PagedView
                        appState={props.appState}
                        query={gql`
                            query MeetingList($pageInput: PageInput, $filter: MeetingFilter, $sortOrder: [SortOrder]) {
                                auth {
                                    as(memberId: ${props.appState.activeMember.id}) {
                                        meetings(pageInput: $pageInput, filter: $filter, sortOrder: $sortOrder) {
                                            items {
                                                id
                                                title
                                                seasonFrom
                                                seasonTo
                                                firstFrom
                                                firstTo
                                                frequency
                                                frequencyEvery
                                                current
                                                members {
                                                    id
                                                }
                                                schedules {
                                                    happening
                                                }
                                            }
                                            total
                                        }
                                    }
                                }
                            }
                        `}
                        dataResolver={(data) => data.auth.as.meetings}
                        columns={meetingColumns(props.appState)}
                        defaultSortOrder={{
                            column: 'firstFrom',
                            direction: 'DESC',
                        }}
                        searchFilter="title"
                        filters={meetingFilters}
                        presets={[]}
                        actions={[
                            {
                                icon: (
                                    <>
                                        <IconPlus /> {Translate.message('meetings.newItem', 'Nová schůzka')}
                                    </>
                                ),
                                linkTo: Routes.meetingsNew(props.appState.memberId),
                            },
                        ]}
                    />
                </div>
            </Route>
            <Route path={Routes.meetingsEdit(props.appState.memberId)}>
                <MeetingsEditPage {...props} />
            </Route>
            <Route path={Routes.meetingsMyDetail(props.appState.memberId)}>
                <MeetingsMyDetailPage {...props} />
            </Route>

            <Route>
                <Redirect to={Routes.meetings(props.appState.memberId)} />
            </Route>
        </Switch>
    );
}
