import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Api } from '../../../../api/Api';
import { Translate } from '../../../../utils/Translate';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Form } from '../../../utils/inputs/Form';
import { FormInput } from '../../../utils/inputs/FormInput';
import { inRange, nonempty, required } from '../../../utils/inputs/inputValidators/validators';
import { Spinner } from '../../../utils/Spinner';
import { IProfileEditorProps } from './ProfileEditor';

export function CounselorInfoEditor(props: IProfileEditorProps) {
    const { appState, member, refetch } = props;
    const [updateProfile, updateStatus] = useMutation(Api.mutations.member.modify, {
        onCompleted: () => refetch(),
    });
    const [add, addStatus] = useMutation(Api.mutations.member.counselorInfo.add, {
        onCompleted: () => refetch(),
    });
    const [remove, removeStatus] = useMutation(Api.mutations.member.counselorInfo.remove, {
        onCompleted: () => refetch(),
    });

    const loading = updateStatus.loading || addStatus.loading || removeStatus.loading;
    const error = updateStatus.error || addStatus.error || removeStatus.error;

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const birchLeafs = [
        '0. úroveň (Žádný lístek)',
        '1. úroveň (Zelený lístek)',
        '2. úroveň (Světle modrý lístek)',
        '3. úroveň (Tmavě modrý lístek)',
        '4. úroveň (Žlutý lístek)',
        '5. úroveň (Červený lístek)',
        '6. úroveň (Hnědý lístek)',
        '7. úroveň (Bílý lístek)',
        '8. úroveň (Šedý lístek)',
        '9. úroveň (Černý lístek)',
        '10. úroveň (Bronzový lístek)',
        '11. úroveň (Stříbrný lístek)',
        '12. úroveň (Zlatý lístek)',
    ];

    return (
        <>
            <Form
                onSubmit={(value) => {
                    updateProfile({
                        variables: {
                            asId: appState.activeMember.id,
                            memberId: member.id,
                            value: {
                                motd: value.motd,
                                birchLeaf: parseInt(value.birchLeaf),
                            },
                        },
                    });
                }}
                validators={(state) => ({
                    motd: [],
                    birchLeaf: [inRange(0, 12, true)],
                })}
                defaultValues={{
                    motd: props.member.motd,
                    birchLeaf: props.member.birchLeaf,
                }}
            >
                {(inputProps, state, buttonProps) => (
                    <>
                        <div className="mb-5">
                            <FormInput
                                {...inputProps('birchLeaf')}
                                type="select"
                                label={Translate.message('member.fields.birchLeaf', 'Březový lístek')}
                                options={birchLeafs.map((title, value) => ({ title, value }))}
                            />
                            <FormInput
                                {...inputProps('motd', 'w-100')}
                                type="textarea"
                                label={Translate.message('member.fields.motd', 'Prásk')}
                            />
                        </div>
                        <button {...buttonProps}>{Translate.message('member.edit.save', 'Uložit')}</button>
                    </>
                )}
            </Form>
            <h3 className="section-heading">Další informace</h3>
            {member.counselorInfo.map((info) => (
                <div className="d-flex flex-column flex-md-row w-100 mb-3">
                    <FormInput
                        type="text"
                        label={Translate.message('member.fields.counselorInfo.title', 'Položka')}
                        value={info.title}
                        disabled={true}
                        className="me-md-3 mb-3"
                    />
                    <FormInput
                        type="text"
                        label={Translate.message('member.fields.counselorInfo.content', 'Obsah')}
                        options={birchLeafs.map((title, value) => ({ title, value }))}
                        value={info.content}
                        disabled={true}
                        className="flex-grow-1 me-md-3 mb-3"
                    />
                    <ConfirmButton
                        label={Translate.message('general.remove', 'Odebrat')}
                        onConfirm={() =>
                            remove({
                                variables: {
                                    asId: appState.activeMember.id,
                                    infoId: info.id,
                                },
                            })
                        }
                        className="mb-3 flex-shrink-0"
                    />
                </div>
            ))}
            <Form
                onSubmit={(value) => {
                    add({
                        variables: {
                            asId: appState.activeMember.id,
                            memberId: member.id,
                            value: {
                                title: value.title,
                                content: value.content,
                            },
                        },
                    });
                }}
                validators={(state) => ({
                    title: [nonempty, required],
                    content: [nonempty, required],
                })}
                defaultValues={{}}
            >
                {(inputProps, state, buttonProps) => (
                    <div className="d-flex flex-column flex-md-row w-100 mb-3 align-items-start mt-5">
                        <FormInput
                            {...inputProps('title')}
                            type="text"
                            label={Translate.message('member.fields.counselorInfo.title', 'Položka')}
                            className="me-md-3 mb-3"
                        />
                        <FormInput
                            {...inputProps('content')}
                            type="text"
                            label={Translate.message('member.fields.counselorInfo.content', 'Obsah')}
                            className="flex-grow-1 me-md-3 mb-3"
                        />
                        <button {...buttonProps} className="btn btn-primary mb-3 flex-shrink-0">
                            {Translate.message('general.add', 'Přidat')}
                        </button>
                    </div>
                )}
            </Form>
        </>
    );
}
