import moment from 'moment';
import * as React from 'react';
import { Api } from '../../../../../api/Api';
import { Event } from '../../../../../api/entities/Event';
import { Member } from '../../../../../api/entities/Member';
import { AppState } from '../../../../../AppState';
import { Translate } from '../../../../../utils/Translate';
import { ImageUpload } from '../../../../utils/ImageUpload';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { date, nonempty, required, requiredIf } from '../../../../utils/inputs/inputValidators/validators';
import { SearchInput } from '../../../../utils/inputs/SearchInput';
import { ProfilePreview } from '../../../../utils/ProfilePreview';

interface IBasicInfoEditorProps {
    appState: AppState;
    event?: Event;
    onSubmit: (value: { [key: string]: any }) => void;
}

export function BasicInfoEditor(props: IBasicInfoEditorProps) {
    const { event, onSubmit } = props;

    let defaultValues: { [key: string]: any } = {
        title: null,
        imageBefore: null,
        enterTime: false,
        from: moment().startOf('week').add(4, 'days').format('YYYY-MM-DD'),
        fromTime: null,
        to: moment().endOf('week').format('YYYY-MM-DD'),
        toTime: null,
        descriptionBefore: null,
        leaders: [props.appState.activeMember.id],
    };
    if (event) {
        defaultValues = {
            title: event.title,
            imageBefore: event.imageBefore,
            enterTime: !event.allDay,
            from: moment(event.from).format('YYYY-MM-DD'),
            fromTime: event.allDay ? null : moment(event.from).format('HH:mm:ss'),
            to: moment(event.to).format('YYYY-MM-DD'),
            toTime: event.allDay ? null : moment(event.to).format('HH:mm:ss'),
            descriptionBefore: event.descriptionBefore,
            leaders: event.leaders.map((leader) => leader.id),
        };
    }

    return (
        <Form
            defaultValues={defaultValues}
            validators={(data) => ({
                title: [required, nonempty],
                imageBefore: [required, nonempty],
                enterTime: [],
                from: [required, date],
                fromTime: [requiredIf(data.enterTime?.value)],
                to: [required, date],
                toTime: [requiredIf(data.enterTime?.value)],
                descriptionBefore: [required, nonempty],
                leaders: [required, nonempty],
            })}
            onSubmit={(data) => {
                const value = {
                    allDay: !data.enterTime,
                    from: moment(data.from + (data.enterTime ? ' ' + data.fromTime : '')).toISOString(),
                    to: moment(data.to + (data.enterTime ? ' ' + data.toTime : '')).toISOString(),
                    descriptionBefore: data.descriptionBefore,
                    leaders: data.leaders,
                    title: data.title,
                    imageBefore: data.imageBefore,
                };
                onSubmit(value);
            }}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <div className="mb-5">
                        <div>
                            <ImageUpload
                                url={inputProps('imageBefore').value as any}
                                appState={props.appState}
                                size={200}
                                onChange={(value) => inputProps('imageBefore').onChange!(value)}
                                className={inputProps('imageBefore').error ? 'is-invalid' : undefined}
                            />
                            {inputProps('imageBefore').error && (
                                <div className="invalid-feedback">{inputProps('imageBefore').error}</div>
                            )}
                        </div>
                        <FormInput
                            {...inputProps('title', 'd-inline-block')}
                            type="text"
                            label={Translate.message('events.fields.title', 'Název akce')}
                        />
                    </div>
                    <div className="mb-5">
                        <FormInput
                            {...inputProps('enterTime')}
                            type="checkbox"
                            label={Translate.message('events.fields.enterTime', 'Zadat čas')}
                        />
                        <div className="d-flex align-items-start">
                            <FormInput
                                {...inputProps('from')}
                                type="date"
                                label={Translate.message('events.fields.from', 'Začátek')}
                            />
                            <FormInput
                                {...inputProps('fromTime', 'input-time')}
                                type="time"
                                label={Translate.message('events.fields.fromTime', 'Čas začátku')}
                                disabled={!state['enterTime']?.value}
                            />
                        </div>
                        <div className="d-flex align-items-start">
                            <FormInput
                                {...inputProps('to')}
                                type="date"
                                label={Translate.message('events.fields.to', 'Konec')}
                            />
                            <FormInput
                                {...inputProps('toTime', 'input-time')}
                                type="time"
                                label={Translate.message('events.fields.toTime', 'Čas konce')}
                                disabled={!state['enterTime']?.value}
                            />
                        </div>
                    </div>
                    <div className="mb-5">
                        <FormInput
                            {...inputProps('descriptionBefore')}
                            type="textarea"
                            label={Translate.message('events.fields.descriptionBefore', 'Popisek akce')}
                            className="w-100"
                        />
                    </div>
                    <div className="mb-5">
                        <h3>{Translate.message('events.fields.leaders', 'Vedoucí akce')}</h3>
                        <SearchInput<Member>
                            type="text"
                            label={Translate.message('events.fields.leaders', 'Vedoucí akce')}
                            appState={props.appState}
                            query={Api.queries.member.search}
                            resultResolver={(data) => data?.auth?.as?.memberById}
                            searchResolver={(data) => data?.auth?.as?.members}
                            itemDisplayJSX={(member) => (
                                <>
                                    {member.firstname} "{member.nickname}" {member.lastname}
                                </>
                            )}
                            itemDisplayText={(member) => `${member.firstname} "${member.nickname}" ${member.lastname}`}
                            onChange={(value) =>
                                value &&
                                inputProps('leaders').onChange!([...((state.leaders?.value as any) || []), value])
                            }
                            className="mb-3"
                            error={inputProps('leaders').error}
                            clearAfterInsert
                        />
                        <div>
                            {state.leaders?.value &&
                                (state.leaders.value as any as number[]).map((id, i) => (
                                    <ProfilePreview
                                        key={i}
                                        appState={props.appState}
                                        id={id}
                                        className="link cross-on-hover"
                                        onClick={() => {
                                            inputProps('leaders').onChange!(
                                                (state.leaders?.value as any as number[]).filter((item) => item !== id),
                                            );
                                        }}
                                    />
                                ))}
                        </div>
                    </div>
                    <button {...buttonProps}>
                        {props.event
                            ? Translate.message('events.edit.save', 'Uložit')
                            : Translate.message('events.new.create', 'Vytvořit')}
                    </button>
                </>
            )}
        </Form>
    );
}
