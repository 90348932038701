import { IconCalendarEvent, IconWalk } from '@tabler/icons';
import moment from 'moment';
import * as React from 'react';
import { Event } from '../../../api/entities/Event';
import { AppState } from '../../../AppState';
import { incremental } from '../../../utils/incremental';
import { Translate } from '../../../utils/Translate';
import { Routes } from '../../routes/Routes';
import { EventTask, EventTaskState } from './EventTask';
import { taskGroups, tasks } from './tasks';

interface IEventTimelineProps {
    appState: AppState;
    event: Event;
}

export function EventTimeline(props: IEventTimelineProps) {
    const {
        appState,
        event: { from, to },
    } = props;

    const { min, max } = tasks.reduce(
        ({ min, max }, curr) => ({ min: Math.min(min, curr.due), max: Math.max(max, curr.due) }),
        { min: tasks[0].due, max: tasks[0].due },
    );

    const weeks = incremental(max + 1, min);

    const markerSize = 50;

    return (
        <div className="timeline-wrapper">
            <div
                className="event-timeline"
                ref={(object) => {
                    try {
                        if (!object) return;
                        const marker = object.querySelector('#today') as HTMLDivElement;

                        // Reset the style values
                        marker.style.display = 'block';
                        marker.style.left = 'unset';
                        marker.style.right = 'unset';

                        if (moment().isSameOrAfter(from) && moment().isSameOrBefore(to)) {
                            // Event is currently taking place
                            marker.style.display = 'none';
                            return;
                        }

                        const beforeEvent = moment().isBefore(from);
                        const weekEnd = beforeEvent ? moment().diff(from, 'weeks') : moment().diff(to, 'weeks') + 1;
                        const weekStart = weekEnd - 1;
                        const weekProgress =
                            -moment().diff(moment(from).add(weekStart, 'weeks')) /
                            moment(from).add(weekStart, 'weeks').diff(moment(from).add(weekEnd, 'weeks'));

                        // console.log(weekProgress);

                        if (weekStart < min) {
                            // Before timeline, render at start
                            marker.style.left = '0px';
                            return;
                        }

                        if (weekEnd > max) {
                            // After timeline, render at end
                            marker.style.right = '0px';
                            return;
                        }

                        // Inside timeline
                        const bbox = object.getBoundingClientRect();
                        const left = (
                            object.querySelector(`#g${weekStart}`)!.querySelector('.timeline-icons') as HTMLDivElement
                        ).getBoundingClientRect();
                        const right = (
                            object.querySelector(`#g${weekEnd}`)!.querySelector('.timeline-icons') as HTMLDivElement
                        ).getBoundingClientRect();

                        const shiftStart = left.x + left.width - bbox.x + markerSize / 2;
                        const shiftEnd = right.x - bbox.x - markerSize / 2;
                        const delta = shiftEnd - shiftStart;

                        marker.style.left = `${shiftStart + delta * weekProgress - markerSize / 2}px`;
                    } catch {
                        console.error('Timeline update failed');
                    }
                }}
            >
                <div id="today">
                    <IconWalk size={markerSize} stroke={1.3} />
                </div>
                {weeks.map((due, i) => {
                    const beforeEvent = due <= 0;
                    const taskDueDate = moment(beforeEvent ? from : to).add(due, 'weeks');
                    const isPast = moment(taskDueDate).isBefore();
                    const isDue = moment(taskDueDate).subtract(1, 'week').isBefore();

                    return due === 0 ? (
                        <div className="timeline-group" id={'g' + due} key={i}>
                            <div className="timeline-icons">
                                <EventTask
                                    appState={appState}
                                    icon={IconCalendarEvent}
                                    title={Translate.message('eventTasks.tasks.event', 'Akce')}
                                    state={
                                        isPast
                                            ? EventTaskState.DONE
                                            : isDue
                                            ? EventTaskState.DUE
                                            : EventTaskState.UPCOMING
                                    }
                                    big={true}
                                    link={Routes.eventsEdit(appState.memberId, props.event.id)}
                                />
                            </div>
                            <div className="timeline-desc">
                                {Translate.message('eventTasks.tasks.event', 'Akce')}
                                <div className="smaller">
                                    {moment(from).twix(to, true).format({
                                        monthFormat: 'MM.',
                                        showDayOfWeek: false,
                                        dayFormat: 'D.',
                                        implicitYear: false,
                                    })}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="timeline-group" key={i} id={'g' + due}>
                            <div className="timeline-icons">
                                {tasks
                                    .filter((task) => task.due === due)
                                    .map((task, j) => (
                                        <EventTask
                                            key={j}
                                            appState={appState}
                                            icon={task.icon}
                                            title={task.title}
                                            link={task.link && task.link(props.appState, props.event)}
                                            state={
                                                task.validator
                                                    ? task.validator(appState, props.event)
                                                        ? EventTaskState.DONE
                                                        : isPast
                                                        ? EventTaskState.MISSED
                                                        : isDue
                                                        ? EventTaskState.DUE
                                                        : EventTaskState.UPCOMING
                                                    : isPast
                                                    ? EventTaskState.DONE
                                                    : isDue
                                                    ? EventTaskState.DUE
                                                    : EventTaskState.UPCOMING
                                            }
                                        />
                                    ))}
                            </div>
                            <div className="timeline-desc">
                                {taskGroups[due]}{' '}
                                {taskGroups[due] && <div className="smaller">do {moment(taskDueDate).format('l')}</div>}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
