import { gql, useQuery } from '@apollo/client';
import { AppState } from '../../../../AppState';
import { Role } from '../../../../api/entities/Role';
import { Translate } from '../../../../utils/Translate';
import { Accordion, AccordionDefinition } from '../../../utils/Accordion';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { RoleEditor } from './RoleEditor';

interface IRoleProps {
    appState: AppState;
}

export function RolesPage(props: IRoleProps) {
    const { error, loading, data, refetch } = useQuery(
        gql`
            query RolesPageRoles($memberId: Int) {
                auth {
                    as(memberId: $memberId) {
                        roles {
                            items {
                                id
                                title
                                rights
                                color
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                memberId: props.appState.activeMember.id,
            },
        },
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    return (
        <div className="col-12">
            <h1>{Translate.message('roles.title', 'Role a oprávnění')}</h1>
            <h6>
                {Translate.message(
                    'roles.subtitle',
                    'Vytvořte role a nastavte jim oprávnění tak, aby každý mohl měnit jen to, co má.',
                )}
            </h6>

            <div className="mt-5">
                <Accordion
                    tabs={{
                        ...(data.auth.as.roles.items as Role[])
                            .sort((a, b) => (a.title === 'admin' ? -1 : b.title === 'admin' ? 1 : a.id - b.id))
                            .reduce(
                                (prev: AccordionDefinition, role, i, roles) => ({
                                    ...prev,
                                    [role.id]: {
                                        title: (
                                            <h3 className="mb-0">
                                                {role.title}
                                                <span
                                                    className="badge ms-2"
                                                    style={{ background: role.color || '#343a40' }}
                                                >
                                                    {role.title.slice(0, 3)}
                                                </span>
                                            </h3>
                                        ),
                                        content: (
                                            <RoleEditor
                                                appState={props.appState}
                                                role={role}
                                                isOnlyAdmin={
                                                    role.rights.includes('admin') &&
                                                    roles.filter((r) => r.rights.includes('admin')).length === 1
                                                }
                                                onSubmit={() => refetch()}
                                            />
                                        ),
                                    },
                                }),
                                {},
                            ),

                        new: {
                            title: (
                                <h3 className="mb-0" style={{ opacity: 0.5 }}>
                                    {Translate.message('roles.createNew', 'Nová role')}
                                </h3>
                            ),
                            content: (
                                <RoleEditor appState={props.appState} onSubmit={() => refetch()} isOnlyAdmin={false} />
                            ),
                        },
                    }}
                />
            </div>
        </div>
    );
}
