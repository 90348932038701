import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import packageInfo from '../package.json';
import { Api } from './api/Api';
import { Member } from './api/entities/Member';

export class AppState {
    public constructor() {
        makeObservable(this);

        (window as any).appState = this;
    }

    @observable
    public authorized: boolean = false;

    @observable
    public members: Member[] = [];
    @observable
    public memberId: number = 0;
    @observable
    public memberDetails: Member | null = null;
    @observable
    public rights: string[] | null = [];
    @observable
    public email: string = '';

    @observable
    public loading: boolean = false;

    @computed
    public get activeMember() {
        return this.memberDetails || this.members[this.memberId];
    }

    @action
    public async checkAuthorization() {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const result = await Api.client.query({
                query: Api.queries.memberList,
            });
            if (!result.errors || result.errors.length === 0) {
                this.members = result.data.auth.myMembers;
                this.authorized = true;
                this.email = result.data.auth.user.email;
            } else {
                this.members = [];
                this.authorized = false;
                this.email = '';
            }
        } catch {
            this.members = [];
            this.authorized = false;
            this.email = '';
        }
        runInAction(() => {
            this.loading = false;
        });
    }

    public hasRight(right: string): boolean {
        return !!this.rights && (this.rights.includes(right) || this.rights.includes('admin'));
    }

    public get appVersion() {
        return packageInfo.version;
    }

    public static LS_PREFIX = 'bonfire.';
}
