import { Member } from '../api/entities/Member';
import { Translate } from './Translate';

export function memberList(members: Member[]): string {
    if (members.length === 0) {
        return Translate.message('memberList.none', 'nikdo');
    }

    if (members.length === 1) {
        return members[0].nickname;
    }

    if (members.length <= 5) {
        return Translate.message('memberList.some', '{{list}} a {{last}}', {
            list: members
                .slice(0, members.length - 1)
                .map((member) => member.nickname)
                .join(', '),
            last: members[members.length - 1].nickname,
        });
    }

    return Translate.message(
        'memberList.lot',
        '{{list}} a {{num?plural|další|další|dalších}} {{num}} {{num?plural|človek|lidi|lidí}}',
        {
            list: members
                .slice(0, 4)
                .map((member) => member.nickname)
                .join(', '),
            num: members.length - 4,
        },
    );
}
