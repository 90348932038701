import * as React from 'react';
import { classNames } from '../../utils/classNames';

interface IListEndTextProps {
    left?: true;
}

export function ListEndText(props: React.PropsWithChildren<IListEndTextProps>) {
    return <p className={classNames('list-more', props.left && 'text-start mx-0 mt-3')}>{props.children}</p>;
}
