import { IconEye, IconEyeOff, IconUserCheck, IconUserX } from '@tabler/icons';
import moment from 'moment';
import { AppState } from '../../../../../AppState';
import { Event } from '../../../../../api/entities/Event';
import { Translate } from '../../../../../utils/Translate';
import { Accordion } from '../../../../utils/Accordion';
import { ReportEditor } from './ReportEditor';

export interface IReportsEditorProps {
    appState: AppState;
    event: Event;
    onSave: () => void;
}

export function ReportsEditor(props: IReportsEditorProps) {
    const { event, onSave } = props;

    const reports = event.reports.items
        .sort((a, b) => moment(a.date).diff(b.date) || a.id - b.id)
        .reduce(
            (prev, curr) => ({
                ...prev,
                [curr.id]: {
                    title: (
                        <>
                            <div className="me-2">{curr.ready ? <IconUserCheck /> : <IconUserX />}</div>
                            <div className="me-2">{curr.public ? <IconEye /> : <IconEyeOff />}</div>
                            {Translate.message('reportsEditor.item.title', 'Kronika ') +
                                (curr.date ? moment(curr.date).format('dddd DD.MM.YYYY') : '') +
                                Translate.message('reportsEditor.item.from', ' od ') +
                                curr.authors.map((a) => `${a.firstname} "${a.nickname}" ${a.lastname}`).join(', ')}
                        </>
                    ),
                    content: (
                        <>
                            <ReportEditor
                                appState={props.appState}
                                event={props.event}
                                onSave={onSave}
                                report={curr}
                                admin
                            />
                        </>
                    ),
                },
            }),
            {},
        );

    return (
        <>
            <Accordion
                tabs={{
                    ...reports,
                    new: {
                        title: Translate.message('reportsEditor.new', 'Přidat novou kroniku'),
                        content: (
                            <>
                                <ReportEditor appState={props.appState} event={props.event} onSave={onSave} admin />
                            </>
                        ),
                    },
                }}
            />
        </>
    );
}
