import moment from 'moment';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Member } from '../../../../api/entities/Member';
import { Translate } from '../../../../utils/Translate';
import { formatPhone } from '../../../../utils/formatPhone';
import { paragraphs } from '../../../../utils/paragraphs';
import { Routes } from '../../Routes';

export interface ICounselorRendererProps {
    appState: AppState;
    members: Member[];
    compact?: boolean;
}

export function CounselorRenderer(props: ICounselorRendererProps) {
    const eventsApprox = (events: number, gender: string) => {
        const prefix = Translate.message(
            'members.counselors.events.prefix',
            ', {{gender?gender|zorganizoval nám|zorganizovala nám|na svědomí má}} ',
            { gender },
        );

        if (events === 0) return '';
        if (events === 1) return prefix + Translate.message('members.counselors.events.one', 'svoji první akci');
        if (events < 5) return prefix + Translate.message('members.counselors.events.few', 'už pár akcí');
        if (events < 10)
            return prefix + Translate.message('members.counselors.events.exact', 'už {{num}} akcí', { num: events });
        return (
            prefix +
            Translate.message('members.counselors.events.over', 'už pěknou řádku akcí (přes {{num}})', {
                num: Math.floor(events / 5) * 5,
            })
        );
    };

    return (
        <div className="col-12 counselors">
            <div className="counselor-container">
                {[...(props.members as Member[])]
                    .sort((a, b) => moment(a.birthdate).diff(b.birthdate))
                    .map((member, i) => (
                        <div className="d-flex flex-row grid-item col-12" key={i}>
                            <Link
                                className="link-hidden image flex-shrink-0"
                                to={Routes.membersDetail(props.appState.memberId, member.id)}
                                style={{ maxWidth: props.compact ? 150 : 350 }}
                            >
                                <div style={{ backgroundImage: `url('${member.image}')` }} role="img" />
                            </Link>
                            <div className="block flex-grow-1">
                                <h3>
                                    {member.firstname} "{member.nickname}" {member.lastname}
                                </h3>
                                <p>
                                    <strong>
                                        {Translate.message('members.counselors.age', '{{age}} let', {
                                            age: moment().diff(member.birthdate, 'years'),
                                        })}
                                        , {member.organizationRoles.map((role, i) => role.title).join(', ')}
                                        {eventsApprox(member.leadEvents.total, member.gender)}
                                    </strong>
                                </p>
                                {!props.compact && paragraphs(member.motd, true)}
                                <div className="info">
                                    <div className="title">Kontakt:</div>
                                    <div className="content">{member.email}</div>
                                    {member.phone && <div className="content">{formatPhone(member.phone)}</div>}
                                </div>
                                {!props.compact &&
                                    member.counselorInfo.map((info, i) => (
                                        <div className="info" key={i}>
                                            <div className="title">{info.title}:</div>
                                            <div className="content">{info.content}</div>
                                        </div>
                                    ))}
                            </div>
                            <div className="flex-shrink-0" style={{ width: 20 }}></div>
                        </div>
                    ))}
            </div>
        </div>
    );
}
