import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from '../routes/Routes';

interface IRedirectStoreBacklinkProps {
    prefix?: string;
    to: string;
}

export function RedirectStoreBacklink(props: IRedirectStoreBacklinkProps) {
    return (
        <>
            <Redirect to={storeBacklink(props.to, props.prefix)} />
        </>
    );
}

export function storeBacklink(to: string, prefix?: string) {
    const query = [...new URLSearchParams(window.location.search)];
    let location = window.location.pathname;
    if (query.length > 0) {
        location += '?';
        location += query.map(([key, value]) => `${key}=${value}`).join('&');
    }
    const urlReminder = prefix ? location.replace(prefix, '') : location;

    if (urlReminder === '/') return to;

    return Routes.authBacklink(to, encodeURIComponent(urlReminder));
}
