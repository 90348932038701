import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { forTime } from 'waitasecond';
import { Api } from '../../../api/Api';
import { Translate } from '../../../utils/Translate';
import { Await } from '../../utils/Await';
import { ErrorDisplay } from '../../utils/ErrorDisplay';
import { FormInput } from '../../utils/inputs/FormInput';
import { PasswordFeatures, validatePassword } from '../../utils/PasswordFeatures';
import { Routes } from '../Routes';

interface IShadowRegisterProps {}

export function ShadowRegister(props: IShadowRegisterProps) {
    const token = new URLSearchParams(window.location.search).get('token');
    const [password, setPassword] = React.useState('');
    const [passwordValidation, setPasswordValidation] = React.useState('');
    const [confirm, { loading, error, data }] = useMutation(Api.mutations.shadowRegister);

    return (
        <div className="wrapper center-screen spot-rh">
            <main>
                <form>
                    {!token ? (
                        <ErrorDisplay error={Translate.message('error.tokenInvalid')} />
                    ) : error ? (
                        <ErrorDisplay error={error} />
                    ) : (
                        <>
                            <h1 className="h2 mb-3 fw-normal">
                                {Translate.message('shadowRegister.title', 'Dokončení registrace')}
                            </h1>
                            <p>
                                {Translate.message(
                                    'shadowRegister.subtitle',
                                    'Člen Duhy si tě přidal, jako správce svého účtu. Ty ale u nás ještě nemáš účet. Tady můžeš dokončit registraci a aktivovat si svůj členský účet',
                                )}
                            </p>
                            <FormInput
                                type="password"
                                className="mb-2"
                                label={Translate.message('register.password', 'Heslo')}
                                value={password}
                                onChange={(value) => setPassword(value)}
                                error={
                                    password.length === 0 || validatePassword(password).length === 0
                                        ? undefined
                                        : Translate.message('error.passwordTooWeak')
                                }
                                disabled={loading || (data && !error)}
                            />
                            <PasswordFeatures password={password} />
                            <FormInput
                                type="password"
                                className="mb-3 mt-3"
                                label={Translate.message('register.passwordValidation', 'Heslo znovu')}
                                value={passwordValidation}
                                onChange={(value) => setPasswordValidation(value)}
                                error={
                                    passwordValidation.length === 0 || password === passwordValidation
                                        ? undefined
                                        : Translate.message('error.passwordsDontMatch')
                                }
                                disabled={loading || (data && !error)}
                            />
                            {(error || (!loading && !data)) && (
                                <button
                                    className="mt-4 w-100 btn btn-lg btn-primary text-white"
                                    type="button"
                                    onClick={() => {
                                        confirm({
                                            variables: {
                                                token,
                                                password,
                                                passwordValidation,
                                            },
                                        });
                                    }}
                                    disabled={password !== passwordValidation || validatePassword(password).length > 0}
                                >
                                    {Translate.message('shadowRegister.send', 'Dokončit registraci')}
                                </button>
                            )}
                            {loading && (
                                <button className="mt-4 w-100 btn btn-lg btn-primary text-white" type="button" disabled>
                                    <div className="spinner-border spinner-border-sm text-light" role="status">
                                        <span className="visually-hidden">
                                            {Translate.message('shadowRegister.sending', 'Odesílání...')}
                                        </span>
                                    </div>
                                </button>
                            )}
                            {data && !error && (
                                <>
                                    <button
                                        disabled
                                        className="mt-4 w-100 btn btn-lg btn-success text-white"
                                        type="button"
                                    >
                                        {Translate.message('shadowRegister.sent', 'Dokončeno')}
                                    </button>
                                    <Await for={forTime(1000)}>
                                        <Redirect to={Routes.login()} />
                                    </Await>
                                </>
                            )}
                        </>
                    )}
                </form>
            </main>
        </div>
    );
}
