import { ApolloError } from '@apollo/client';
import { IconAlertTriangle } from '@tabler/icons';
import * as React from 'react';
import { gqlErrorToString } from '../../utils/gqlErrorToString';

interface IErrorDisplayProps {
    error: ApolloError | string;
}

export function ErrorDisplay(props: IErrorDisplayProps) {
    let error: string | undefined = undefined;
    if (typeof props.error === 'string') {
        error = props.error;
    } else {
        error = gqlErrorToString(props.error as ApolloError);
    }
    if (!error) return <></>;

    console.error(props.error);
    return (
        <div className="error">
            <IconAlertTriangle size={100} strokeWidth={1.5} />
            <div className="desc">{error}</div>
        </div>
    );
}
