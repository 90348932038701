import { TablerIconProps } from '@tabler/icons';
import { Link, useLocation } from 'react-router-dom';
import { classNames } from '../../utils/classNames';

interface ISidebarLinkProps {
    route: string;
    title: string;
    children?: JSX.Element | false | undefined | null | (JSX.Element | false | undefined | null)[];
    icon?: React.FunctionComponent<TablerIconProps>;
    fuzzy?: true;
    hasIndex?: number;
    badge?: string | number;
    onClick?: () => void;
}

export function SidebarLink(props: ISidebarLinkProps) {
    const location = useLocation();

    let route = props.route;
    let split = route.split('/');
    if (props.hasIndex) {
        for (let i = 0; i < props.hasIndex; i++) split.pop();
    }
    route = split.join('/');

    const isOpen = location.pathname.startsWith(route) && props.children;

    const isActive =
        (!props.fuzzy && location.pathname === route) || (props.fuzzy && location.pathname.startsWith(route));

    return (
        <>
            <li className="nav-item">
                <Link
                    to={props.route}
                    className={classNames('nav-link', isActive && 'active')}
                    onClick={() => props.onClick && props.onClick()}
                >
                    {props.icon && <props.icon size={20} strokeWidth={1.5} className="me-2 mb-1" />}
                    <span>
                        {props.title}
                        {props.badge && !isOpen ? <span className="ms-2 badge bg-danger">{props.badge}</span> : <></>}
                    </span>
                </Link>
            </li>
            {isOpen && <ul className="nav nav-pills submenu flex-column">{props.children}</ul>}
        </>
    );
}
