import { IconBallon, IconCheck, IconClock, IconListCheck, IconPencil } from '@tabler/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { TWIX_FORMAT_DATE_ONLY } from '../../../../utils/twixFormat';
import { columnDefinition } from '../../../utils/dataDisplayComponents/pagedView/Page';
import { Routes } from '../../Routes';
import { meetingFrequencyToText } from './meetingFrequencyToText';

export const meetingColumns: (appState: AppState) => columnDefinition[] = (appState) => [
    {
        width: 0,
        getter: (row) => (row.current ? <IconCheck /> : <IconClock />),
    },
    // @translation meetings.title: "Název"
    { title: 'meetings.title', width: 5, getter: (row) => row['title'] as string, sortBy: 'title' },
    // @translation meetings.date: "Datum"
    {
        title: 'meetings.date',
        width: 4,
        getter: (row) => meetingFrequencyToText(row as any),
        sortBy: 'firstFrom',
    },
    // @translation meetings.season: "Rok"
    {
        title: 'meetings.season',
        width: 4,
        getter: (row) =>
            moment(row.seasonFrom as any)
                .twix(row.seasonTo as any)
                .format(TWIX_FORMAT_DATE_ONLY),
        sortBy: 'seasonFrom',
    },
    // @translation meetings.members: "Počet členů"
    {
        title: 'meetings.members',
        width: 2,
        getter: (row) => (row.members as any).length,
    },
    // @translation meetings.schedules: "Plány"
    {
        title: 'meetings.schedules',
        width: 2,
        getter: (row) =>
            `${(row.schedules as any as any[]).filter((schedule) => schedule.happening === 'yes').length}/${
                (row.schedules as any as any[]).length
            }`,
    },
    {
        width: 0,
        getter: (row) => (
            <div className="actions">
                {row.current && (
                    <Link to={Routes.meetingsPlanner(appState.memberId)}>
                        <IconListCheck />
                    </Link>
                )}
                <Link to={Routes.meetingsMyDetail(appState.memberId, row.id as number)}>
                    <IconBallon />
                </Link>
                <Link to={Routes.meetingsEdit(appState.memberId, row.id as number)}>
                    <IconPencil />
                </Link>
            </div>
        ),
    },
];
