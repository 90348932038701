import { ApolloClient, from, gql, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

export class Api {
    private static internalClient: ApolloClient<NormalizedCacheObject>;
    private static internalApiUrl: string;

    public static setupApi(apiUrl: string) {
        const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.forEach(({ message, locations, path }) =>
                    console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`),
                );

            if (networkError) console.log(`[Network error]: ${networkError}`);
        });

        this.internalClient = new ApolloClient({
            uri: apiUrl,
            cache: new InMemoryCache(),
            credentials: 'include',
            link: from([
                errorLink,
                createUploadLink({
                    uri: apiUrl,
                    credentials: 'include',
                }),
            ]),
            defaultOptions: {
                mutate: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            },
        });
        this.internalApiUrl = apiUrl;
    }

    public static get client() {
        return this.internalClient;
    }

    public static get apiUrl() {
        return this.internalApiUrl;
    }

    /* Requests */

    private static readonly MEMBER_DETAILS = `
    id
    firstname
    lastname
    nickname
    gender
    image
    birthdate
    isStudent
    school
    schoolClass
    isEmployee
    job
    employer
    email
    phone
    address
    otherInfo
    motd
    hasPublicInfo
    organizationRoles {
        id
        title
        icon
    }
    roles {
        id
        title
        color
        rights
    }
    birchLeaf
    validMembership
    representatives {
        id
        firstname
        lastname
        email
        phone
    }
    certificates {
        id
        validSince
        validUntil
        certificate {
            id
            title
        }
    }
    leadEvents {
        total
    }
    memberships {
        id
        start
        end
    }
    counselorInfo {
        id
        title
        content
    }
    meetings {
        items {
            id
            current
            title
            firstFrom
            firstTo
            frequency
            frequencyEvery
        }
    }
`;

    public static readonly queries = {
        memberList: gql`
            query ListMembers {
                auth {
                    myMembers {
                        id
                        firstname
                        lastname
                        gender
                        nickname
                        image
                        organizationRoles {
                            id
                            title
                            icon
                        }
                        roles {
                            rights
                        }
                        validMembership
                    }
                    user {
                        email
                    }
                }
            }
        `,
        member: {
            managers: (memberId: number) => gql`
                {
                    auth {
                        user {
                            email
                        }
                        as(memberId: ${memberId}) {
                            memberById(id: ${memberId}) {
                                managingUsers {
                                    user {
                                        email
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            detail: (asId: number) => gql`
                query MemberDetail($id: Int) {
                    auth {
                        as(memberId: ${asId}) {
                            memberById(id: $id) {
                                ${this.MEMBER_DETAILS}
                            }
                        }
                    }
                }
            `,
            basic: gql`
                query MemberBasic($asId: Int!, $id: Int!) {
                    auth {
                        as(memberId: $asId) {
                            memberById(id: $id) {
                                id
                                firstname
                                lastname
                                nickname
                                image
                            }
                        }
                    }
                }
            `,
            publicDetail: (asId: number) => gql`
                query MemberPublicDetail($id: Int) {
                    auth {
                        as(memberId: ${asId}) {
                            memberById(id: $id) {
                                id
                                firstname
                                lastname
                                nickname
                                gender
                                image
                                birchLeaf
                                motd
                                hasPublicInfo
                                certificates {
                                    certificate {
                                        title
                                    }
                                    validSince
                                    validUntil
                                }
                                validMembership
                                leadEvents{
                                    total
                                }
                                organizationRoles {
                                    id
                                    title
                                    icon
                                }
                                counselorInfo {
                                    id
                                    title
                                    content
                                }
                            }
                        }
                    }
                }
            `,
            counselorDetail: (asId: number) => gql`
                query CounselorDetail($id: Int) {
                    auth {
                        as(memberId: ${asId}) {
                            memberById(id: $id) {
                                birthdate
                                email
                                phone
                                counselorInfo
                            }
                        }
                    }
                }
            `,
            list: (asId: number) => gql`
                query MemberList($pageInput: PageInput, $filter: MemberFilter, $sortOrder: [SortOrder]) {
                    auth {
                        as(memberId: ${asId}) {
                            members(pageInput: $pageInput, filter: $filter, sortOrder: $sortOrder) {
                                items {
                                    id
                                    firstname
                                    lastname
                                    gender
                                    nickname
                                    email
                                    birthdate
                                    validMembership
                                    organizationRoles {
                                        id
                                        title
                                        icon
                                    }
                                    roles {
                                        id
                                        title
                                        color
                                    }
                                }
                                total
                            }
                        }
                    }
                }
            `,
            requests: (asId: number) => gql`
                query MemberRequests($pageInput: PageInput, $filter: MembershipRequestFilter, $sortOrder: [SortOrder]) {
                    auth {
                        as(memberId: ${asId}) {
                            membershipRequests(pageInput: $pageInput, filter: $filter, sortOrder: $sortOrder) {
                                items {
                                    id
                                    status
                                    member {
                                        id
                                        firstname
                                        nickname
                                        lastname
                                        gender
                                        birthdate
                                        email
                                    }
                                }
                                total
                            }
                        }
                    }
                }
            `,
            counselors: (asId: number) => gql`
                query Counselors {
                    auth {
                        as(memberId: ${asId}) {
                            members(filter: {
                                hasPublicInfo: true
                                validMembership: true
                            }) {
                                items {
                                    id
                                    firstname
                                    lastname
                                    nickname
                                    gender
                                    image
                                    birchLeaf
                                    birthdate
                                    email
                                    phone
                                    motd
                                    organizationRoles {
                                        id
                                        title
                                        icon
                                    }
                                    leadEvents {
                                        total
                                    }
                                    counselorInfo {
                                        title
                                        content
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            search: gql`
                query MemberSearch($asId: Int!, $search: String!, $id: Int!, $doSearch: Boolean!, $doResult: Boolean!) {
                    auth {
                        as(memberId: $asId) {
                            members(
                                pageInput: { skip: 0, take: 10 }
                                sortOrder: [{ column: "birthdate", direction: ASC }]
                                filter: { names: $search }
                            ) {
                                items @include(if: $doSearch) {
                                    id
                                    firstname
                                    nickname
                                    lastname
                                }
                                total
                            }
                            memberById(id: $id) @include(if: $doResult) {
                                id
                                firstname
                                nickname
                                lastname
                            }
                        }
                    }
                }
            `,
        },
        event: {
            list: (asId: number) => gql`
                query EventList($pageInput: PageInput, $filter: EventFilter, $sortOrder: [SortOrder]) {
                    auth {
                        as(memberId: ${asId}) {
                            events(pageInput: $pageInput, filter: $filter, sortOrder: $sortOrder) {
                                items {
                                    id
                                    from
                                    to
                                    title
                                    allDay
                                    descriptionBefore
                                    imageBefore
                                    registrationStart
                                    registrationEnd
                                    maxAttendees
                                    attendees {
                                        answer
                                        member {
                                            nickname
                                            id
                                        }
                                    }
                                    leaders {
                                        id
                                    }
                                    articles {
                                        items {
                                            id
                                        }
                                    }
                                    linkPhoto
                                }
                                total
                            }
                        }
                    }
                }
            `,
            detailedList: (asId: number) => gql`
                query EventListDetailed($pageInput: PageInput, $filter: EventFilter, $sortOrder: [SortOrder]) {
                    auth {
                        as(memberId: ${asId}) {
                            events(pageInput: $pageInput, filter: $filter, sortOrder: $sortOrder) {
                                items {
                                    id
                                    from
                                    to
                                    title
                                    allDay
                                    attendees {
                                        id
                                        answer
                                    }
                                    attendeesExternal {
                                        id
                                        answer
                                    }
                                    leaders {
                                        id
                                        nickname
                                    }
                                    articles {
                                        items {
                                            id
                                        }
                                    }
                                }
                                total
                            }
                        }
                    }
                }
            `,
            detail: (asId: number, showMemberDetails?: boolean) => gql`
                query Event($id: Int) {
                    auth {
                        as(memberId: ${asId}) {
                            eventById(id: $id) {
                                id
                                from
                                to
                                title
                                allDay
                                descriptionBefore
                                descriptionAfter
                                imageBefore
                                linkPhoto
                                linkVideo
                                registrationStart
                                registrationEnd
                                maxAttendees
                                allowExternal
                                attendees {
                                    answer
                                    member {
                                        id
                                        firstname
                                        lastname
                                        nickname
                                        image
                                        hasPublicInfo
                                        ${
                                            !showMemberDetails
                                                ? ''
                                                : `
                                                    birthdate
                                                    roles {
                                                        color
                                                        title
                                                    }
                                                    organizationRoles {
                                                        title
                                                        icon
                                                    }
                                                `
                                        }
                                    }
                                }
                                attendeesExternal {
                                    id
                                    answer
                                    from {
                                        id
                                        firstname
                                        lastname
                                        nickname
                                        ${showMemberDetails ? 'birthdate' : ''}
                                    }
                                    firstname
                                    lastname
                                    nickname
                                    ${showMemberDetails ? 'birthdate' : ''}
                                }
                                leaders {
                                    id
                                    firstname
                                    lastname
                                    nickname
                                    ${showMemberDetails ? 'birthdate' : ''}
                                }
                                articles {
                                        items {
                                            id
                                        }
                                    }
                                reports {
                                    items {
                                        id
                                        public
                                        ready
                                        date
                                        title
                                        content
                                        authors {
                                            id
                                            firstname
                                            lastname
                                            nickname
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            search: gql`
                query EventSearch($asId: Int!, $search: String!, $id: Int!, $doSearch: Boolean!, $doResult: Boolean!) {
                    auth {
                        as(memberId: $asId) {
                            events(
                                pageInput: { skip: 0, take: 10 }
                                sortOrder: [{ column: "from", direction: DESC }]
                                filter: { title: $search }
                            ) {
                                items @include(if: $doSearch) {
                                    id
                                    title
                                    from
                                    to
                                }
                                total
                            }
                            eventById(id: $id) @include(if: $doResult) {
                                id
                                title
                                from
                                to
                            }
                        }
                    }
                }
            `,
        },
        report: {
            my: (asId: number) => gql`
                query MyReport {
                    auth {
                        as(memberId: ${asId}) {
                            me {
                                reports {
                                    items {
                                        id
                                        date
                                        title
                                        content
                                        authors {
                                            id
                                            firstname
                                            lastname
                                            nickname
                                        }
                                        event {
                                            id
                                            title
                                        }
                                        public
                                        ready
                                    }
                                }
                            }
                        }
                    }
                }
            `,
        },
        article: {
            list: (asId: number) => gql`
                query ArticleList($pageInput: PageInput, $filter: ArticleFilter, $sortOrder: [SortOrder]) {
                    auth {
                        as(memberId: ${asId}) {
                            articles(pageInput: $pageInput, filter: $filter, sortOrder: $sortOrder) {
                                total
                                items {
                                    id
                                    title
                                    content
                                    author {
                                        nickname
                                    }
                                    relatedEvent {
                                        id
                                        from
                                        to
                                        title
                                        allDay
                                        descriptionBefore
                                        imageBefore
                                        registrationStart
                                        registrationEnd
                                        maxAttendees
                                        attendees {
                                            answer
                                            member {
                                                nickname
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            detailedList: (asId: number) => gql`
                query ArticleListDetailed($pageInput: PageInput, $filter: ArticleFilter, $sortOrder: [SortOrder]) {
                    auth {
                        as(memberId: ${asId}) {
                            articles(pageInput: $pageInput, filter: $filter, sortOrder: $sortOrder) {
                                items {
                                    id
                                    title
                                    author {
                                        nickname
                                    }
                                    relatedEvent {
                                        id
                                        title
                                    }
                                    visibleFrom
                                    visibleTo
                                }
                                total
                            }
                        }
                    }
                }
            `,
            detail: (asId: number) => gql`
                query ArticleDetail($id: Int!) {
                    auth {
                        as(memberId: ${asId}) {
                            articleById(id: $id) {
                                id
                                title
                                author {
                                    nickname
                                }
                                relatedEvent {
                                    id
                                    title
                                }
                                content
                                visibleFrom
                                visibleTo
                            }
                        }
                    }
                }
            `,
        },
        authAsDetails: (asId: number) => gql`
            query MemberDetail {
                auth {
                    as(memberId: ${asId}) {
                        memberById(id: ${asId}) {
                            ${this.MEMBER_DETAILS}
                        }
                        rights
                    }
                }
            }
        `,
        organizationRoles: (asId: number) => gql`
            {
                auth {
                    as(memberId: ${asId}) {
                        organizationRoles {
                            items {
                                id
                                title
                                icon
                            }
                        }
                    }
                }
            }
        `,
        roles: (asId: number) => gql`
            {
                auth {
                    as(memberId: ${asId}) {
                        roles {
                            items {
                                id
                                title
                                color
                                rights
                            }
                        }
                    }
                }
            }
        `,
    };

    public static readonly mutations = {
        member: {
            create: gql`
                mutation CreateMember($memberId: Int!, $value: MemberInput!) {
                    auth {
                        as(memberId: $memberId) {
                            createMember(value: $value) {
                                id
                            }
                        }
                    }
                }
            `,
            createAnonymous: gql`
                mutation CreateMemberAnonymous($value: MemberInput!) {
                    auth {
                        createMember(value: $value) {
                            id
                        }
                    }
                }
            `,
            representative: {
                // TODO: Solve better
                add: (asId: number, targetId: number) => gql`
                    mutation addValue {
                        auth {
                            as(memberId: ${asId}) {
                                memberAddRepresentative(memberId: ${targetId}, value: {
                                    firstname: ".",
                                    lastname: ".",
                                    email: "email@mail.cz",
                                    phone: "123456789"
                                }) {
                                    ${this.MEMBER_DETAILS}
                                }
                            }
                        }
                    }
                `,
                addWithData: gql`
                    mutation addValueWithData($memberId: Int!, $targetId: Int!, $value: RepresentativeInput!) {
                        auth {
                            as(memberId: $memberId) {
                                memberAddRepresentative(memberId: $targetId, value: $value) {
                                    id
                                }
                            }
                        }
                    }
                `,
                delete: (asId: number, targetId: number) => gql`
                    mutation removeValue {
                        auth {
                            as(memberId: ${asId}) {
                                memberRemoveRepresentative(representativeId: ${targetId}) {
                                    ${this.MEMBER_DETAILS}
                                }
                            }
                        }
                    }
                `,
                update: (asId: number, targetId: number, key: string, type: string) => gql`
                    mutation updateValue($value: ${type}) {
                        auth {
                            as(memberId: ${asId}) {
                                memberModifyRepresentative(representativeId: ${targetId}, value: {
                                    ${key}: $value
                                }) {
                                    ${this.MEMBER_DETAILS}
                                }
                            }
                        }
                    }
                `,
            },
            managers: {
                add: gql`
                    mutation addManager($memberId: Int!, $email: String!) {
                        auth {
                            as(memberId: $memberId) {
                                memberAddManager(memberId: $memberId, email: $email) {
                                    id
                                }
                            }
                        }
                    }
                `,
                remove: gql`
                    mutation removeManager($memberId: Int!, $email: String!) {
                        auth {
                            as(memberId: $memberId) {
                                memberRemoveManager(memberId: $memberId, email: $email) {
                                    id
                                }
                            }
                        }
                    }
                `,
            },
            update: (asId: number, targetId: number, key: string, type: string) => gql`
                mutation updateValue($value: ${type}) {
                    auth {
                        as(memberId: ${asId}) {
                            updateMember(memberId: ${targetId}, value: {
                                ${key}: $value
                            }) {
                                ${this.MEMBER_DETAILS}
                            }
                        }
                    }
                }
            `,
            modify: gql`
                mutation modifyValue($asId: Int!, $memberId: Int!, $value: MemberInput!) {
                    auth {
                        as(memberId: $asId) {
                            updateMember(memberId: $memberId, value: $value) {
                                id
                            }
                        }
                    }
                }
            `,
            createMembershipRequest: gql`
                mutation createMembershipRequest($memberId: Int!) {
                    auth {
                        as(memberId: $memberId) {
                            createMembershipRequest(memberId: $memberId) {
                                id
                                status
                            }
                        }
                    }
                }
            `,
            acceptMembershipRequest: gql`
                mutation acceptMembershipRequest($memberId: Int!, $requestId: Int!) {
                    auth {
                        as(memberId: $memberId) {
                            acceptMembershipRequest(requestId: $requestId) {
                                id
                                status
                            }
                        }
                    }
                }
            `,
            declineMembershipRequest: gql`
                mutation declineMembershipRequest($memberId: Int!, $requestId: Int!) {
                    auth {
                        as(memberId: $memberId) {
                            declineMembershipRequest(requestId: $requestId) {
                                id
                                status
                            }
                        }
                    }
                }
            `,
            membership: {
                add: gql`
                    mutation memberAddMembership($asId: Int!, $memberId: Int!, $value: MembershipInput!) {
                        auth {
                            as(memberId: $asId) {
                                memberAddMembership(memberId: $memberId, value: $value) {
                                    id
                                }
                            }
                        }
                    }
                `,
                remove: gql`
                    mutation memberRemoveMembership($asId: Int!, $membershipId: Int!) {
                        auth {
                            as(memberId: $asId) {
                                memberRemoveMembership(membershipId: $membershipId) {
                                    id
                                }
                            }
                        }
                    }
                `,
                update: gql`
                    mutation memberModifyMembership($asId: Int!, $membershipId: Int!, $value: MembershipInput!) {
                        auth {
                            as(memberId: $asId) {
                                memberModifyMembership(membershipId: $membershipId, value: $value) {
                                    id
                                }
                            }
                        }
                    }
                `,
            },
            counselorInfo: {
                add: gql`
                    mutation memberAddCounselorInfo($asId: Int!, $memberId: Int!, $value: CounselorInfoInput!) {
                        auth {
                            as(memberId: $asId) {
                                memberAddCounselorInfo(memberId: $memberId, value: $value) {
                                    id
                                }
                            }
                        }
                    }
                `,
                remove: gql`
                    mutation memberRemoveCounselorInfo($asId: Int!, $infoId: Int!) {
                        auth {
                            as(memberId: $asId) {
                                memberRemoveCounselorInfo(infoId: $infoId) {
                                    id
                                }
                            }
                        }
                    }
                `,
            },
        },
        event: {
            create: gql`
                mutation CreateEvent($memberId: Int!, $value: EventInput!) {
                    auth {
                        as(memberId: $memberId) {
                            createEvent(value: $value) {
                                id
                            }
                        }
                    }
                }
            `,
            delete: gql`
                mutation DeleteEvent($memberId: Int!, $eventId: Int!) {
                    auth {
                        as(memberId: $memberId) {
                            deleteEvent(eventId: $eventId)
                        }
                    }
                }
            `,
            attend: gql`
                mutation Attend($memberId: Int!, $eventId: Int!) {
                    auth {
                        as(memberId: $memberId) {
                            attendEvent(eventId: $eventId, memberId: $memberId, answer: yes)
                        }
                    }
                }
            `,
            update: (asId: number, targetId: number, key: string, type: string) => gql`
                mutation updateValue($value: ${type}) {
                    auth {
                        as(memberId: ${asId}) {
                            updateEvent(eventId: ${targetId}, value: {
                                ${key}: $value
                            }) {
                                id
                            }
                        }
                    }
                }
            `,
            modify: gql`
                mutation modifyEvent($memberId: Int!, $eventId: Int!, $value: EventInput!) {
                    auth {
                        as(memberId: $memberId) {
                            updateEvent(eventId: $eventId, value: $value) {
                                id
                            }
                        }
                    }
                }
            `,
        },
        report: {
            create: gql`
                mutation CreateReport($memberId: Int!, $value: ReportInput!) {
                    auth {
                        as(memberId: $memberId) {
                            createReport(value: $value) {
                                id
                            }
                        }
                    }
                }
            `,
            modify: gql`
                mutation modifyReport($memberId: Int!, $reportId: Int!, $value: ReportInput!) {
                    auth {
                        as(memberId: $memberId) {
                            updateReport(reportId: $reportId, value: $value) {
                                id
                            }
                        }
                    }
                }
            `,
        },
        article: {
            create: gql`
                mutation CreateArticle($memberId: Int!, $value: ArticleInput!) {
                    auth {
                        as(memberId: $memberId) {
                            createArticle(value: $value) {
                                id
                            }
                        }
                    }
                }
            `,
            update: gql`
                mutation UpdateArticle($memberId: Int!, $articleId: Int!, $value: ArticleInput!) {
                    auth {
                        as(memberId: $memberId) {
                            updateArticle(articleId: $articleId, value: $value) {
                                id
                            }
                        }
                    }
                }
            `,
            delete: gql`
                mutation DeleteArticle($memberId: Int!, $articleId: Int!) {
                    auth {
                        as(memberId: $memberId) {
                            deleteArticle(articleId: $articleId)
                        }
                    }
                }
            `,
        },
        organizationRoles: {
            add: gql`
                mutation memberAddOrganizationRole($asId: Int!, $memberId: Int!, $roleId: Int!) {
                    auth {
                        as(memberId: $asId) {
                            memberAddOrganizationRole(memberId: $memberId, roleId: $roleId) {
                                id
                            }
                        }
                    }
                }
            `,
            remove: gql`
                mutation memberRemoveOrganizationRole($asId: Int!, $memberId: Int!, $roleId: Int!) {
                    auth {
                        as(memberId: $asId) {
                            memberRemoveOrganizationRole(memberId: $memberId, roleId: $roleId) {
                                id
                            }
                        }
                    }
                }
            `,
        },
        roles: {
            add: gql`
                mutation memberAddRole($asId: Int!, $memberId: Int!, $roleId: Int!) {
                    auth {
                        as(memberId: $asId) {
                            memberAddRole(memberId: $memberId, roleId: $roleId) {
                                id
                            }
                        }
                    }
                }
            `,
            remove: gql`
                mutation memberRemoveRole($asId: Int!, $memberId: Int!, $roleId: Int!) {
                    auth {
                        as(memberId: $asId) {
                            memberRemoveRole(memberId: $memberId, roleId: $roleId) {
                                id
                            }
                        }
                    }
                }
            `,
        },
        uploadFile: gql`
            mutation UploadFile($memberId: Int!, $file: Upload!) {
                auth {
                    as(memberId: $memberId) {
                        uploadFile(file: $file) {
                            filename
                            mimetype
                            url
                        }
                    }
                }
            }
        `,
        login: gql`
            mutation Login($email: String!, $password: String!) {
                login(email: $email, password: $password)
            }
        `,
        generateLoginOptions: gql`
            mutation generateLoginOptions($email: String!) {
                generateLoginOptions(email: $email)
            }
        `,
        verifyLoginResponse: gql`
            mutation verifyLoginResponse($email: String!, $response: String!) {
                verifyLoginResponse(email: $email, response: $response)
            }
        `,
        logout: gql`
            mutation Logout {
                logout
            }
        `,
        register: gql`
            mutation Register($email: String!, $password: String!, $passwordValidation: String!) {
                register(email: $email, password: $password, passwordValidation: $passwordValidation)
            }
        `,
        reset: gql`
            mutation Reset($email: String!) {
                reset(email: $email)
            }
        `,
        registerConfirm: gql`
            mutation RegisterConfirm($token: String!) {
                registerConfirm(token: $token)
            }
        `,
        resetConfirm: gql`
            mutation ResetConfirm($token: String!, $password: String!, $passwordValidation: String!) {
                resetConfirm(token: $token, password: $password, passwordValidation: $passwordValidation)
            }
        `,
        shadowRegister: gql`
            mutation ShadowRegister($token: String!, $password: String!, $passwordValidation: String!) {
                shadowRegister(token: $token, password: $password, passwordValidation: $passwordValidation)
            }
        `,
        resend: gql`
            mutation Resend($email: String!) {
                resendConfirmation(email: $email)
            }
        `,
    };
}
