import { useQuery } from '@apollo/client';
import { IconCalendarEvent, IconEyeOff, IconLockOpen, IconPencil, IconUsers } from '@tabler/icons';
import moment from 'moment';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Rights } from '../../../../api/Rights';
import { Event } from '../../../../api/entities/Event';
import { Translate } from '../../../../utils/Translate';
import { capitalize } from '../../../../utils/capitalize';
import { classNames } from '../../../../utils/classNames';
import { hasRights } from '../../../../utils/hasRights';
import { memberList } from '../../../../utils/memberList';
import { TWIX_FORMAT } from '../../../../utils/twixFormat';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { EventAttendButtom } from '../../../utils/EventAttendButton';
import { EventAttendees } from '../../../utils/EventAttendees';
import { FormattedRender } from '../../../utils/FormattedRender';
import { Spinner } from '../../../utils/Spinner';
import { ArticlesListView } from '../../../utils/dataDisplayComponents/articles/ArticlesListView';
import { Routes } from '../../Routes';

interface IEventsDetailProps {
    appState: AppState;
}

export function EventsDetail(props: IEventsDetailProps) {
    const {
        params: { detailId },
    } = useRouteMatch<{ detailId: string }>();
    const id = parseInt(detailId);

    const { loading, error, data, refetch } = useQuery(Api.queries.event.detail(props.appState.activeMember.id), {
        variables: {
            id,
        },
    });

    if (isNaN(id))
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const event = data.auth.as.eventById as Event;

    const attending =
        event.attendees.filter((attendance) => attendance.answer === 'yes').length +
        event.attendeesExternal.filter((attendance) => attendance.answer === 'yes').length;

    const header = (
        <div className="image-header">
            <div className="image" style={{ backgroundImage: `url(${event.imageBefore})` }} />
            <h1>{event.title}</h1>
            <h6>{event.descriptionAfter || event.descriptionBefore}</h6>
            <div className="d-flex flex-row mt-5 align-items-center">
                <img
                    className={classNames('event-picture')}
                    src={event.imageBefore}
                    alt={Translate.message('eventsListView.imageAlt', 'Obrázek na akci {{event}}', {
                        event: event.title,
                    })}
                />
                <div>
                    <h3>{moment(event.from).twix(event.to, event.allDay).format(TWIX_FORMAT)}</h3>
                    {Translate.message('eventsDetail.leaders', 'Organizuje {{list}}', {
                        list: memberList(event.leaders),
                    })}
                </div>
            </div>
        </div>
    );

    return (
        <div className="col-12">
            {hasRights(props.appState, Rights.EVENT_MODIFY) ||
            event.leaders.some((leader) => leader.id === props.appState.activeMember.id) ? (
                <div className="d-flex flex-row align-items-start">
                    <div className="flex-grow-1">{header}</div>
                    <div>
                        <Link
                            to={Routes.eventsEdit(props.appState.memberId, event.id)}
                            className="btn btn-primary mt-3"
                        >
                            <IconPencil /> {Translate.message('eventsDetail.edit', 'Upravit')}
                        </Link>
                    </div>
                </div>
            ) : (
                header
            )}

            {event.registrationStart && (
                <>
                    <div>
                        <h2 className="section-heading">
                            {moment(event.from).isBefore()
                                ? Translate.message('eventsDetail.attendees', 'Účastníci')
                                : Translate.message('eventsDetail.attendance', 'Přihlašování')}
                        </h2>

                        <EventAttendees appState={props.appState} event={event} />

                        {moment(event.from).isAfter() && (
                            <>
                                <div className="mt-5">
                                    <EventAttendButtom
                                        appState={props.appState}
                                        event={event}
                                        onUpdate={() => refetch()}
                                    />
                                </div>
                                <div className="mt-4 mb-2">
                                    <p>
                                        <IconCalendarEvent />{' '}
                                        {event.registrationEnd
                                            ? Translate.message(
                                                  'eventsDetail.sinceRange',
                                                  'Přihlašování probíhá {{date}}',
                                                  {
                                                      date: moment(event.registrationStart)
                                                          .twix(event.registrationEnd)
                                                          .format(TWIX_FORMAT),
                                                  },
                                              )
                                            : Translate.message(
                                                  'eventsDetail.since',
                                                  'Přihlašování probíhá od {{date}}',
                                                  {
                                                      date: moment(event.from).calendar(),
                                                  },
                                              )}
                                    </p>
                                    <p>
                                        <IconUsers />{' '}
                                        {event.maxAttendees
                                            ? attending >= event.maxAttendees
                                                ? Translate.message('eventsDetail.capacityFull', 'Akce je plná', {
                                                      used: attending,
                                                      remaining: event.maxAttendees - attending,
                                                      max: event.maxAttendees,
                                                  })
                                                : Translate.message(
                                                      'eventsDetail.capacityLimited',
                                                      '{{remaining?plural|Zbývá už jen|Zbývají už jen|Zbývá už jen}} {{remaining}} {{remaining?plural|místo|místa|míst}}',
                                                      {
                                                          used: attending,
                                                          remaining: event.maxAttendees - attending,
                                                          max: event.maxAttendees,
                                                      },
                                                  )
                                            : Translate.message(
                                                  'eventsDetail.capacityUnlimited',
                                                  '{{used}} {{used?plural|člověk|lidi|lidí}} už je přihlášených',
                                                  {
                                                      used: attending,
                                                  },
                                              )}
                                    </p>
                                    {event.allowExternal && (
                                        <p>
                                            <IconLockOpen />{' '}
                                            {Translate.message(
                                                'eventsDetail.allowExternal',
                                                'Na akci se můžou přihlašovat i neduháci',
                                            )}
                                        </p>
                                    )}
                                </div>
                            </>
                        )}
                        {event.reports.items.length > 0 && (
                            <>
                                <h2 className="section-heading">
                                    {Translate.message('eventsDetail.reports', 'Kroniky')}
                                </h2>
                                {event.reports.items
                                    .sort((a, b) => moment(a.date).unix() - moment(b.date).unix() || a.id - b.id)
                                    .map((report) => (
                                        <div className="block">
                                            {report.title ? (
                                                <>
                                                    <h3>
                                                        {report.title} {!report.public && <IconEyeOff />}
                                                    </h3>
                                                    <h6>{moment(report.date).format('dddd DD.MM.YYYY')}</h6>
                                                </>
                                            ) : (
                                                <>
                                                    <h3>
                                                        {capitalize(moment(report.date).format('dddd DD.MM.YYYY'))}{' '}
                                                        {!report.public && <IconEyeOff />}
                                                    </h3>
                                                </>
                                            )}
                                            <div className="mt-4">
                                                <FormattedRender content={report.content || ''} />
                                            </div>
                                        </div>
                                    ))}
                            </>
                        )}
                    </div>
                </>
            )}
            {event.articles.length > 0 && (
                <>
                    <h2 className="section-heading">
                        {Translate.message('eventsDetail.articles', 'Lístečky na akci')}
                    </h2>
                    <ArticlesListView
                        query={Api.queries.article.list(props.appState.activeMember.id)}
                        dataResolver={(data) => data.auth.as.articles}
                        appState={props.appState}
                        filter={{
                            from: moment(new Date()).toISOString(),
                            relatedEventId: event.id,
                        }}
                    />
                </>
            )}
        </div>
    );
}
