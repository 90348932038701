import { IconMailOpened } from '@tabler/icons';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from '../../../../../utils/Translate';
import { Routes } from '../../../Routes';
import { IStepProps } from '../Apply';

export function StepOutro(props: IStepProps) {
    return (
        <>
            <h2 className="mb-4">
                <Translate.Component name="application.outro.title" alt="Máme tvou přihlášku" />
            </h2>
            <div className="mb-5">
                <p>
                    <Translate.Component
                        name="application.outro.subtitle"
                        alt="Přihlášku už máme ve schránce a za chvíli mi ji cvičené veverky přinesou až do stanu."
                    />
                </p>
                <p>
                    <Translate.Component
                        name="application.outro.watchMail"
                        alt="V nejbližších dnech <strong>sleduj svůj email</strong>, kam ti pošleme další informace."
                    />
                </p>
                <p className="text-center text-success">
                    <IconMailOpened size={80} strokeWidth={1} />
                </p>
                <p>
                    <Translate.Component name="application.outro.goodbye" alt="Prozatím se měj a těšíme se na tebe" />
                </p>
            </div>
            <div className="mt-5 d-flex flex-row align-items-center">
                <div className="d-inline-block text-start"></div>
                <div className="d-inline-block text-end  flex-grow-1">
                    <Link
                        to={Routes.auth()}
                        className="btn btn-primary"
                        onClick={() => {
                            props.appState.checkAuthorization();
                        }}
                    >
                        {Translate.message('application.complete', 'Dokončit')}
                    </Link>
                </div>
            </div>
        </>
    );
}
