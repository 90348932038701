import moment from 'moment';
import { Translate } from '../../../../utils/Translate';
import { filterPreset } from '../../../utils/dataDisplayComponents/pagedView/Page';

export const eventFilterPresets: filterPreset[] = [
    {
        title: Translate.message('events.presets.thisYear', 'Letošní akce'),
        value: {
            from: moment().startOf('year').format('YYYY-MM-DD'),
        },
    },
    {
        title: Translate.message('events.presets.365Days', 'Akce za poslední rok'),
        value: {
            from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        },
    },
];
