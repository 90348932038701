import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Api } from '../../../api/Api';
import { AppState } from '../../../AppState';
import { Translate } from '../../../utils/Translate';
import { ErrorDisplay } from '../../utils/ErrorDisplay';
import { Spinner } from '../../utils/Spinner';
import { Routes } from '../Routes';

interface IMembershipRequestProps {
    appState: AppState;
}

export function MembershipRequest(props: IMembershipRequestProps) {
    const { appState } = props;

    const [request, { loading, error, data }] = useMutation(Api.mutations.member.createMembershipRequest);

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    if (data) {
        props.appState.checkAuthorization();
    }

    const requests = appState.memberDetails!.membershipRequests;
    if (requests.some((req) => req.status === 'pending') || data) {
        return (
            <>
                <h2 className="mb-4">
                    {Translate.message('authAs.membershipRequested.title', 'Vaše přihláška do Duhy se zpracovává')}
                </h2>
                <div className="mb-5">
                    <p>
                        <Translate.Component
                            name="authAs.membershipRequested.content.1"
                            alt="Prosíme o strpení, naše vedení už se na Vaši přihlášku kouká. Schválení přihlášky může trvat až týden."
                        />
                    </p>
                    <p>
                        <Translate.Component
                            name="authAs.membershipRequested.content.2"
                            alt="Pokud máte jakékoli dotazy, nebo proces trvá příliš dlouho, můžete nám zkusit napsat email."
                        />
                    </p>
                </div>
                <div className="mt-5 d-flex flex-row align-items-center">
                    <div className="d-inline-block text-start">
                        <Link to={Routes.auth()}>
                            {Translate.message('authAs.membershipRequested.back', 'Zpět na výběr člena')}
                        </Link>
                    </div>
                    <div className="d-inline-block text-end  flex-grow-1"></div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <h2 className="mb-4">{Translate.message('authAs.membershipInvalid.title', 'Podejte si přihlášku')}</h2>
                <div className="mb-5">
                    <p>
                        <Translate.Component
                            name="authAs.membershipInvalid.content.1"
                            alt="Vypadá to, že aktuálně nemáte aktivní členství v Duze."
                        />
                    </p>
                    <p>
                        <Translate.Component
                            name="authAs.membershipInvalid.content.2"
                            alt="Pokud se chcete stát členem, můžete <<strong&požádat o zřízení členství>>. Požádáním souhlasíte se zpracováním osobních údajů."
                        />
                    </p>
                </div>
                <div className="mb-5 text-center">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            request({
                                variables: {
                                    memberId: appState.activeMember.id,
                                },
                            });
                        }}
                    >
                        {Translate.message('authAs.membershipInvalid.button', 'Požádat o členství v Duze AZ')}
                    </button>
                </div>
                <div className="mt-5 d-flex flex-row align-items-center">
                    <div className="d-inline-block text-start">
                        <Link to={Routes.auth()}>
                            {Translate.message('authAs.membershipInvalid.back', 'Zpět na výběr člena')}
                        </Link>
                    </div>
                    <div className="d-inline-block text-end  flex-grow-1"></div>
                </div>
            </>
        );
    }
}
