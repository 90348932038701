import { IconPlus } from '@tabler/icons';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Translate } from '../../../../utils/Translate';
import { PagedView } from '../../../utils/dataDisplayComponents/pagedView/PagedView';
import { Routes } from '../../Routes';
import { EventsCalendar } from './EventsCalendar';
import { EventsDetail } from './EventsDetail';
import { EventsEditor } from './EventsEditor';
import { EventsMy } from './EventsMy';
import { EventsNew } from './EventsNew';
import { EventsUpcoming } from './EventsUpcoming';
import { PublicReportsEditor } from './editors/PublicReportsEditor';
import { eventColumns } from './eventColumns';
import { eventFilterPresets } from './eventFilterPresets';
import { eventFilters } from './eventFilters';

interface IEventsPageProps {
    appState: AppState;
}

export function EventsPage(props: IEventsPageProps) {
    return (
        <Switch>
            <Route path={Routes.events(props.appState.memberId)} exact>
                <Redirect to={Routes.upcomingEvents(props.appState.memberId)} />
            </Route>
            <Route path={Routes.eventsEdit(props.appState.memberId)}>
                <EventsEditor appState={props.appState} />
            </Route>
            <Route path={Routes.eventsDetail(props.appState.memberId)}>
                <EventsDetail appState={props.appState} />
            </Route>
            <Route path={Routes.upcomingEvents(props.appState.memberId)}>
                <EventsUpcoming appState={props.appState} />
            </Route>
            <Route path={Routes.eventsNew(props.appState.memberId)}>
                <EventsNew appState={props.appState} />
            </Route>
            <Route path={Routes.eventsList(props.appState.memberId)}>
                <div className="col-12">
                    <h1>{Translate.message('events.all.title', 'Všechny akce')}</h1>
                    <h6>{Translate.message('events.all.subtitle', 'Seznam všech akcí')}</h6>
                    <PagedView
                        appState={props.appState}
                        query={Api.queries.event.detailedList(props.appState.activeMember.id)}
                        dataResolver={(data) => data.auth.as.events}
                        columns={eventColumns(props.appState)}
                        defaultSortOrder={{
                            column: 'from',
                            direction: 'DESC',
                        }}
                        searchFilter="title"
                        filters={eventFilters}
                        presets={eventFilterPresets}
                        actions={[
                            {
                                icon: (
                                    <>
                                        <IconPlus /> {Translate.message('events.newItem', 'Nová akce')}
                                    </>
                                ),
                                linkTo: Routes.eventsNew(props.appState.memberId),
                            },
                        ]}
                    />
                </div>
            </Route>
            <Route path={Routes.eventsCalendar(props.appState.memberId)}>
                <EventsCalendar appState={props.appState} />
            </Route>
            <Route path={Routes.eventsMy(props.appState.memberId)}>
                <EventsMy appState={props.appState} />
            </Route>
            <Route path={Routes.reports(props.appState.memberId)}>
                <PublicReportsEditor appState={props.appState} />
            </Route>
            <Route>
                <Redirect to={Routes.events(props.appState.memberId)} />
            </Route>
        </Switch>
    );
}
