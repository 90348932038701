import * as React from 'react';
import { Link } from 'react-router-dom';
import { Member } from '../../api/entities/Member';
import { AppState } from '../../AppState';
import { classNames } from '../../utils/classNames';
import { Routes } from '../routes/Routes';

interface IMemberHeadProps {
    appState: AppState;
    member: Member;
    size?: number;
    noLink?: true;
    className?: string;
}

export function MemberHead(props: IMemberHeadProps) {
    const img = (
        <img
            src={props.member.image}
            alt={props.member.nickname}
            title={props.member.nickname}
            className={classNames('profile-picture', props.className)}
            width={props.size || 50}
            height={props.size || 50}
        />
    );

    if (props.noLink) return img;

    return <Link to={Routes.membersDetail(props.appState.memberId, props.member.id)}>{img}</Link>;
}
