import { Translate } from '../../../../utils/Translate';
import { filterPreset } from '../../../utils/dataDisplayComponents/pagedView/Page';

export const memberFilterPresets: filterPreset[] = [
    {
        title: Translate.message('members.presets.activeMembers', 'Současní členové'),
        value: {
            validMembership: 'true',
        },
    },
];
