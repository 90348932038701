import { Role } from '../../api/entities/Role';
import { classNames } from '../../utils/classNames';

interface IRightsIconProps {
    role: Role;
    className?: string;
}

export function RightsIcon(props: IRightsIconProps) {
    const { role, className } = props;
    return (
        <span className={classNames('badge', 'ms-2', className)} style={{ background: role.color || '#343a40' }}>
            {role.title.slice(0, 3)}
        </span>
    );
}
