import { IconUser } from '@tabler/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { MembershipRequest } from '../../../../api/entities/Member';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { columnDefinition } from '../../../utils/dataDisplayComponents/pagedView/Page';
import { Routes } from '../../Routes';
import { RequestStatus } from './RequestStatus';

export const requestColumns: (appState: AppState) => columnDefinition[] = (appState) => [
    {
        width: 0,
        getter: (row) => <RequestStatus request={row as MembershipRequest} appState={appState} />,
        sortBy: 'status',
    },
    { title: 'members.firstname', width: 5, getter: (row) => (row['member'] as any)['firstname'], sortBy: 'firstname' },
    { title: 'members.lastname', width: 5, getter: (row) => (row['member'] as any)['lastname'], sortBy: 'lastname' },
    { title: 'members.nickname', width: 3, getter: (row) => (row['member'] as any)['nickname'], sortBy: 'nickname' },
    {
        title: 'members.gender',
        width: 2,
        getter: (row) => Translate.message(`general.${(row['member'] as any)['gender']}`),
        sortBy: 'gender',
    },
    { title: 'members.email', width: 4, getter: (row) => (row['member'] as any)['email'] },
    {
        title: 'members.birthdate',
        width: 3,
        getter: (row) =>
            (row['member'] as any)['birthdate'] && ((row['member'] as any)['birthdate'] as string).length > 0
                ? moment((row['member'] as any)['birthdate'] as string).calendar()
                : '',
        sortBy: 'birthdate',
    },
    {
        title: 'members.age',
        width: 1,
        getter: (row) => {
            const val = moment().diff((row['member'] as any)['birthdate'] as string, 'years');
            return isNaN(val) ? '' : val;
        },
    },
    {
        width: 0,
        getter: (row) => (
            <div className="actions">
                <Link to={Routes.membersDetail(appState.memberId, (row['member'] as any)['id'] as number)}>
                    <IconUser />
                </Link>
            </div>
        ),
    },
];
