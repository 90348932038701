import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { ListEndText } from '../../../utils/ListEndText';
import { Routes } from '../../Routes';
import { meetingFrequencyToText } from './meetingFrequencyToText';

interface IMeetingsMyPageProps {
    appState: AppState;
}

export function MeetingsMyPage(props: IMeetingsMyPageProps) {
    const meetings = props.appState.memberDetails!.meetings.items.filter((meeting) => meeting.current);
    const length = meetings.length;
    if (length === 0) {
        return (
            <div className="col-12">
                <h1>{Translate.message('meetings.my.title', 'Moje schůzka')}</h1>
                <h6>{Translate.message('meetings.my.subtitle', 'Copak se pro tebe na letošních schůzkách peče?')}</h6>
                <ListEndText>
                    {Translate.message(
                        'meetings.my.noMeetings',
                        'Letos tě nemáme {{gender?gender|vedeného|vedenou|vedené}} u žádné schůzky... Pokud někam chodíš, ozvi se prosím svému schůzkovému vedoucímu.',
                        { gender: props.appState.memberDetails!.gender },
                    )}
                </ListEndText>
            </div>
        );
    }

    if (length === 1) {
        return <Redirect to={Routes.meetingsMyDetail(props.appState.memberId, meetings[0].id)} />;
    }

    return (
        <div className="col-12">
            <h1>{Translate.message('meetings.my.title', 'Moje schůzka')}</h1>
            <h6>{Translate.message('meetings.my.subtitle', 'Copak se pro tebe na letošních schůzkách peče?')}</h6>
            <ListEndText>
                {Translate.message(
                    'meetings.my.multipleMeetings',
                    'Vypadá to, že chodíš na víc schůzek. Kterou máš na mysli?',
                    { gender: props.appState.memberDetails!.gender },
                )}
            </ListEndText>
            {meetings
                .sort((a, b) => moment(a.firstFrom).unix() - moment(b.firstFrom).unix())
                .filter((meeting) => meeting.current)
                .map((meeting, i) => (
                    <Link
                        key={i}
                        to={Routes.meetingsMyDetail(props.appState.memberId, meeting.id)}
                        className="link-hidden d-inline"
                    >
                        <div className="block tight text-start w-100 align-items-center">
                            <h3>{meeting.title}</h3>
                            <div>{meetingFrequencyToText(meeting)}</div>
                        </div>
                    </Link>
                ))}
        </div>
    );
}
