import { useMutation } from '@apollo/client';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AppState } from '../../../../../AppState';
import { Api } from '../../../../../api/Api';
import { Event } from '../../../../../api/entities/Event';
import { Member } from '../../../../../api/entities/Member';
import { Report } from '../../../../../api/entities/Report';
import { Translate } from '../../../../../utils/Translate';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { ProfilePreview } from '../../../../utils/ProfilePreview';
import { Spinner } from '../../../../utils/Spinner';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { SearchInput } from '../../../../utils/inputs/SearchInput';
import { date, required } from '../../../../utils/inputs/inputValidators/validators';

export interface IReportEditorProps {
    appState: AppState;
    event: Event;
    report?: Report;
    admin?: boolean;
    onSave: () => void;
}

export function ReportEditor({ appState, event, report, onSave, admin }: IReportEditorProps) {
    const [editorKey, setEditorKey] = useState(0);

    useEffect(() => {
        setEditorKey((editorKey) => editorKey + 1);
    }, [report]);

    const [create, createStatus] = useMutation(Api.mutations.report.create);
    const [update, updateStatus] = useMutation(Api.mutations.report.modify);

    if (createStatus.loading || updateStatus.loading) return <Spinner />;
    if (createStatus.error) return <ErrorDisplay error={createStatus.error} />;
    if (updateStatus.error) return <ErrorDisplay error={updateStatus.error} />;

    return (
        <Form
            defaultValues={{
                date: (report?.date ? moment(report.date) : moment(event.from)).format('YYYY-MM-DD'),
                title: report?.title || null,
                authors: report?.authors.map((author) => author.id) || [],
                content: report?.content || null,
                ready: report?.ready || false,
                public: report?.public || false,
            }}
            validators={(data) => ({
                date: [date],
                title: [],
                authors: [required],
                content: [],
                ready: [],
                public: [],
            })}
            onSubmit={async (data) => {
                let value: { [key: string]: any } = {
                    title: data.title && data.title.length > 0 ? data.title : null,
                    content: data.content,
                    ready: !!data.ready,
                };
                if (admin) {
                    value = {
                        ...value,
                        date: moment(data.date).toISOString(),
                        authors: data.authors,
                        public: !!data.public,
                    };
                }
                if (report) {
                    await update({
                        variables: {
                            memberId: appState.activeMember.id,
                            reportId: report.id,
                            value,
                        },
                    });
                    onSave();
                } else {
                    await create({
                        variables: {
                            memberId: appState.activeMember.id,
                            value: {
                                ...value,
                                event: event.id,
                            },
                        },
                    });
                    onSave();
                }
            }}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <div className="mb-5">
                        <FormInput
                            {...inputProps('title')}
                            type="text"
                            label={Translate.message('reports.fields.title', 'Název kroniky')}
                        />
                        <FormInput
                            {...inputProps('date')}
                            type="date"
                            disabled={!admin}
                            label={Translate.message('reports.fields.date', 'Datum')}
                        />
                    </div>
                    <div className="mb-5">
                        <FormInput
                            {...inputProps('content')}
                            type="editor"
                            formattedType="quill"
                            label={Translate.message('reports.fields.content', 'Obsah kroniky')}
                            className="w-100"
                            appState={appState}
                            key={editorKey}
                        />
                    </div>
                    {admin && (
                        <div className="mb-5">
                            <h3>{Translate.message('reports.fields.authors', 'Autoři kroniky')}</h3>
                            <SearchInput<Member>
                                type="text"
                                label={Translate.message('reports.fields.authors', 'Autoři kroniky')}
                                appState={appState}
                                query={Api.queries.member.search}
                                resultResolver={(data) => data?.auth?.as?.memberById}
                                searchResolver={(data) => data?.auth?.as?.members}
                                itemDisplayJSX={(member) => (
                                    <>
                                        {member.firstname} "{member.nickname}" {member.lastname}
                                    </>
                                )}
                                itemDisplayText={(member) =>
                                    `${member.firstname} "${member.nickname}" ${member.lastname}`
                                }
                                onChange={(value) =>
                                    value &&
                                    inputProps('authors').onChange!([...((state.authors?.value as any) || []), value])
                                }
                                className="mb-3"
                                error={inputProps('authors').error}
                                clearAfterInsert
                            />
                            <div>
                                {state.authors?.value &&
                                    (state.authors.value as any as number[]).map((id, i) => (
                                        <ProfilePreview
                                            key={i}
                                            appState={appState}
                                            id={id}
                                            className="link cross-on-hover"
                                            onClick={() => {
                                                inputProps('authors').onChange!(
                                                    (state.authors?.value as any as number[]).filter(
                                                        (item) => item !== id,
                                                    ),
                                                );
                                            }}
                                        />
                                    ))}
                            </div>
                        </div>
                    )}
                    <div className="mb-5">
                        <FormInput
                            {...inputProps('ready')}
                            type="checkbox"
                            label={Translate.message('reports.fields.ready', 'Kronika je hotová')}
                        />
                        <FormInput
                            {...inputProps('public')}
                            type="checkbox"
                            disabled={!admin}
                            label={Translate.message('reports.fields.public', 'Kronika je schválená a veřejná')}
                        />
                    </div>
                    <button {...buttonProps}>
                        {report
                            ? Translate.message('reports.edit.save', 'Uložit')
                            : Translate.message('reports.new.create', 'Vytvořit')}
                    </button>
                </>
            )}
        </Form>
    );
}
