import * as React from 'react';
import { OrganizationRole } from '../../api/entities/OrganizationRole';
import { IconByName } from './IconByName';

interface IRoleIconProps {
    role: OrganizationRole;
    className?: string;
}

export function RoleIcon(props: IRoleIconProps) {
    const { role, className } = props;
    return (
        <>
            {role.icon ? (
                <span title={role.title}>
                    <IconByName icon={role.icon as any} className={className} />
                </span>
            ) : (
                <span title={role.title}>
                    <IconByName icon={('letter-' + role.title.substring(0, 1)) as any} className={className} />
                </span>
            )}
        </>
    );
}
