import { IconCheck, IconEdit, IconUser, IconX } from '@tabler/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Member } from '../../../../api/entities/Member';
import { Translate } from '../../../../utils/Translate';
import { IconByName } from '../../../utils/IconByName';
import { RightsIcons } from '../../../utils/RightsIcons';
import { columnDefinition } from '../../../utils/dataDisplayComponents/pagedView/Page';
import { Routes } from '../../Routes';

export const memberColumns: (appState: AppState) => columnDefinition[] = (appState) => [
    { width: 0, getter: (row) => (row['validMembership'] ? <IconCheck /> : <IconX />) },
    // @translation members.firstname: "Firstname"
    { title: 'members.firstname', width: 5, getter: (row) => row['firstname'], sortBy: 'firstname' },
    // @translation members.lastname: "Lastname"
    { title: 'members.lastname', width: 5, getter: (row) => row['lastname'], sortBy: 'lastname' },
    // @translation members.nickname: "Nickname"
    { title: 'members.nickname', width: 3, getter: (row) => row['nickname'], sortBy: 'nickname' },
    // @translation members.gender: "Gender"
    {
        title: 'members.gender',
        width: 2,
        getter: (row) => Translate.message(`general.${row['gender']}`),
        sortBy: 'gender',
    },
    // @translation members.organizationRoles: "Role"
    {
        title: 'members.roles',
        width: 0,
        getter: (row) => (
            <>
                {(row as any as Member).organizationRoles.map((role, i) =>
                    role.icon ? (
                        <span key={i} title={role.title}>
                            <IconByName icon={role.icon as any} />
                        </span>
                    ) : (
                        <span key={i} title={role.title}>
                            <IconByName icon={('letter-' + role.title.substring(0, 1)) as any} />
                        </span>
                    ),
                )}
                {<RightsIcons member={row as any} />}
            </>
        ),
    },
    // @translation members.email: "Email"
    { title: 'members.email', width: 4, getter: (row) => row['email'] },
    // @translation members.birthdate: "Birthdate"
    {
        title: 'members.birthdate',
        width: 3,
        getter: (row) =>
            row['birthdate'] && (row['birthdate'] as string).length > 0
                ? moment(row['birthdate'] as string).calendar()
                : '',
        sortBy: 'birthdate',
    },
    // @translation members.age: "Age"
    {
        title: 'members.age',
        width: 1,
        getter: (row) => {
            const val = moment().diff(row['birthdate'] as string, 'years');
            return isNaN(val) ? '' : val;
        },
    },
    {
        width: 0,
        getter: (row) => (
            <div className="actions">
                <Link to={Routes.membersDetail(appState.memberId, row.id as number)}>
                    <IconUser />
                </Link>
                <Link to={Routes.membersEdit(appState.memberId, row.id as number)}>
                    <IconEdit />
                </Link>
            </div>
        ),
    },
];
