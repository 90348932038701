import MasonryObject from 'masonry-layout';
import * as React from 'react';

interface IMasonryProps {}

export function Masonry(props: React.PropsWithChildren<IMasonryProps>) {
    const [masonry, setMasonry] = React.useState<MasonryObject | null>(null);

    if (masonry && masonry.reloadItems) {
        masonry.reloadItems();
    }

    return (
        <div
            ref={(object) => {
                if (object && !masonry) {
                    setMasonry(
                        new MasonryObject(object, {
                            itemSelector: '.grid-item',
                        }),
                    );
                }
            }}
        >
            {props.children}
        </div>
    );
}
