import * as React from 'react';
import { Event } from '../../../../../api/entities/Event';
import { AppState } from '../../../../../AppState';
import { Translate } from '../../../../../utils/Translate';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';

export interface IAfterEditorProps {
    appState: AppState;
    event: Event;
    onSubmit: (value: { [key: string]: any }) => void;
}

export function AfterEditor(props: IAfterEditorProps) {
    const { event, onSubmit } = props;

    return (
        <>
            <Form
                onSubmit={(value) => {
                    const result = {
                        descriptionAfter: value.descriptionAfter,
                        linkPhoto: value.linkPhoto,
                        linkVideo: value.linkVideo,
                    };
                    onSubmit(result);
                }}
                validators={() => ({
                    descriptionAfter: [],
                    linkPhoto: [],
                    linkVideo: [],
                })}
                defaultValues={{
                    descriptionAfter: event.descriptionAfter,
                    linkPhoto: event.linkPhoto,
                    linkVideo: event.linkVideo,
                }}
            >
                {(inputProps, state, buttonProps) => (
                    <>
                        <div className="mb-3">
                            <FormInput
                                {...inputProps('linkPhoto')}
                                type="text"
                                label={Translate.message('events.fields.linkPhoto', 'Odkaz na fotky z akce')}
                            />
                            <FormInput
                                {...inputProps('linkVideo')}
                                type="text"
                                label={Translate.message('events.fields.linkVideo', 'Odkaz na video z akce')}
                            />
                        </div>
                        <div className="mb-5">
                            <FormInput
                                {...inputProps('descriptionAfter')}
                                type="textarea"
                                label={Translate.message('events.fields.descriptionAfter', 'Popisek po akci')}
                                className="w-100"
                            />
                        </div>
                        <div className="mt-5">
                            <button {...buttonProps}>{Translate.message('events.edit.save', 'Uložit')}</button>
                        </div>
                    </>
                )}
            </Form>
        </>
    );
}
