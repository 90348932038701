import { useMutation } from '@apollo/client';
import moment from 'moment';
import * as React from 'react';
import { Api } from '../../../../api/Api';
import { Translate } from '../../../../utils/Translate';
import { TWIX_FORMAT } from '../../../../utils/twixFormat';
import { Accordion } from '../../../utils/Accordion';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Form, IFormProps } from '../../../utils/inputs/Form';
import { FormInput } from '../../../utils/inputs/FormInput';
import { date, required, requiredIf } from '../../../utils/inputs/inputValidators/validators';
import { Spinner } from '../../../utils/Spinner';
import { IProfileEditorProps } from './ProfileEditor';

export function MembershipEditor(props: IProfileEditorProps) {
    const { member, appState, refetch } = props;

    const [add, addStatus] = useMutation(Api.mutations.member.membership.add, { onCompleted: () => refetch() });
    const [remove, removeStatus] = useMutation(Api.mutations.member.membership.remove, {
        onCompleted: () => refetch(),
    });
    const [update, updateStatus] = useMutation(Api.mutations.member.membership.update, {
        onCompleted: () => refetch(),
    });

    const loading = addStatus.loading || removeStatus.loading || updateStatus.loading;
    const error = addStatus.error || removeStatus.error || updateStatus.error;

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const formProps: IFormProps = {
        onSubmit: () => {},
        defaultValues: {
            start: moment().format('YYYY-MM-DD'),
            end: null,
            hasEnd: false,
        },
        validators: (data) => ({
            start: [required, date],
            end: [requiredIf(data.hasEnd?.value), date],
            hasEnd: [],
        }),
        children: (inputProps, state, buttonProps) => (
            <>
                <FormInput
                    type="checkbox"
                    label={Translate.message('membershipEditor.hasEnd', 'Ukončené členství')}
                    {...inputProps('hasEnd')}
                />
                <div className="mb-4">
                    <FormInput
                        type="date"
                        label={Translate.message('membershipEditor.start', 'Začátek členství')}
                        {...inputProps('start', 'align-top')}
                    />
                    <FormInput
                        type="date"
                        label={Translate.message('membershipEditor.end', 'Konec členství')}
                        disabled={!state.hasEnd.value}
                        {...inputProps('end', 'align-top')}
                    />
                </div>
                <button {...buttonProps}>{Translate.message('membershipEditor.save', 'Uložit')}</button>
            </>
        ),
    };

    const memberships = member.memberships
        .sort((a, b) => moment(b.start).diff(a.start))
        .reduce(
            (prev, curr) => ({
                ...prev,
                [curr.start]: {
                    title: curr.end
                        ? moment(curr.start).twix(curr.end, true).format(TWIX_FORMAT)
                        : Translate.message('membershipEditor.since', 'Od {{date}} doteď', {
                              date: moment(curr.start).format('LL'),
                          }),
                    content: (
                        <Form
                            {...formProps}
                            onSubmit={(values) =>
                                update({
                                    variables: {
                                        asId: appState.activeMember.id,
                                        membershipId: curr.id,
                                        value: {
                                            start: values.start,
                                            end: values.hasEnd ? values.end : null,
                                        },
                                    },
                                })
                            }
                            defaultValues={{
                                start: moment(curr.start).format('YYYY-MM-DD'),
                                end: curr.end ? moment(curr.end).format('YYYY-MM-DD') : null,
                                hasEnd: !!curr.end,
                            }}
                        >
                            {(inputProps, state, buttonProps) => (
                                <>
                                    {formProps.children(inputProps, state, buttonProps)}
                                    <ConfirmButton
                                        label={Translate.message('membershipEditor.remove', 'Odstranit')}
                                        color="danger"
                                        className="ms-3"
                                        onConfirm={() =>
                                            remove({
                                                variables: {
                                                    asId: appState.activeMember.id,
                                                    membershipId: curr.id,
                                                },
                                            })
                                        }
                                    ></ConfirmButton>
                                </>
                            )}
                        </Form>
                    ),
                },
            }),
            {},
        );

    return (
        <>
            <Accordion
                tabs={{
                    ...memberships,
                    new: {
                        title: Translate.message('membershipEditor.new', 'Přidat nové členství'),
                        content: (
                            <Form
                                {...formProps}
                                onSubmit={(values) =>
                                    add({
                                        variables: {
                                            asId: appState.activeMember.id,
                                            memberId: member.id,
                                            value: {
                                                start: values.start,
                                                end: values.hasEnd ? values.end : null,
                                            },
                                        },
                                    })
                                }
                                defaultValues={{
                                    start: moment().format('YYYY-MM-DD'),
                                    end: null,
                                    hasEnd: false,
                                }}
                            />
                        ),
                    },
                }}
            />
        </>
    );
}
