import moment from 'moment';
import { AppState } from '../../../../../AppState';
import { Meeting } from '../../../../../api/entities/Meeting';
import { Translate } from '../../../../../utils/Translate';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { date, inRange, nonempty, required } from '../../../../utils/inputs/inputValidators/validators';

interface IMeetingInfoEditorProps {
    appState: AppState;
    meeting?: Meeting;
    onSubmit: (value: { [key: string]: any }) => void;
}

export function MeetingInfoEditor(props: IMeetingInfoEditorProps) {
    const { meeting, onSubmit } = props;

    let defaultValues: { [key: string]: any } = {
        title: null,
        seasonFrom: null,
        seasonTo: null,
        firstFrom: null,
        firstTo: null,
        frequency: 'weekly',
        frequencyEvery: 1,
    };
    if (meeting) {
        defaultValues = {
            title: meeting.title,
            seasonFrom: moment(meeting.seasonFrom).format('YYYY-MM-DD'),
            seasonTo: moment(meeting.seasonTo).format('YYYY-MM-DD'),
            firstFrom: moment(meeting.firstFrom).format('YYYY-MM-DD HH:mm'),
            firstTo: moment(meeting.firstTo).format('YYYY-MM-DD HH:mm'),
            frequency: meeting.frequency,
            frequencyEvery: meeting.frequencyEvery,
        };
    }

    return (
        <Form
            defaultValues={defaultValues}
            validators={(data) => ({
                title: [required, nonempty],
                seasonFrom: [required, date],
                seasonTo: [required, date],
                firstFrom: [required, date],
                firstTo: [required, date],
                frequency: [required],
                frequencyEvery: [required, inRange(1, 1000, true)],
            })}
            onSubmit={(data) => {
                const value = {
                    ...data,
                    seasonFrom: moment(data.seasonFrom).toISOString(),
                    seasonTo: moment(data.seasonTo).toISOString(),
                    firstFrom: moment(data.firstFrom).toISOString(),
                    firstTo: moment(data.firstTo).toISOString(),
                };
                onSubmit(value);
            }}
        >
            {(inputProps, state, buttonProps) => (
                <div className="col-xl-8 col-12">
                    <FormInput
                        {...inputProps('title', 'w-100')}
                        type="text"
                        label={Translate.message('meetings.fields.title', 'Název schůzky')}
                    />
                    <h3 className="mt-5 mb-0">{Translate.message('meetings.fields.group.season', 'Období')}</h3>
                    <div className="row">
                        <div className="col-6">
                            <FormInput
                                {...inputProps('seasonFrom', 'w-100')}
                                type="date"
                                label={Translate.message('meetings.fields.seasonFrom', 'Období od')}
                            />
                        </div>
                        <div className="col-6">
                            <FormInput
                                {...inputProps('seasonTo', 'w-100')}
                                type="date"
                                label={Translate.message('meetings.fields.seasonTo', 'Období do')}
                            />
                        </div>
                    </div>
                    <h3 className="mt-5 mb-0">{Translate.message('meetings.fields.group.first', 'První schůzka')}</h3>
                    <div className="row">
                        <div className="col-6">
                            <FormInput
                                {...inputProps('firstFrom', 'w-100')}
                                type="datetime-local"
                                label={Translate.message('meetings.fields.firstFrom', 'První schůzka od')}
                            />
                        </div>
                        <div className="col-6">
                            <FormInput
                                {...inputProps('firstTo', 'w-100')}
                                type="datetime-local"
                                label={Translate.message('meetings.fields.firstTo', 'První schůzka do')}
                            />
                        </div>
                    </div>
                    <h3 className="mt-5 mb-0">{Translate.message('meetings.fields.group.frequency', 'Frekvence')}</h3>
                    <div className="row">
                        <div className="col-8">
                            <FormInput
                                {...inputProps('frequency', 'w-100')}
                                type="select"
                                options={[
                                    {
                                        title: Translate.message('meetings.frequency.select.daily', 'Denně'),
                                        value: 'daily',
                                    },
                                    {
                                        title: Translate.message('meetings.frequency.select.weekly', 'Týdně'),
                                        value: 'weekly',
                                    },
                                    {
                                        title: Translate.message('meetings.frequency.select.monthly', 'Měsíčně'),
                                        value: 'monthly',
                                    },
                                    {
                                        title: Translate.message('meetings.frequency.select.yearly', 'Ročně'),
                                        value: 'yearly',
                                    },
                                    {
                                        title: Translate.message('meetings.frequency.select.irregular', 'Nepravidelně'),
                                        value: 'irregular',
                                    },
                                ]}
                                label={Translate.message('meetings.fields.frequency', 'Opakování')}
                            />
                        </div>
                        <div className="col-4">
                            <FormInput
                                {...inputProps('frequencyEvery', 'w-100')}
                                type="number"
                                disabled={state.frequency.value === 'irregular'}
                                label={Translate.message('meetings.fields.frequencyEvery', 'Jednou za')}
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <button {...buttonProps}>
                            {props.meeting
                                ? Translate.message('meetings.edit.save', 'Uložit')
                                : Translate.message('meetings.new.create', 'Vytvořit')}
                        </button>
                    </div>
                </div>
            )}
        </Form>
    );
}
