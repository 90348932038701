import { useQuery } from '@apollo/client';
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Api } from '../../../../api/Api';
import { Member } from '../../../../api/entities/Member';
import { Rights } from '../../../../api/Rights';
import { AppState } from '../../../../AppState';
import { classNames } from '../../../../utils/classNames';
import { hasRights } from '../../../../utils/hasRights';
import { Translate } from '../../../../utils/Translate';
import { EventsListView } from '../../../utils/dataDisplayComponents/events/EventsListView';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Masonry } from '../../../utils/Masonry';
import { Spinner } from '../../../utils/Spinner';
import { Routes } from '../../Routes';

interface IMembersDetailProps {
    appState: AppState;
}

export function MembersDetail(props: IMembersDetailProps) {
    const {
        params: { detailId },
    } = useRouteMatch<{ detailId: string }>();
    const id = parseInt(detailId);

    const memberDetailRights = hasRights(props.appState, Rights.MEMBER_DETAIL);
    const memberModifyRights = hasRights(props.appState, Rights.MEMBER_MODIFY);

    const { loading, error, data } = useQuery(Api.queries.member.publicDetail(props.appState.activeMember.id), {
        variables: {
            id,
        },
    });

    if (isNaN(id))
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const member = data.auth.as.memberById as Member;

    return (
        <div className="col-12">
            <div className="image-header">
                <div className="image" style={{ backgroundImage: `url(${member.image})` }} />
                <h1>
                    {member.firstname} "{member.nickname}" {member.lastname}
                </h1>
                <h6>
                    {[
                        ...member.organizationRoles.map((role) => role.title),
                        member.validMembership
                            ? Translate.message('memberDetail.active', 'Aktivní člen')
                            : Translate.message('memberDetail.inactive', 'Neaktivní člen'),
                    ].join(', ')}
                </h6>
                <div className="d-flex flex-column flex-md-row mt-5 align-items-center">
                    <img
                        className={classNames('profile-picture')}
                        src={member.image}
                        width={150}
                        height={150}
                        alt={Translate.message('memberDetail.imageAlt', 'Profilový obrázek člena {{member}}', {
                            member: member.nickname,
                        })}
                    />
                    {member.motd && member.motd.length > 0 && <div className="quote ms-0 ms-md-5">{member.motd}</div>}
                </div>
                {(memberDetailRights || memberModifyRights) && (
                    <Link to={Routes.membersEdit(props.appState.memberId, member.id)} className="btn btn-primary mt-5">
                        {Translate.message('memberDetail.edit', 'Zobrazit podrobnosti')}
                    </Link>
                )}
                {member.counselorInfo.length > 0 && (
                    <div className="counselors">
                        <div className="counselor-container">
                            <Masonry>
                                {member.counselorInfo.map((info, i) => (
                                    <div className="column grid-item col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
                                        <div className="counselor">
                                            <h3>{info.title}</h3>
                                            <div>{info.content}</div>
                                        </div>
                                    </div>
                                ))}
                            </Masonry>
                        </div>
                    </div>
                )}
                {member.leadEvents && member.leadEvents.total > 0 && (
                    <div>
                        <h2 className="section-heading">
                            {Translate.message('memberDetail.events', 'Akce, které má na svědomí')}
                        </h2>
                        <EventsListView
                            query={Api.queries.event.list(props.appState.activeMember.id)}
                            dataResolver={(data) => data.auth.as.events}
                            appState={props.appState}
                            filter={{
                                leaderId: member.id,
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
