import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { forTime } from 'waitasecond';
import { Api } from '../../../api/Api';
import { Translate } from '../../../utils/Translate';
import { Await } from '../../utils/Await';
import { ErrorDisplay } from '../../utils/ErrorDisplay';
import { Routes } from '../Routes';

interface IRegisterConfirmProps {}

export function RegisterConfirm(props: IRegisterConfirmProps) {
    const token = new URLSearchParams(window.location.search).get('token');
    const [confirm, { loading, error, data, called }] = useMutation(Api.mutations.registerConfirm);

    return (
        <div className="wrapper center-screen spot-rh">
            <main>
                <form>
                    {!token ? (
                        <ErrorDisplay error={Translate.message('error.tokenInvalid')} />
                    ) : (
                        <>
                            {!called && (
                                <>
                                    <h1 className="h2 mb-3 fw-normal">
                                        {Translate.message('registerConfirm.title', 'Potvrdit emailovou adresu')}
                                    </h1>
                                    <p>
                                        {Translate.message(
                                            'registerConfirm.subtitle',
                                            'Opravdu chcete potvrdit vaši adresu?',
                                        )}
                                    </p>
                                    <button
                                        className="mt-4 w-100 btn btn-lg btn-primary text-white"
                                        type="button"
                                        onClick={() => {
                                            confirm({
                                                variables: {
                                                    token,
                                                },
                                            });
                                        }}
                                    >
                                        {Translate.message('registerConfirm.send', 'Potvrdit')}
                                    </button>
                                </>
                            )}
                            {loading && (
                                <>
                                    <h1 className="h2 mb-3 fw-normal">
                                        {Translate.message('registerConfirm.title', 'Potvrdit emailovou adresu')}
                                    </h1>
                                    <p>
                                        {Translate.message(
                                            'registerConfirm.subtitle',
                                            'Opravdu chcete potvrdit vaši adresu?',
                                        )}
                                    </p>
                                    <button
                                        className="mt-4 w-100 btn btn-lg btn-primary text-white"
                                        type="button"
                                        disabled
                                    >
                                        <div className="spinner-border spinner-border-sm text-light" role="status">
                                            <span className="visually-hidden">
                                                {Translate.message('registerConfirm.sending', 'Odesílání...')}
                                            </span>
                                        </div>
                                    </button>
                                </>
                            )}
                            {data && !error && (
                                <>
                                    <h1 className="h2 mb-3 fw-normal">
                                        {Translate.message('registerConfirm.title', 'Potvrdit emailovou adresu')}
                                    </h1>
                                    <p>
                                        <Translate.Component
                                            name="registerConfirm.login"
                                            alt="Gratulujeme, registrace byla dokončena! Za chvíli budete přesměrováni na <<link&přihlašování>>"
                                            wrappers={{
                                                link: (children, key) => (
                                                    <Link key={key} to={Routes.login()}>
                                                        {children}
                                                    </Link>
                                                ),
                                            }}
                                        />
                                    </p>
                                    <Await for={forTime(5000)}>
                                        <Redirect to={Routes.login()} />
                                    </Await>
                                </>
                            )}
                            {error && <ErrorDisplay error={error} />}
                        </>
                    )}
                </form>
            </main>
        </div>
    );
}
