import { Api } from '../api/Api';
import { AppState } from '../AppState';

export async function logout(appState: AppState) {
    await Api.client.mutate({
        mutation: Api.mutations.logout,
    });
    await Api.client.resetStore();
    appState.checkAuthorization();
}
