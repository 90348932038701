import { gql, useQuery } from '@apollo/client';
import { IconCheckbox, IconSquareX } from '@tabler/icons';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Meeting } from '../../../../api/entities/Meeting';
import { Member } from '../../../../api/entities/Member';
import { Translate } from '../../../../utils/Translate';
import { TWIX_FORMAT } from '../../../../utils/twixFormat';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { ListEndText } from '../../../utils/ListEndText';
import { MemberHead } from '../../../utils/MemberHead';
import { Spinner } from '../../../utils/Spinner';
import { CounselorRenderer } from '../members/CounselorRenderer';
import { meetingFrequencyToText } from './meetingFrequencyToText';

interface IMeetingsMyDetailPageProps {
    appState: AppState;
}

export function MeetingsMyDetailPage(props: IMeetingsMyDetailPageProps) {
    const {
        params: { detailId },
    } = useRouteMatch<{ detailId: string }>();
    const id = parseInt(detailId);

    const { loading, error, data } = useQuery(
        gql`
            query MeetingDetail($memberId: Int!, $id: Int!) {
                auth {
                    as(memberId: $memberId) {
                        meetingById(id: $id) {
                            id
                            current
                            title
                            seasonFrom
                            seasonTo
                            firstFrom
                            firstTo
                            frequency
                            frequencyEvery
                            members {
                                firstname
                                lastname
                                nickname
                                image
                                id
                                hasPublicInfo
                            }
                            schedules {
                                id
                                note
                                from
                                to
                                happening
                            }
                        }
                        members(filter: { hasPublicInfo: true, meetingId: $id }) {
                            items {
                                id
                                firstname
                                lastname
                                nickname
                                gender
                                image
                                birchLeaf
                                motd
                                hasPublicInfo
                                birthdate
                                email
                                phone
                                certificates {
                                    certificate {
                                        title
                                    }
                                    validSince
                                    validUntil
                                }
                                validMembership
                                leadEvents {
                                    total
                                }
                                organizationRoles {
                                    id
                                    title
                                    icon
                                }
                                counselorInfo {
                                    id
                                    title
                                    content
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                id,
                memberId: props.appState.activeMember.id,
            },
        },
    );

    if (isNaN(id))
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const meeting: Meeting = data.auth.as.meetingById;
    const counselors: Member[] = data.auth.as.members.items;
    const upcoming = meeting.schedules
        .sort((a, b) => moment(a.from).unix() - moment(b.from).unix())
        .filter((schedule) => schedule.happening !== 'undecided')
        .filter((schedule) => moment(schedule.to).isSameOrAfter());

    return (
        <div className="col-12">
            <h1>{meeting.title}</h1>
            <h6>{Translate.message('meetings.my.subtitle', 'Copak se pro tebe na letošních schůzkách peče?')}</h6>
            <h2 className="mt-5">{Translate.message('meetings.my.detail.regularity0', 'Kdy se potkáváme')}</h2>
            <div className="row">
                <div className="col-lg-8 col-12">
                    <p>
                        {Translate.message('meetings.my.detail.regularity1', 'Potkáváme se')}{' '}
                        <strong>{meetingFrequencyToText(meeting).toLowerCase()}</strong>
                        {Translate.message('meetings.my.detail.regularity2', ', tak nezapomeň a doraž za námi!')}
                    </p>
                    {upcoming.length === 0 ? (
                        <ListEndText>
                            {Translate.message(
                                'meetings.my.detail.notPlanned',
                                'Aktuálně není naplánovaná žádná schůzka. Nevíš, co se děje? Zkus se zeptat nějakého vedoucího.',
                                { gender: props.appState.memberDetails!.gender },
                            )}
                        </ListEndText>
                    ) : (
                        upcoming.slice(0, 4).map((schedule, i) =>
                            i === 0 ? (
                                <div className="block d-flex flex-row">
                                    {schedule.happening === 'yes' ? (
                                        <IconCheckbox size="3em" className="text-success flex-shrink-0" />
                                    ) : (
                                        <IconSquareX size="3em" className="text-danger flex-shrink-0" />
                                    )}
                                    <div className="flex-grow-1 ms-4">
                                        <h3 className="pt-2">
                                            {schedule.happening === 'yes'
                                                ? Translate.message(
                                                      'meetings.my.detail.next.yes',
                                                      'Na příští schůzce se sejdeme v {{date}}',
                                                      {
                                                          date: moment(schedule.from)
                                                              .twix(schedule.to)
                                                              .format(TWIX_FORMAT),
                                                      },
                                                  )
                                                : Translate.message(
                                                      'meetings.my.detail.next.no',
                                                      'Příští schůzka v {{date}} bohužel neproběhne',
                                                      {
                                                          date: moment(schedule.from)
                                                              .twix(schedule.to)
                                                              .format(TWIX_FORMAT),
                                                      },
                                                  )}
                                        </h3>
                                        {schedule.note && <div>{schedule.note}</div>}
                                    </div>
                                </div>
                            ) : (
                                <div className="py-2 px-5 d-flex flex-row">
                                    {schedule.happening === 'yes' ? (
                                        <IconCheckbox size="2em" className="text-success flex-shrink-0" />
                                    ) : (
                                        <IconSquareX size="2em" className="text-danger flex-shrink-0" />
                                    )}
                                    <div className="flex-grow-1 ms-4">
                                        <div className="pt-1">
                                            <strong>
                                                {schedule.happening === 'yes'
                                                    ? Translate.message(
                                                          'meetings.my.detail.later.yes',
                                                          'Schůzka v {{date}} bude',
                                                          {
                                                              date: moment(schedule.from)
                                                                  .twix(schedule.to)
                                                                  .format(TWIX_FORMAT),
                                                          },
                                                      )
                                                    : Translate.message(
                                                          'meetings.my.detail.later.no',
                                                          'Schůzka v {{date}} bohužel neproběhne',
                                                          {
                                                              date: moment(schedule.from)
                                                                  .twix(schedule.to)
                                                                  .format(TWIX_FORMAT),
                                                          },
                                                      )}
                                            </strong>
                                        </div>
                                        <div>{schedule.note && <div>{schedule.note}</div>}</div>
                                    </div>
                                </div>
                            ),
                        )
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-8 col-12">
                    <h2 className="mt-5">{Translate.message('meetings.my.detail.counselors', 'Vedoucí')}</h2>
                    <CounselorRenderer appState={props.appState} members={counselors} compact />
                </div>
                <div className="col-lg-4 col-12 ps-0 ps-lg-5">
                    <h2 className="mt-5 mb-4">{Translate.message('meetings.my.detail.kids', 'Spoluschůzkaři')}</h2>
                    {meeting.members
                        .filter((member) => !member.hasPublicInfo)
                        .map((member, i) => (
                            <div key={i}>
                                <MemberHead member={member} appState={props.appState} className="mb-2" />{' '}
                                {member.firstname} "{member.nickname}" {member.lastname}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}
