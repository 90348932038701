import { IconPlus } from '@tabler/icons';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Api } from '../../../../api/Api';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { PagedView } from '../../../utils/dataDisplayComponents/pagedView/PagedView';
import { Routes } from '../../Routes';
import { articleColumns } from './articlesColumns';
import { ArticlesEdit } from './ArticlesEdit';
import { ArticlesEditor } from './ArticlesEditor';
import { articleFilterPresets } from './articlesFilterPresets';
import { articleFilters } from './articlesFilters';

interface IArticlesPageProps {
    appState: AppState;
}

export function ArticlesPage(props: IArticlesPageProps) {
    return (
        <Switch>
            <Route path={Routes.articles(props.appState.memberId)} exact>
                <Redirect to={Routes.articlesList(props.appState.memberId)} />
            </Route>
            <Route path={Routes.articlesNew(props.appState.memberId)}>
                <ArticlesEditor appState={props.appState} />
            </Route>
            <Route path={Routes.articlesEdit(props.appState.memberId)}>
                <ArticlesEdit appState={props.appState} />
            </Route>
            <Route path={Routes.articlesList(props.appState.memberId)}>
                <div className="col-12">
                    <h1>{Translate.message('articles.all.title', 'Všechny lístečky')}</h1>
                    <h6>{Translate.message('articles.all.subtitle', 'Seznam všech lístečků')}</h6>
                    <PagedView
                        appState={props.appState}
                        query={Api.queries.article.detailedList(props.appState.activeMember.id)}
                        dataResolver={(data) => data.auth.as.articles}
                        columns={articleColumns(props.appState)}
                        defaultSortOrder={{
                            column: 'visibleFrom',
                            direction: 'DESC',
                        }}
                        filters={articleFilters}
                        presets={articleFilterPresets}
                        actions={[
                            {
                                icon: (
                                    <>
                                        <IconPlus /> {Translate.message('articles.newItem', 'Nový lísteček')}
                                    </>
                                ),
                                linkTo: Routes.articlesNew(props.appState.memberId),
                            },
                        ]}
                    />
                </div>
            </Route>
            <Route>
                <Redirect to={Routes.articles(props.appState.memberId)} />
            </Route>
        </Switch>
    );
}
