import { useQuery } from '@apollo/client';
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { Api } from '../../../../api/Api';
import { Member } from '../../../../api/entities/Member';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { ProfileEditor } from '../profile/ProfileEditor';

interface IMembersEditProps {
    appState: AppState;
}

export function MembersEdit(props: IMembersEditProps) {
    const {
        params: { detailId },
    } = useRouteMatch<{ detailId: string }>();
    const id = parseInt(detailId);

    // const memberDetailRights = hasRights(props.appState, Rights.MEMBER_DETAIL);

    const { loading, error, data, refetch } = useQuery(Api.queries.member.detail(props.appState.activeMember.id), {
        variables: {
            id,
        },
    });

    if (isNaN(id))
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const member = data.auth.as.memberById as Member;

    return (
        <div className="col-12">
            <h1>{Translate.message('memberEdit.title', 'Upravit detail člena')}</h1>
            <h6>{Translate.message('memberEdit.subtitle', 'Zde můžete upravit detaily člena')}</h6>
            <ProfileEditor appState={props.appState} member={member} refetch={() => refetch()} />
        </div>
    );
}
