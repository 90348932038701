import { useQuery } from '@apollo/client';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AppState } from '../../../AppState';
import { Api } from '../../../api/Api';
import { Translate } from '../../../utils/Translate';
import { Sidebar } from '../../Sidebar';
import { ErrorBoundary } from '../../utils/ErrorBoundary';
import { ErrorDisplay } from '../../utils/ErrorDisplay';
import { Loading } from '../../utils/Loading';
import { RedirectStoreBacklink } from '../../utils/RedirectStoreBaclink';
import { Routes } from '../Routes';
import { HomePage } from './HomePage';
import { MembershipRequest } from './MembershipRequest';
import { AdminPage } from './admin/AdminPage';
import { ArticlesPage } from './articles/ArticlesPage';
import { EventsPage } from './events/EventsPage';
import { MeetingsPage } from './meetings/MeetingsPage';
import { MembersPage } from './members/MembersPage';
import { ProfilePage } from './profile/ProfilePage';

interface IAuthAsProps {
    appState: AppState;
}

export function AuthAs(props: IAuthAsProps) {
    let { params } = useRouteMatch<{ memberId: string }>();
    const appState = props.appState;

    const memberId = parseInt(params.memberId);
    let validMemberId: number | false = false;

    if (memberId >= 0) {
        validMemberId =
            !isNaN(memberId) && appState.members && appState.members[memberId] && appState.members[memberId].id;
    } else {
        validMemberId = !isNaN(memberId) && -memberId;
    }

    const { loading, error, data } = useQuery(Api.queries.authAsDetails(validMemberId || 0));

    if (validMemberId === false) {
        return <RedirectStoreBacklink to={Routes.auth()} prefix={Routes.authAs(memberId)} />;
    }

    if (loading) return <Loading />;
    if (error) {
        console.error(error);
        return <ErrorDisplay error={Translate.message('error.unknownError')} />;
    }

    appState.memberDetails = data.auth.as.memberById;
    appState.rights = data.auth.as.rights;
    appState.memberId = memberId;

    if (!appState.memberDetails!.validMembership) {
        return (
            <div className="wrapper center-screen center-screen-wide spot-rh">
                <main>
                    <img className="mb-5 brand" src="/media/logo-dark.png" alt="Logo" />
                    <div className="block text-start p-5">
                        <MembershipRequest appState={appState} />
                    </div>
                </main>
            </div>
        );
    }

    return (
        <div className="wrapper main-page">
            <Sidebar {...{ appState }} />
            <main className="spot-lh d-flex flex-column flex-lg-row">
                <ErrorBoundary>
                    <Switch>
                        <Route path={Routes.events()}>
                            <EventsPage appState={appState} />
                        </Route>
                        <Route path={Routes.meetings()}>
                            <MeetingsPage appState={appState} />
                        </Route>
                        <Route path={Routes.articles()}>
                            <ArticlesPage appState={appState} />
                        </Route>
                        <Route path={Routes.members()}>
                            <MembersPage appState={appState} />
                        </Route>
                        <Route path={Routes.admin()}>
                            <AdminPage appState={appState} />
                        </Route>

                        <Route path={Routes.profile()}>
                            <ProfilePage appState={appState} />
                        </Route>
                        <Route path={Routes.home()} exact={true}>
                            <HomePage appState={appState} />
                        </Route>

                        <Route>
                            <Redirect to={Routes.home(memberId)} />
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </main>
        </div>
    );
}
