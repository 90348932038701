import { ApolloError } from '@apollo/client';
import { Translate } from './Translate';

export function gqlErrorToString(error: ApolloError | undefined) {
    if (!error) return undefined;

    if (error.networkError) {
        if (error.networkError.message.includes('400')) {
            return Translate.message('error.internalClientError', 'Došlo k neočekávané chybě! Tohle se nemělo stát...');
        } else {
            return Translate.message('error.networkError');
        }
    }

    if (error.graphQLErrors) {
        return error.graphQLErrors
            .map((error) =>
                Translate.message(
                    `error.${error.extensions?.messageId || 'unknownError'}`,
                    undefined,
                    error.extensions?.context,
                ),
            )
            .join(', ');
    }

    return Translate.message('error.unknownError');
}
