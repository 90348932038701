import { IconPlus } from '@tabler/icons';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { AppState } from '../../../AppState';
import { Api } from '../../../api/Api';
import { Rights } from '../../../api/Rights';
import { Member } from '../../../api/entities/Member';
import { Translate } from '../../../utils/Translate';
import { logout } from '../../../utils/logout';
import { RedirectPopBacklink, popBacklink } from '../../utils/RedirectPopBacklink';
import { RedirectStoreBacklink } from '../../utils/RedirectStoreBaclink';
import { RoleIcons } from '../../utils/RoleIcons';
import { SearchInput } from '../../utils/inputs/SearchInput';
import { Routes } from '../Routes';
import { Account } from './Account';
import { AuthAs } from './AuthAs';
import { Apply } from './application/Apply';

interface IAuthProps {
    appState: AppState;
}

export function Auth({ appState }: IAuthProps) {
    const location = useLocation();
    const history = useHistory();

    const urlReminder = location.pathname.replace(Routes.auth(), '');

    return (
        <Switch>
            <Route path={Routes.auth()} exact={true}>
                {appState.members.length === 1 ? (
                    <RedirectPopBacklink to="" prefix={Routes.authAs(0)} />
                ) : (
                    <div className="wrapper center-screen spot-rh">
                        <main>
                            <h1 className="h2 mb-4 fw-normal">
                                {Translate.message('auth.title', 'Za koho chcete pracovat?')}
                            </h1>
                            {appState.members.map((member, id) => (
                                <Link key={id} to={popBacklink('', Routes.authAs(id))} className="link-hidden d-inline">
                                    <div className="block tight text-start d-flex flex-row w-100 align-items-center">
                                        <img
                                            src={member.image}
                                            alt=""
                                            className="profile-picture"
                                            width="28"
                                            height="28"
                                        />
                                        <div className="flex-fill">
                                            {member.firstname} "{member.nickname}" {member.lastname}
                                        </div>
                                        <div>
                                            <RoleIcons member={member} />
                                        </div>
                                    </div>
                                </Link>
                            ))}
                            {appState.members.some((member) =>
                                member.roles.some((role) => role.rights.includes(Rights.ADMIN)),
                            ) && (
                                <SearchInput<Member>
                                    type="text"
                                    label={Translate.message('authAs.otherUser', 'Jiný uživatel')}
                                    appState={appState}
                                    query={Api.queries.member.search}
                                    overrideVariables={{
                                        asId: appState.members.find((member) =>
                                            member.roles.some((role) => role.rights.includes(Rights.ADMIN)),
                                        )!.id,
                                    }}
                                    resultResolver={(data) => data?.auth?.as?.memberById}
                                    searchResolver={(data) => data?.auth?.as?.members}
                                    itemDisplayJSX={(member) => (
                                        <>
                                            {member.firstname} "{member.nickname}" {member.lastname}
                                        </>
                                    )}
                                    itemDisplayText={(member) =>
                                        `${member.firstname} "${member.nickname}" ${member.lastname}`
                                    }
                                    onChange={(value) => {
                                        value && history.push(popBacklink('', Routes.authAs(-value)));
                                    }}
                                    className="mb-3"
                                    clearAfterInsert
                                />
                            )}
                            <Link to={Routes.apply()} className="link-hidden d-inline">
                                <div className="block tight text-start translucent">
                                    <IconPlus style={{ marginLeft: 4, marginRight: 'calc(0.5em + 4px)' }} size={20} />
                                    {Translate.message('sidebar.register', 'Nová přihláška')}
                                </div>
                            </Link>
                            <p className="mt-4 text-muted fw-lighter text-start">
                                <Link to={Routes.login()} onClick={() => logout(appState)}>
                                    {Translate.message('auth.logout', 'Odhlásit se')}
                                </Link>
                                <br />
                                <Link to={Routes.account()}>{Translate.message('auth.account', 'Nastavení účtu')}</Link>
                            </p>
                        </main>
                    </div>
                )}
            </Route>
            <Route path={Routes.apply()}>
                <Apply {...{ appState }} />
            </Route>
            <Route path={Routes.account()}>
                <Account {...{ appState }} />
            </Route>
            <Route path={Routes.authAs()}>
                {urlReminder.match(/^\/-?\d+($|\/)/) ? (
                    <AuthAs {...{ appState }} />
                ) : (
                    <RedirectStoreBacklink to={Routes.auth()} prefix={Routes.auth()} />
                )}
            </Route>
            <Route>
                <RedirectStoreBacklink to={Routes.auth()} prefix={Routes.auth()} />
            </Route>
        </Switch>
    );
}
