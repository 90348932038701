import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Meeting } from '../../../../api/entities/Meeting';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { MemberHead } from '../../../utils/MemberHead';
import { Spinner } from '../../../utils/Spinner';
import { Routes } from '../../Routes';
import { meetingFrequencyToText } from './meetingFrequencyToText';

interface IMeetingsCurrentPageProps {
    appState: AppState;
}

export function MeetingsCurrentPage(props: IMeetingsCurrentPageProps) {
    const { loading, error, data } = useQuery(
        gql`
            query MeetingDetail($memberId: Int!) {
                auth {
                    as(memberId: $memberId) {
                        meetings(filter: { current: true }) {
                            items {
                                id
                                current
                                title
                                seasonFrom
                                seasonTo
                                firstFrom
                                firstTo
                                frequency
                                frequencyEvery
                                members {
                                    firstname
                                    lastname
                                    nickname
                                    image
                                    id
                                    hasPublicInfo
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                memberId: props.appState.activeMember.id,
            },
        },
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const meetings: Meeting[] = data.auth.as.meetings.items;

    return (
        <div className="col-12">
            <h1>{Translate.message('meetings.current.title', 'Letošní schůzky')}</h1>
            <h6>{Translate.message('meetings.current.subtitle', 'Copak se pro tebe na letošních schůzkách peče?')}</h6>

            {meetings
                .sort((a, b) => moment(a.firstFrom).unix() - moment(b.firstFrom).unix())
                .map((meeting, i) => (
                    <div className="block" key={i}>
                        <h2 className="">{meeting.title}</h2>
                        <h6>
                            {Translate.message('meetings.my.detail.regularity1', 'Potkáváme se')}{' '}
                            <strong>{meetingFrequencyToText(meeting).toLowerCase()}</strong>
                        </h6>
                        <div className="cols-xl-3 cols-lg-2 cols-1 mt-4">
                            {meeting.members.map((member, i) => (
                                <div key={i} style={{ breakInside: 'avoid-column' }}>
                                    <MemberHead size={30} member={member} appState={props.appState} className="mb-2" />{' '}
                                    {member.hasPublicInfo ? (
                                        <strong>
                                            {member.firstname} "{member.nickname}" {member.lastname}
                                        </strong>
                                    ) : (
                                        <>
                                            {member.firstname} "{member.nickname}" {member.lastname}
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                        <Link
                            to={Routes.meetingsMyDetail(props.appState.memberId, meeting.id)}
                            className="d-block pt-3"
                        >
                            {Translate.message('meetings.current.readmore', 'Více informací')}
                        </Link>
                    </div>
                ))}
        </div>
    );
}
