import { Rights } from '../api/Rights';
import { Translate } from './Translate';
import { capitalize } from './capitalize';

export function rightToTranslation(right: string | Rights) {
    return Translate.message(
        `rights.${right
            .split('-')
            .map((word, i) => (i === 0 ? word : capitalize(word)))
            .join('')}`,
    );
}
