import { IconCaretLeft, IconCaretRight, IconHome } from '@tabler/icons';
import moment from 'moment';
import { useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { EventsCalendarView } from '../../../utils/dataDisplayComponents/events/EventsCalendarView';
import { FormInput } from '../../../utils/inputs/FormInput';
import { Routes } from '../../Routes';

interface IEventsCalendarProps {
    appState: AppState;
}

export function EventsCalendar(props: IEventsCalendarProps) {
    const [display, setDisplay] = useState({ events: true, meetings: true });
    const {
        params: { year, month },
    } = useRouteMatch<{ year: string; month: string }>();
    const yearNum = parseInt(year);
    const monthNum = parseInt(month) - 1;

    if (isNaN(yearNum) || isNaN(monthNum) || yearNum < 1000 || yearNum > 3000 || monthNum < 0 || monthNum > 11)
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    const current = moment({ year: yearNum, month: monthNum });
    const next = moment(current).add(1, 'month');
    const prev = moment(current).subtract(1, 'month');

    return (
        <div className="col-12">
            <h1>{Translate.message('events.calendar.title', 'Kalendář akcí')}</h1>
            <h6>{Translate.message('events.calendar.subtitle', 'Kalendář všech plánovaných i uplynulých akcí')}</h6>
            <div className="w-100 d-flex flex-column flex-lg-row mt-5">
                <div className="col-12 col-lg-7">
                    <div className="block">
                        <EventsCalendarView
                            date={current.toDate()}
                            appState={props.appState}
                            maxRows={3}
                            display={display}
                            controls={
                                <>
                                    <Link
                                        to={Routes.eventsCalendar(
                                            props.appState.memberId,
                                            prev.year(),
                                            prev.month() + 1,
                                        )}
                                    >
                                        <IconCaretLeft />
                                    </Link>
                                    <Link
                                        to={Routes.eventsCalendar(
                                            props.appState.memberId,
                                            moment().year(),
                                            moment().month() + 1,
                                        )}
                                    >
                                        <IconHome />
                                    </Link>
                                    <Link
                                        to={Routes.eventsCalendar(
                                            props.appState.memberId,
                                            next.year(),
                                            next.month() + 1,
                                        )}
                                    >
                                        <IconCaretRight />
                                    </Link>
                                </>
                            }
                        />
                    </div>
                </div>

                <div className="col-12 col-lg-5 ps-lg-5 mt-lg-4 mt-5">
                    <h3>{Translate.message('events.calendar.options', 'Nastavení')}</h3>
                    <FormInput
                        type="checkbox"
                        value={display.events}
                        onChange={(events) => setDisplay({ ...display, events })}
                        label={Translate.message('events.calendar.events', 'Akce')}
                    />
                    <FormInput
                        type="checkbox"
                        value={display.meetings}
                        onChange={(meetings) => setDisplay({ ...display, meetings })}
                        label={Translate.message('events.calendar.meetings', 'Schůzky')}
                    />
                </div>
            </div>
        </div>
    );
}
