import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from '../routes/Routes';

interface IRedirectPopBacklinkProps {
    prefix?: string;
    to: string;
}

export function RedirectPopBacklink(props: IRedirectPopBacklinkProps) {
    return (
        <>
            <Redirect to={popBacklink(props.to, props.prefix)} />
        </>
    );
}

export function popBacklink(to: string, prefix?: string) {
    const location = window.location;
    const query = new URLSearchParams(location.search);
    const backlink = query.get(Routes.backlinkArg());

    return (prefix || '') + (backlink ? decodeURIComponent(backlink) : to);
}
