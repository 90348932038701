import { GraphQLError } from 'graphql';
import * as React from 'react';
import { Link, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { forTime } from 'waitasecond';
import { Api } from '../../../api/Api';
import { Translate } from '../../../utils/Translate';
import { Await } from '../../utils/Await';
import { FormInput } from '../../utils/inputs/FormInput';
import { PasswordFeatures, validatePassword } from '../../utils/PasswordFeatures';
import { Routes } from '../Routes';

interface IRegisterProps {}

interface IRegisterState {
    state: 'READY' | 'SENDING' | 'SENT' | 'GQL_ERROR' | 'NET_ERROR' | 'REDIRECT';
    email: string;
    password: string;
    passwordValidation: string;
    error: null | readonly GraphQLError[];
}

class Register extends React.Component<RouteComponentProps<IRegisterProps>, IRegisterState> {
    state: IRegisterState = {
        state: 'READY',
        email: '',
        password: '',
        passwordValidation: '',
        error: null,
    };

    register() {
        this.setState({ state: 'SENDING' });
        Api.client
            .mutate({
                mutation: Api.mutations.register,
                variables: {
                    email: this.state.email,
                    password: this.state.password,
                    passwordValidation: this.state.passwordValidation,
                },
            })
            .then((data) => {
                if (data.errors) {
                    this.setState({ error: data.errors, state: 'GQL_ERROR' });
                    return;
                }
                this.setState({ state: 'SENT' });
            })
            .catch(() => {
                this.setState({ state: 'NET_ERROR' });
            });
    }

    render() {
        const formOK =
            validatePassword(this.state.password).length === 0 && this.state.password === this.state.passwordValidation;

        return (
            <div className="wrapper center-screen spot-rh">
                <main>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();

                            formOK && this.register();
                        }}
                    >
                        <img className="mb-5 brand" src={`${process.env.PUBLIC_URL}/media/logo-dark.png`} alt="Logo" />
                        {this.state.state === 'SENT' ? (
                            <>
                                <h1 className="h2 mb-3 fw-normal">
                                    {Translate.message('register.completed.title', 'Jste zaregistrovaní!')}
                                </h1>
                                <p>
                                    {Translate.message(
                                        'register.completed.subtitle',
                                        'Registraci dokončíte kliknutím na odkaz v emailu',
                                    )}
                                </p>
                                <p className="mt-4 text-muted fw-lighter text-start">
                                    <Translate.Component
                                        name="register.completed.login"
                                        alt="Za chvíli budete přesměrování na <<link&přihlašování>>"
                                        wrappers={{
                                            link: (children) => <Link to={Routes.login()}>{children}</Link>,
                                        }}
                                    />
                                </p>
                                <Await for={forTime(5000)}>
                                    <Redirect to={Routes.login()} />
                                </Await>
                            </>
                        ) : (
                            <>
                                <h1 className="h2 mb-3 fw-normal">
                                    {Translate.message('register.title', 'Ještě nemáte účet?')}
                                </h1>
                                <p>
                                    {Translate.message(
                                        'register.subtitle',
                                        'Zaregistrujte se a získejte přístup ke všemu, co se v Duze děje',
                                    )}
                                </p>
                                <FormInput
                                    type="email"
                                    className="mb-3"
                                    label={Translate.message('register.email', 'Emailová adresa')}
                                    value={this.state.email}
                                    onChange={(value) => this.setState({ email: value })}
                                    error={
                                        this.state.error
                                            ? this.state.error
                                                  .filter(
                                                      (err) =>
                                                          err.extensions &&
                                                          ['alreadyRegistered', 'emailFormat'].includes(
                                                              err.extensions.messageId,
                                                          ),
                                                  )
                                                  .map((err) =>
                                                      Translate.message(
                                                          'error.' + err.extensions!.messageId,
                                                          err.extensions!.context,
                                                      ),
                                                  )
                                                  .join(', ')
                                            : undefined
                                    }
                                />
                                <FormInput
                                    type="password"
                                    className="mb-2"
                                    label={Translate.message('register.password', 'Heslo')}
                                    value={this.state.password}
                                    onChange={(value) => this.setState({ password: value })}
                                    error={
                                        this.state.password.length === 0 ||
                                        validatePassword(this.state.password).length === 0
                                            ? undefined
                                            : Translate.message('error.passwordTooWeak')
                                    }
                                />
                                <PasswordFeatures password={this.state.password} />
                                <FormInput
                                    type="password"
                                    className="mb-3 mt-3"
                                    label={Translate.message('register.passwordValidation', 'Heslo znovu')}
                                    value={this.state.passwordValidation}
                                    onChange={(value) => this.setState({ passwordValidation: value })}
                                    error={
                                        this.state.passwordValidation.length === 0 ||
                                        this.state.password === this.state.passwordValidation
                                            ? undefined
                                            : Translate.message('error.passwordsDontMatch')
                                    }
                                />
                                {(() => {
                                    switch (this.state.state) {
                                        case 'SENDING':
                                            return (
                                                <button
                                                    className="mt-4 w-100 btn btn-lg btn-primary text-white"
                                                    type="button"
                                                    disabled
                                                >
                                                    <div
                                                        className="spinner-border spinner-border-sm text-light"
                                                        role="status"
                                                    >
                                                        <span className="visually-hidden">
                                                            {Translate.message('register.sending', 'Odesílání...')}
                                                        </span>
                                                    </div>
                                                </button>
                                            );
                                        default:
                                            return (
                                                <button
                                                    className="mt-4 w-100 btn btn-lg btn-primary text-white"
                                                    type="button"
                                                    onClick={() => formOK && this.register()}
                                                    disabled={!formOK}
                                                >
                                                    {Translate.message('register.send', 'Zaregistrovat se')}
                                                </button>
                                            );
                                    }
                                })()}
                                <p className="mt-4 text-muted fw-lighter text-start">
                                    <Translate.Component
                                        name="register.login"
                                        alt="Už máte účet? Můžete se <<link&přihlásit>>"
                                        wrappers={{
                                            link: (children, key) => (
                                                <Link key={key} to={Routes.login()}>
                                                    {children}
                                                </Link>
                                            ),
                                        }}
                                    />
                                </p>
                            </>
                        )}
                    </form>
                </main>
            </div>
        );
    }
}

export default withRouter(Register);
