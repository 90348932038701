import moment, { Moment } from 'moment';
import { Meeting, MeetingSchedule } from '../../../../api/entities/Meeting';

export function getRegularMeetings(meeting: Meeting, from: Moment, to: Moment) {
    let weeks = [];
    const firstWeek = moment(from).startOf('week');
    const lastWeek = moment(to).endOf('week');
    for (let current = firstWeek; current.isBefore(lastWeek); current.add(1, 'week')) {
        weeks.push(moment(current));
    }

    const regular: Moment[] = [];
    if (meeting.frequency !== 'irregular') {
        for (
            let current = moment(meeting.firstFrom);
            current.isSameOrBefore(meeting.seasonTo);
            current.add(
                meeting.frequencyEvery,
                { daily: 'day', weekly: 'week', monthly: 'month', yearly: 'year' }[meeting.frequency] as any,
            )
        ) {
            regular.push(moment(current));
        }
    }

    return weeks
        .map((week) => {
            const planed = meeting.schedules.filter((schedule) =>
                moment(schedule.from).isBetween(week, moment(week).add(1, 'week')),
            );

            if (planed.length > 0) {
                return planed;
            }

            let currentRegular = regular.filter((curr) => curr.isBetween(week, moment(week).add(1, 'week')));

            return currentRegular.map(
                (date) =>
                    ({
                        from: date.toISOString(),
                        to: moment(date)
                            .add(moment(meeting.firstTo).diff(meeting.firstFrom, 'seconds'), 'seconds')
                            .toISOString(),
                        happening: 'undecided',
                    } as MeetingSchedule),
            );
        })
        .reduce((prev, curr) => [...prev, ...curr])
        .filter((meeting) => moment(meeting.from).isBetween(from, to));
}
