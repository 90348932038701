import { IconEdit, IconEye, IconEyeOff } from '@tabler/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Event } from '../../../../api/entities/Event';
import { Member } from '../../../../api/entities/Member';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { TWIX_FORMAT } from '../../../../utils/twixFormat';
import { columnDefinition } from '../../../utils/dataDisplayComponents/pagedView/Page';
import { Routes } from '../../Routes';

export const articleColumns: (appState: AppState) => columnDefinition[] = (appState) => [
    {
        width: 0,
        getter: (row) =>
            moment().isBetween(row['visibleFrom'] as string, row['visibleTo'] as string) ? <IconEye /> : <IconEyeOff />,
    },
    // @translation articles.title: "Nadpis"
    { title: 'articles.title', width: 5, getter: (row) => row['title'], sortBy: 'title' },
    // @translation articles.visible: "Viditelný"
    {
        title: 'articles.visible',
        width: 4,
        getter: (row) => {
            const momentFrom = moment(row['visibleFrom'] as string);
            const momentTo = moment(row['visibleTo'] as string);
            if (
                momentFrom.diff(moment(momentFrom).startOf('day')) === 0 &&
                momentTo.diff(moment(momentTo).startOf('day')) === 0
            ) {
                // It is whole days
                return (
                    momentFrom
                        .twix(moment(momentTo).subtract(1, 'day'), true)
                        .format({ ...TWIX_FORMAT, monthFormat: 'M.' }) +
                    ' ' +
                    Translate.message('general.including', 'včetně')
                );
            }

            return momentFrom.twix(momentTo).format({ ...TWIX_FORMAT, monthFormat: 'M.' });
        },
        sortBy: 'visibleFrom',
    },
    // @translation articles.author: "Autor"
    { title: 'articles.author', width: 3, getter: (row) => (row['author'] as any as Member).nickname },
    // @translation articles.relatedEvent: "Související akce"
    {
        title: 'articles.relatedEvent',
        width: 3,
        getter: (row) => (row['relatedEvent'] ? (row['relatedEvent'] as any as Event).title : ''),
        sortBy: 'gender',
    },
    {
        width: 0,
        getter: (row) => (
            <div className="actions">
                {/* <Link to={Routes.articlesDetail(appState.memberId, row.id as number)}>
                    <IconPin />
                </Link> */}
                <Link to={Routes.articlesEdit(appState.memberId, row.id as number)}>
                    <IconEdit />
                </Link>
            </div>
        ),
    },
];
