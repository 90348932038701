import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { forTime } from 'waitasecond';
import { Api } from '../../../api/Api';
import { gqlErrorToString } from '../../../utils/gqlErrorToString';
import { Translate } from '../../../utils/Translate';
import { Await } from '../../utils/Await';
import { FormInput } from '../../utils/inputs/FormInput';
import { Routes } from '../Routes';

interface IResetProps {}

export function Reset(props: IResetProps) {
    const [email, setEmail] = React.useState('');
    const [reset, { loading, error, data }] = useMutation(Api.mutations.reset);

    return (
        <div className="wrapper center-screen spot-rh">
            <main>
                <form>
                    <h1 className="h2 mb-3 fw-normal">{Translate.message('reset.title', 'Zapomněli jste heslo?')}</h1>
                    <p>
                        {Translate.message(
                            'reset.subtitle',
                            'Zadejte svůj email a my vám pošleme odkaz na obnovu hesla',
                        )}
                    </p>
                    <FormInput
                        type="email"
                        value={email}
                        onChange={(data) => setEmail(data)}
                        disabled={loading || (data && !error)}
                        label={Translate.message('reset.email', 'Emailová adresa')}
                        error={gqlErrorToString(error)}
                    />
                    {(error || (!loading && !data)) && (
                        <button
                            className="mt-4 w-100 btn btn-lg btn-primary text-white"
                            type="button"
                            onClick={() => {
                                reset({
                                    variables: {
                                        email,
                                    },
                                });
                            }}
                        >
                            {Translate.message('reset.send', 'Obnovit heslo')}
                        </button>
                    )}
                    {loading && (
                        <button className="mt-4 w-100 btn btn-lg btn-primary text-white" type="button" disabled>
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden">
                                    {Translate.message('reset.sending', 'Odesílání...')}
                                </span>
                            </div>
                        </button>
                    )}
                    {data && !error && (
                        <>
                            <button disabled className="mt-4 w-100 btn btn-lg btn-success text-white" type="button">
                                {Translate.message('reset.sent', 'Odesláno')}
                            </button>
                            <Await for={forTime(5000)}>
                                <Redirect to={Routes.login()} />
                            </Await>
                        </>
                    )}
                    <p className="mt-4 text-muted fw-lighter text-start">
                        <Translate.Component
                            name="reset.login"
                            alt="Znáte svoje heslo? Můžete se <<link&přihlásit>>."
                            wrappers={{
                                link: (children, key) => (
                                    <Link to={Routes.login()} key={key}>
                                        {children}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </form>
            </main>
        </div>
    );
}
