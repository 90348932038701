import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Redirect } from 'react-router';
import { Api } from '../../../../../api/Api';
import { Event } from '../../../../../api/entities/Event';
import { AppState } from '../../../../../AppState';
import { Translate } from '../../../../../utils/Translate';
import { ConfirmButton } from '../../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { Spinner } from '../../../../utils/Spinner';
import { Routes } from '../../../Routes';

interface IOthersEditorProps {
    appState: AppState;
    event: Event;
}

export function OthersEditor(props: IOthersEditorProps) {
    const { event, appState } = props;
    const [del, { loading, error, data }] = useMutation(Api.mutations.event.delete, {
        variables: {
            memberId: appState.activeMember.id,
            eventId: event.id,
        },
    });

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    if (data) return <Redirect to={Routes.eventsList(appState.memberId)} />;

    return (
        <>
            <h3>{Translate.message('events.editor.remove.title', 'Odstranit akci')}</h3>
            <p className="text-danger">
                {Translate.message(
                    'events.editor.remove.desc',
                    'Kliknutím na tlačítko dojde k odstranění této akce. Tato operace je nevratná, prosím postupujte opatrně!',
                )}
            </p>
            <ConfirmButton
                label={Translate.message('events.editor.remove.button', 'Nenávratně odstranit akci')}
                onConfirm={() => del()}
            />
        </>
    );
}
