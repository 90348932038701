import { IconCamera } from '@tabler/icons';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { Api } from '../../api/Api';
import { AppState } from '../../AppState';
import { classNames } from '../../utils/classNames';

interface IImageUploadProps {
    appState: AppState;
    url?: string;
    onChange: (newUrl: string | null) => void;
    size: number;
    rounded?: true;
    className?: string;
    style?: React.CSSProperties;
    remove?: true;
}

interface IImageUploadState {
    state: 'READY' | 'UPLOADING';
    url: string | null;
}

export class ImageUpload extends React.Component<IImageUploadProps, IImageUploadState> {
    state: IImageUploadState = {
        state: 'READY',
        url: null,
    };

    private id: string;

    constructor(props: IImageUploadProps) {
        super(props);

        if (props.url) {
            this.state.url = props.url;
        }

        this.id = uuid();
    }

    upload = ({ target: { validity, files } }: React.ChangeEvent<HTMLInputElement>) => {
        const { appState, onChange } = this.props;

        if (validity.valid && files) {
            const file = files[0];

            this.setState({
                state: 'UPLOADING',
            });

            Api.client
                .mutate({
                    mutation: Api.mutations.uploadFile,
                    variables: {
                        memberId: appState.activeMember.id,
                        file,
                    },
                })
                .then((value) => {
                    if (value.errors) {
                        // TODO: handle errors
                    } else {
                        const newUrl = value.data!.auth.as.uploadFile.url;
                        this.setState({
                            url: newUrl,
                            state: 'READY',
                        });
                        onChange(newUrl);
                    }
                });
        }
    };

    render() {
        const { rounded, size, className } = this.props;
        const { url, state } = this.state;

        return (
            <div className={className} style={this.props.style}>
                <input
                    type="file"
                    onChange={this.upload}
                    id={this.id}
                    className="d-none"
                    disabled={state === 'UPLOADING'}
                />
                <label
                    htmlFor={this.id}
                    className={classNames('image-upload', rounded && 'rounded-circle')}
                    style={{ backgroundImage: `url(${url})`, width: size, height: size }}
                >
                    {state === 'READY' &&
                        (this.state.url && this.props.remove ? (
                            <>
                                <IconCamera
                                    color="white"
                                    size={50}
                                    style={{
                                        zIndex: 2,
                                        position: 'absolute',
                                        inset: 'calc(50% - 35px) 0 0 calc(50% - 25px)',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        inset: 'calc(50% + 20px) 0 0 0',
                                        color: 'white',
                                        textAlign: 'center',
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();

                                        this.setState({
                                            url: null,
                                        });
                                        this.props.onChange(null);
                                    }}
                                >
                                    Odebrat
                                </div>
                            </>
                        ) : (
                            <>
                                <IconCamera
                                    color="white"
                                    size={50}
                                    style={{
                                        zIndex: 2,
                                        position: 'absolute',
                                        inset: 'calc(50% - 25px)',
                                    }}
                                />
                            </>
                        ))}
                    {state === 'UPLOADING' && (
                        <div
                            className="spinner-border spinner-border text-white"
                            style={{
                                width: 50,
                                height: 50,
                                zIndex: 2,
                                position: 'absolute',
                                inset: 'calc(50% - 25px)',
                            }}
                        >
                            <span className="visually-hidden">Nahrávání...</span>
                        </div>
                    )}
                </label>
            </div>
        );
    }
}
