import { Member } from '../../api/entities/Member';
import { RightsIcon } from './RightsIcon';

interface IRightsIconsProps {
    member: Member;
}

export function RightsIcons(props: IRightsIconsProps) {
    const roles = props.member.roles;
    return (
        <>
            {roles.map((role, i) => (
                <RightsIcon role={role} key={i} />
            ))}
        </>
    );
}
