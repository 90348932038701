import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Redirect } from 'react-router';
import { Api } from '../../../../api/Api';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { Routes } from '../../Routes';
import { BasicInfoEditor } from './editors/BasicInfoEditor';

interface IEventsNewProps {
    appState: AppState;
}

export function EventsNew(props: IEventsNewProps) {
    const [create, { loading, error, data }] = useMutation(Api.mutations.event.create);

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;
    if (data) return <Redirect to={Routes.eventsDetail(props.appState.memberId, data.auth.as.createEvent.id)} />;

    return (
        <div className="col-12">
            <h1>{Translate.message('events.new.title', 'Nová akce')}</h1>
            <h6>{Translate.message('events.new.subtitle', 'Vytvoř novou akci a doplň k ní základní údaje')}</h6>

            <BasicInfoEditor
                appState={props.appState}
                onSubmit={(value) =>
                    create({
                        variables: {
                            memberId: props.appState.activeMember.id,
                            value,
                        },
                    })
                }
            />
        </div>
    );
}
