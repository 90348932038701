import moment from 'moment';
import { AppState } from '../../../AppState';
import { Api } from '../../../api/Api';
import { Translate } from '../../../utils/Translate';
import { ArticlesListView } from '../../utils/dataDisplayComponents/articles/ArticlesListView';
import { EventsCalendarView } from '../../utils/dataDisplayComponents/events/EventsCalendarView';
import { EventsListView } from '../../utils/dataDisplayComponents/events/EventsListView';

interface IHomePageProps {
    appState: AppState;
}

export function HomePage(props: IHomePageProps) {
    return (
        <>
            <div className="col-12 col-lg-7">
                <h1>
                    {Translate.message('home.title', 'Ahoj {{nickname}}!', {
                        nickname: props.appState.activeMember.nickname,
                    })}
                </h1>
                <h6>
                    {Translate.message('home.subtitle', 'Koukni se, co je tu nového a co tě čeká v příštích dnech.', {
                        gender: props.appState.activeMember.gender,
                    })}
                </h6>
                {/*
                <h2 className="section-heading">Nové příspěvky ve fóru</h2>
                <div className="block tight badged">
                    <div className="block-badge bg-success" />
                    <h3>Tábor 2021 - program</h3>
                    Kubula a další 4 lidi přidali nový příspěvek
                </div>
                <div className="block tight badged">
                    <div className="block-badge bg-danger" />
                    <h3>DŮLEŽITÉ! (škody, novinky, nákup materiálu, ...)</h3>
                    Lucysh přidala nový příspěvek
                </div>
                <div className="block tight badged">
                    <div className="block-badge bg-warning" />
                    <h3>Velikonoční akce</h3>
                    Sony a Pasťa přidali nový příspěvek
                </div>*/}
                <h2 className="section-heading">{Translate.message('home.articles', 'Nejčerstvější lístečky')}</h2>
                <ArticlesListView
                    query={Api.queries.article.list(props.appState.activeMember.id)}
                    dataResolver={(data) => data.auth.as.articles}
                    appState={props.appState}
                    filter={{
                        from: moment(new Date()).toISOString(),
                        to: moment(new Date()).toISOString(),
                    }}
                    error={Translate.message(
                        'home.noArticles',
                        'Aktuálně nemáme žádné důležité lístečky, ale nějaké se tady určitě brzy objeví',
                    )}
                />

                <h2 className="section-heading">{Translate.message('home.eventsThisWeek', 'Akce tento týden')}</h2>
                <EventsListView
                    query={Api.queries.event.list(props.appState.activeMember.id)}
                    dataResolver={(data) => data.auth.as.events}
                    appState={props.appState}
                    filter={{
                        from: moment(new Date()).startOf('week').toISOString(),
                        to: moment(new Date()).endOf('week').toISOString(),
                    }}
                    details
                    error={Translate.message(
                        'home.noEventsThisWeek',
                        'Tento týden nás nečekají žádné akce. Koukněte se ale na další akce, které se plánují!',
                    )}
                />
                <h3>{Translate.message('home.moreUpcoming', 'Další plánované akce')}</h3>
                <EventsListView
                    query={Api.queries.event.list(props.appState.activeMember.id)}
                    dataResolver={(data) => data.auth.as.events}
                    appState={props.appState}
                    filter={{
                        from: moment(new Date()).endOf('week').toISOString(),
                    }}
                    limit={3}
                    error={Translate.message(
                        'home.noEvents',
                        'Zatím nemáme naplánované žádné další akce, ale usilovně na tom pracujeme!',
                    )}
                />
            </div>
            <div className="col-12 col-lg-5 ps-lg-5 mt-lg-0 mt-5">
                <div className="block">
                    <EventsCalendarView date={new Date()} appState={props.appState} />
                </div>
            </div>
        </>
    );
}
