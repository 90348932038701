import { IconX } from '@tabler/icons';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppState } from '../../AppState';
import { Event } from '../../api/entities/Event';
import { Meeting } from '../../api/entities/Meeting';
import { Translate } from '../../utils/Translate';
import { capitalize } from '../../utils/capitalize';
import { classNames } from '../../utils/classNames';
import { incremental } from '../../utils/incremental';
import { TWIX_FORMAT } from '../../utils/twixFormat';
import { Routes } from '../routes/Routes';
import { getRegularMeetings } from '../routes/auth/meetings/getRegularMeetings';
import { ListEndText } from './ListEndText';

enum CalendarEventType {
    EVENT = 'event',
    MEETING = 'meeting',
}

enum CalendarEventAttendance {
    YES = 'yes',
    NO = 'no',
    UNDECIDED = 'undecided',
}

const colors = {
    event: 'primary',
    meeting: 'success',
};

type CalendarEvent = {
    from: string;
    to: string;
    allDay: boolean;
    title: string;
    subtitle?: string;
    type: CalendarEventType;
    id: number;
    attendance: CalendarEventAttendance;
};

interface ICalendarProps {
    date?: Date;
    events: Event[];
    meetings: Meeting[];
    maxRows?: number;
    appState: AppState;
    controls?: JSX.Element;
    display: {
        events: boolean;
        meetings: boolean;
    };
}

interface ICalendarState {
    openDay: Moment | null;
}

export class Calendar extends React.Component<ICalendarProps, ICalendarState> {
    state: ICalendarState = {
        openDay: null,
    };

    eventToClass(event: CalendarEvent) {
        if (event.attendance === 'yes') {
            return `text-white bg-${colors[event.type]}`;
        }
        if (event.attendance === 'no') {
            return `event-no text-${colors[event.type]} border-${colors[event.type]}`;
        }
        if (event.attendance === 'undecided') {
            return `event-undecided text-${colors[event.type]} border-${colors[event.type]}`;
        }
    }

    render() {
        const date = moment(this.props.date) || moment();
        const today = moment();
        const month = date.month();
        const year = date.year();

        const months = incremental(12).map((month) => capitalize(moment().month(month).format('MMMM')));
        const weekdays = incremental(7).map((weekday) => capitalize(moment().weekday(weekday).format('dddd')));

        const maxRows = this.props.maxRows || 2;

        const firstDateInCalendar = moment(date).startOf('month').startOf('week');

        let meetings = this.props.meetings
            .map((meeting) =>
                getRegularMeetings(meeting, firstDateInCalendar, moment(firstDateInCalendar).add(2, 'months')),
            )
            .reduce(
                (prev, schedules, i) => [
                    ...prev,
                    ...schedules.map((schedule) => ({
                        from: schedule.from,
                        to: schedule.to,
                        allDay: false,
                        id: this.props.meetings[i].id,
                        title:
                            this.props.meetings[i].title +
                            (schedule.happening === 'no' ? Translate.message('calendar.meeting.no', ' NENÍ!') : ''),
                        subtitle: schedule.note || undefined,
                        type: CalendarEventType.MEETING,
                        attendance: schedule.happening as any,
                    })),
                ],
                [] as CalendarEvent[],
            );
        let events: CalendarEvent[] = this.props.events.map((event) => ({
            from: event.from,
            to: event.to,
            allDay: event.allDay,
            id: event.id,
            title: event.title,
            subtitle: event.descriptionAfter || event.descriptionBefore,
            type: CalendarEventType.EVENT,
            attendance: (event.attendees.find((attendee) => attendee.member.id === this.props.appState.activeMember.id)
                ?.answer || 'undecided') as any,
        }));

        events.map((event) => ({
            ...event,
            title:
                event.title +
                (event.attendance === 'no' ? Translate.message('calendar.event.no', ' (odmítnuto)') : '') +
                (event.attendance === 'yes' ? Translate.message('calendar.event.yes', ' (přihlášeno)') : ''),
        }));

        const items: CalendarEvent[] = [
            ...(this.props.display.meetings ? meetings : []),
            ...(this.props.display.events ? events : []),
        ];

        let dayContainersRendered = [];
        let eventsRendered = [];

        /* Build the containers */
        let currentDate = moment(firstDateInCalendar);
        let dayOfWeek = 0;
        let row = 0;
        do {
            dayContainersRendered.push(
                <div
                    style={{ gridColumn: dayOfWeek + 1, gridRow: row + 2 }}
                    className={classNames(
                        'calendar-day link',
                        currentDate.month() !== month && 'disabled',
                        today.isSame(currentDate, 'day') && 'calendar-day-today',
                    )}
                    key={currentDate.unix()}
                    onClick={(
                        (date: Moment) => () =>
                            this.setState({ openDay: date })
                    )(moment(currentDate))}
                >
                    {currentDate.date()}
                </div>,
            );
            currentDate.add(1, 'day');
            dayOfWeek++;
            if (dayOfWeek >= 7) {
                dayOfWeek = 0;
                row++;
            }
        } while (currentDate.month() === month || dayOfWeek !== 0);

        /* Render the events */
        for (let week = 0; week < row; week++) {
            const rangeStart = moment(firstDateInCalendar).add(week, 'week');
            const rangeEnd = moment(firstDateInCalendar).add(week + 1, 'week');

            const eventsWithin = items
                .filter((event) => rangeEnd.isAfter(event.from) && rangeStart.isSameOrBefore(event.to))
                .sort((a, b) => moment(a.from).unix() - moment(b.from).unix())
                .sort(
                    (a, b) =>
                        moment(b.to).unix() - moment(b.from).unix() - (moment(a.to).unix() - moment(a.from).unix()),
                );

            let dayUsage = [0, 0, 0, 0, 0, 0, 0];

            const mapped = eventsWithin
                .map((event, key) => {
                    let from = moment(event.from).diff(rangeStart, 'days');
                    let to = from + moment(event.to).diff(rangeStart, 'days') - from + 1;

                    const overflowRight = to > 7;
                    const overflowLeft = from < 0;

                    to = Math.min(to, 7);
                    from = Math.max(from, 0);

                    let row = -1;
                    let found = false;
                    while (!found) {
                        row++;
                        found = true;
                        for (let i = from; i < to; i++) {
                            found = found && (dayUsage[i] & (1 << row)) === 0;
                        }
                    }
                    for (let i = from; i < to; i++) {
                        dayUsage[i] = dayUsage[i] | (1 << row);
                    }

                    return {
                        row,
                        from,
                        to,
                        element: (
                            <Link
                                to={
                                    {
                                        event: Routes.eventsDetail(this.props.appState.memberId, event.id),
                                        meeting: Routes.meetingsMyDetail(this.props.appState.memberId, event.id),
                                    }[event.type]
                                }
                                className={classNames(
                                    'calendar-event',
                                    this.eventToClass(event),
                                    overflowRight && 'calendar-event-overflow-r',
                                    overflowLeft && 'calendar-event-overflow-l',
                                )}
                                style={{
                                    gridColumnStart: from + 1,
                                    gridColumnEnd: to + 1,
                                    gridRow: week + 2,
                                    marginTop: 25 + 27 * row,
                                }}
                                key={week * 100 + key}
                                title={`${event.title} (${moment(event.from)
                                    .twix(event.to, event.allDay)
                                    .format(TWIX_FORMAT)})`}
                            >
                                {event.title}
                            </Link>
                        ),
                    };
                })
                .filter((event) => {
                    return !dayUsage
                        .map((usage, day) => {
                            if (event.from <= day && event.to > day && usage > 1 << maxRows) {
                                return !(event.row >= maxRows - 1);
                            }
                            return true;
                        })
                        .includes(false);
                });

            eventsRendered.push(...mapped.map((event) => event.element));

            /* Render overflows */
            for (let i = 0; i < 7; i++) {
                let usage = dayUsage[i] >> (maxRows - 1);
                const hasLastHiddenEvent = ((dayUsage[i] >> (maxRows - 1)) & 1) === 1;
                let numOverflow = 0;
                while (usage > 0) {
                    if (usage & 1) {
                        numOverflow++;
                    }
                    usage >>= 1;
                }
                if (numOverflow > 0 && !(hasLastHiddenEvent && numOverflow === 1)) {
                    eventsRendered.push(
                        <div
                            className={'calendar-overflow link'}
                            style={{
                                gridColumn: i + 1,
                                gridRow: week + 2,
                                marginTop: 25 + 27 * (maxRows - 1),
                            }}
                            key={week * 100 + i + 1000}
                            onClick={() => this.setState({ openDay: moment(rangeStart).add(i, 'days') })}
                        >
                            {Translate.message('calendar.nMore', `+{{num}} {{num?plural|další|další|dalších}}`, {
                                num: numOverflow,
                            })}
                        </div>,
                    );
                }
            }
        }

        const eventsForSelected = this.state.openDay
            ? items
                  .filter(
                      (event) =>
                          moment(this.state.openDay).endOf('day').isSameOrAfter(event.from) &&
                          moment(this.state.openDay).startOf('day').isSameOrBefore(event.to),
                  )
                  .sort((a, b) => moment(a.from).diff(b.from))
            : [];

        return (
            <>
                <div className="d-flex flex-row">
                    <div className="flex-grow-1">
                        {year}
                        <h2>{months[month]}</h2>
                    </div>
                    <div>{this.props.controls}</div>
                </div>
                <div
                    className={classNames('calendar', this.state.openDay && 'overlay-open')}
                    style={{
                        gridTemplateRows: `24px ${`${25 + maxRows * 27}px `.repeat(row)}`,
                    }}
                    onClick={() => this.state.openDay && this.setState({ openDay: null })}
                >
                    {weekdays.map((day, index) => (
                        <div style={{ gridColumn: index + 1, gridRow: 1 }} className="calendar-day-heading" key={index}>
                            <span style={{ display: 'inline-block' }}>{day.substr(0, 2)}</span>
                            <span style={{ display: 'inline-block' }}>{day.substr(2)}</span>
                        </div>
                    ))}
                    {dayContainersRendered}
                    {eventsRendered}
                </div>
                {this.state.openDay && (
                    <div className="day-detail">
                        <h3 className="mb-3 pe-4">
                            {this.state.openDay.format('LL')}{' '}
                            {moment().isSame(this.state.openDay, 'day') &&
                                Translate.message('calendar.today', '(dnes)')}
                        </h3>
                        {eventsForSelected.length === 0 && (
                            <ListEndText>
                                {Translate.message('calendar.noEvents', 'Na tento den nejsou naplánované žádné akce')}
                            </ListEndText>
                        )}
                        {eventsForSelected.map((event) => (
                            <Link
                                to={Routes.eventsDetail(this.props.appState.memberId, event.id)}
                                className={`calendar-event ${this.eventToClass(event)} event-detail`}
                            >
                                <div title={event.title}>{event.title}</div>
                                <div className="details">
                                    {moment(event.from).twix(event.to, event.allDay).format({ monthFormat: 'M.' })}
                                </div>
                            </Link>
                        ))}
                        <IconX className="close" onClick={() => this.setState({ openDay: null })} />
                    </div>
                )}
            </>
        );
    }
}
