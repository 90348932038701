import { Translate } from '../../../../utils/Translate';
import { filter } from '../../../utils/dataDisplayComponents/pagedView/Page';
import { FormInput } from '../../../utils/inputs/FormInput';

export const eventFilters: filter<any>[] = [
    {
        id: 'from',
        title: 'filter.from',
        createFilter: (value) => (value && value.length > 0 ? { from: value } : {}),
        toggle: (value, onChange) => (
            <>
                <FormInput
                    type="date"
                    label={Translate.message('events.from', 'Od')}
                    value={value}
                    onChange={onChange}
                />
            </>
        ),
    },
    {
        id: 'to',
        title: 'filter.to',
        createFilter: (value) => (value && value.length > 0 ? { to: value } : {}),
        toggle: (value, onChange) => (
            <>
                <FormInput type="date" label={Translate.message('events.to', 'Do')} value={value} onChange={onChange} />
            </>
        ),
    },
];
