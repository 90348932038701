import { useMutation } from '@apollo/client';
import { Api } from '../../../../../api/Api';
import { Translate } from '../../../../../utils/Translate';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { Spinner } from '../../../../utils/Spinner';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { date, nonempty, required } from '../../../../utils/inputs/inputValidators/validators';
import { IStepProps } from '../Apply';

export function StepBirth(props: IStepProps) {
    const [create, { loading, error, data }] = useMutation(Api.mutations.member.createAnonymous);

    if (loading) return <Spinner />;
    if (error)
        return (
            <>
                <ErrorDisplay error={error} />
                <div className="mt-5 d-flex flex-row align-items-center">
                    <div className="d-inline-block text-start">
                        <span className="link blue" onClick={() => props.prev()}>
                            Zpět
                        </span>
                    </div>
                    <div className="d-inline-block text-end  flex-grow-1"></div>
                </div>
            </>
        );

    if (data) {
        props.setMemberId(data.auth.createMember.id);
        props.setStep(props.step + 1);
        return <Spinner />;
    }

    return (
        <Form
            defaultValues={{}}
            onSubmit={(values) => {
                const data = { ...props.values, ...values, isEmployee: false, otherInfo: '', motd: '', birchLeaf: 0 };
                create({
                    variables: {
                        value: data,
                    },
                });
            }}
            validators={(data) => ({
                address: [required, nonempty],
                birthdate: [required, date],
            })}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <h2 className="mb-4">
                        <Translate.Component name="application.birth.title" alt="Ještě něco o tobě" />
                    </h2>
                    <div className="mb-5">
                        <p className="mb-4">
                            <Translate.Component
                                name="application.birth.subtitle"
                                alt="Abychom měli z krku všechny povinnosti, potřebujeme od tebe vědět <<strong&ještě pár věcí>>"
                            />
                        </p>
                        <FormInput
                            {...inputProps('address')}
                            type="textarea"
                            label={Translate.message('application.birth.address', 'Bydliště')}
                        />
                        <FormInput
                            {...inputProps('birthdate')}
                            type="date"
                            label={Translate.message('application.birth.birthdate', 'Datum narození')}
                        />
                    </div>
                    <div className="mt-5 d-flex flex-row align-items-center">
                        <div className="d-inline-block text-start">
                            <span className="link blue" onClick={() => props.prev()}>
                                {Translate.message('application.prev', 'Zpět')}
                            </span>
                        </div>
                        <div className="d-inline-block text-end  flex-grow-1">
                            <button {...buttonProps}>{Translate.message('application.next', 'Další')}</button>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
}
