import { useMutation, useQuery } from '@apollo/client';
import { IconX } from '@tabler/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Event } from '../../../../api/entities/Event';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { Tabs } from '../../../utils/Tabs';
import { EventTimeline } from '../../../utils/eventTasks/EventTimeline';
import { Routes } from '../../Routes';
import { AfterEditor } from './editors/AfterEditor';
import { BasicInfoEditor } from './editors/BasicInfoEditor';
import { OthersEditor } from './editors/OthersEditor';
import { RegistrationEditor } from './editors/RegistrationEditor';
import { ReportsEditor } from './editors/ReportsEditor';

export interface IEventsEditorProps {
    appState: AppState;
}

export function EventsEditor(props: IEventsEditorProps) {
    const {
        params: { detailId },
    } = useRouteMatch<{ detailId: string }>();
    const id = parseInt(detailId);

    const query = useQuery(Api.queries.event.detail(props.appState.activeMember.id), {
        variables: {
            id,
        },
    });
    const [edit, editState] = useMutation(Api.mutations.event.modify, { onCompleted: () => query.refetch() });

    if (isNaN(id))
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    const loading = query.loading || editState.loading;
    const error = query.error || editState.error;

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    const event = query.data.auth.as.eventById as Event;

    const editorProps = {
        ...props,
        onSubmit: (value: any) =>
            edit({
                variables: {
                    memberId: props.appState.activeMember.id,
                    eventId: event.id,
                    value,
                },
            }),
        event,
    };

    return (
        <div className="col-12">
            <div className="d-flex flex-row align-items-start">
                <div className="flex-grow-1">
                    <h1>{Translate.message('events.edit.title', 'Upravit akci')}</h1>
                    <h6>{Translate.message('events.edit.subtitle', 'Uprav a doplň všechny údaje o akci')}</h6>
                </div>
                <div>
                    <Link to={Routes.eventsDetail(props.appState.memberId, event.id)} className="btn btn-danger mt-3">
                        <IconX /> {Translate.message('events.edit.close', 'Ukončit úpravy')}
                    </Link>
                </div>
            </div>

            <EventTimeline appState={props.appState} event={event} />

            <Tabs
                tabs={{
                    basic: {
                        title: Translate.message('events.edit.tabs.basic', 'Základní informace'),
                        content: <BasicInfoEditor {...editorProps} />,
                    },
                    registration: {
                        title: Translate.message('events.edit.tabs.registration', 'Přihlašování'),
                        content: <RegistrationEditor {...editorProps} />,
                    },
                    after: {
                        title: Translate.message('events.edit.tabs.after', 'Po akci'),
                        content: <AfterEditor {...editorProps} />,
                    },
                    reports: {
                        title: Translate.message('events.edit.tabs.reports', 'Kroniky'),
                        content: <ReportsEditor {...editorProps} onSave={() => query.refetch()} />,
                    },
                    management: {
                        title: Translate.message('events.edit.tabs.management', 'Správa akce'),
                        content: <OthersEditor {...editorProps} />,
                    },
                }}
                default="basic"
            />
        </div>
    );
}
