import { validator } from '.';
import { Rights } from '../../../../api/Rights';
import { AppState } from '../../../../AppState';
import { hasRights } from '../../../../utils/hasRights';
import { validateEmail } from './validateEmail';

export const required: validator = (value) => {
    const objectValue = value;
    if (objectValue === null || objectValue === undefined) {
        return { messageId: 'validationRequired', context: {} };
    }
};

export const forbidden: validator = (value) => {
    const objectValue = value;
    if (!(objectValue === null || objectValue === undefined)) {
        return { messageId: 'validationNull', context: {} };
    }
};

export const nonempty: validator = (value) => {
    if (value === null || value === undefined || value === '') return;

    if (value.length === 0) {
        return { messageId: 'validationNonempty', context: {} };
    }
};

export const date: validator = (value) => {
    if (value === null || value === undefined || value === '') return;

    if (!Date.parse(value)) {
        return { messageId: 'validationFormat', context: { format: 'date' } };
    }
};

export const email: validator = (value) => {
    if (value === null || value === undefined || value === '') return;

    if (!validateEmail(value)) {
        return { messageId: 'validationFormat', context: { format: 'email' } };
    }
};

export const phone: validator = (value) => {
    if (value === null || value === undefined || value === '') return;

    // Only accepts Czech numbers right now
    const expr = /(\+\s*4\s*2\s*0|\s*0\s*0\s*4\s*2\s*0)?\s*(([0-9]\s*){9})/;

    if (!expr.test(String(value))) {
        return { messageId: 'validationFormat', context: { format: 'phone' } };
    }
};

export const integer: validator = (value) => {
    if (value === null || value === undefined || value === '') return;

    if (parseInt(value) % 1 !== 0) {
        return { messageId: 'validationFormat', context: { format: 'integer' } };
    }
};

export const withRights: (appState: AppState, right: Rights) => validator = (appState, right) => (value) => {
    if (value === null || value === undefined || value === '') return;

    if (!hasRights(appState, right)) {
        return { messageId: 'missingModifyRights', context: { right } };
    }
};

export const inRange: (from: number, to: number, integer: boolean) => validator = (from, to, integer) => (value) => {
    if (value === null || value === undefined || value === '') return;

    const numeric = parseInt(value);

    if (numeric < from || numeric > to || (integer && numeric % 1 !== 0)) {
        return { messageId: 'validationRange', context: { from, to } };
    }
};

export const requiredIf: (condition: any) => validator = (condition) => (value) => {
    const objectValue = value;
    if (condition && (objectValue === null || objectValue === undefined)) {
        return { messageId: 'validationRequired', context: {} };
    }
};
