import moment from 'moment';
import { Translate } from '../../../../utils/Translate';
import { filterPreset } from '../../../utils/dataDisplayComponents/pagedView/Page';

export const articleFilterPresets: filterPreset[] = [
    {
        title: Translate.message('article.presets.visible', 'Aktuální lístečky'),
        value: {
            visibleFrom: moment().toISOString(),
            visibleTo: moment().toISOString(),
        },
    },
];
