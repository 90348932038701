import * as React from 'react';
import { Api } from '../../../../api/Api';
import { Translate } from '../../../../utils/Translate';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { LiveInput } from '../../../utils/inputs/LiveInput';
import { IProfileEditorProps } from './ProfileEditor';

export function RepresentativesEditor(props: IProfileEditorProps) {
    const { appState, member } = props;

    return (
        <>
            {member.representatives.map((rep, i) => (
                <span key={i}>
                    <h3 key={'_' + rep.id}>
                        {Translate.message('profilePage.representativeNum', '{{num}}{{num?order|.}} zástupce', {
                            num: i + 1,
                        })}
                    </h3>
                    <div key={rep.id} className="mt-2">
                        <div className="columns">
                            <div className="col-12 col-md-6 d-inline-block align-top">
                                <LiveInput
                                    type="text"
                                    initialValue={rep.firstname}
                                    label={Translate.message('profilePage.representative.firstname', 'Krestní jméno')}
                                    mutation={Api.mutations.member.representative.update(
                                        appState.activeMember.id,
                                        rep.id,
                                        'firstname',
                                        'String',
                                    )}
                                />
                                <LiveInput
                                    type="email"
                                    initialValue={rep.email}
                                    label={Translate.message('profilePage.representative.email', 'Email')}
                                    mutation={Api.mutations.member.representative.update(
                                        appState.activeMember.id,
                                        rep.id,
                                        'email',
                                        'String',
                                    )}
                                />
                            </div>
                            <div className="col-12 col-md-6 d-inline-block align-top">
                                <LiveInput
                                    type="text"
                                    initialValue={rep.lastname}
                                    label={Translate.message('profilePage.representative.lastname', 'Příjmení')}
                                    mutation={Api.mutations.member.representative.update(
                                        appState.activeMember.id,
                                        rep.id,
                                        'lastname',
                                        'String',
                                    )}
                                />
                                <LiveInput
                                    type="text"
                                    initialValue={rep.phone}
                                    label={Translate.message('profilePage.representative.phone', 'Telefon')}
                                    mutation={Api.mutations.member.representative.update(
                                        appState.activeMember.id,
                                        rep.id,
                                        'phone',
                                        'String',
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <ConfirmButton
                        label={Translate.message('general.remove', 'Remove')}
                        className="mt-4 mb-5"
                        onConfirm={() => {
                            Api.client
                                .mutate({
                                    mutation: Api.mutations.member.representative.delete(
                                        appState.activeMember.id,
                                        rep.id,
                                    ),
                                })
                                .then((data) => props.refetch())
                                .catch((exception) => console.error(exception));
                        }}
                    />
                </span>
            ))}
            <button
                className="btn btn-primary d-block"
                type="button"
                onClick={() => {
                    Api.client
                        .mutate({
                            mutation: Api.mutations.member.representative.add(appState.activeMember.id, member.id),
                        })
                        .then((data) => props.refetch())
                        .catch((exception) => console.error(exception));
                }}
            >
                {Translate.message('general.add', 'Add')}
            </button>
        </>
    );
}
