import { gql, useMutation, useQuery } from '@apollo/client';
import { IconAlertTriangle } from '@tabler/icons';
import { useState } from 'react';
import { AppState } from '../../../../AppState';
import { Rights } from '../../../../api/Rights';
import { Service } from '../../../../api/entities/Service';
import { Translate } from '../../../../utils/Translate';
import { capitalize } from '../../../../utils/capitalize';
import { rightToTranslation } from '../../../../utils/rightToTranslation';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { FormInput } from '../../../utils/inputs/FormInput';

interface ITokenProps {
    appState: AppState;
}

export function TokensPage(props: ITokenProps) {
    const { error, loading, data, refetch } = useQuery(
        gql`
            query Tokens($memberId: Int) {
                auth {
                    as(memberId: $memberId) {
                        services {
                            id
                            title
                            owner {
                                id
                                firstname
                                lastname
                                nickname
                            }
                            rights
                            token
                        }
                    }
                }
            }
        `,
        {
            variables: {
                memberId: props.appState.activeMember.id,
            },
        },
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    return (
        <div className="col-12">
            <h1>API tokens</h1>
            <h6>You can create and manage API tokens for external services.</h6>

            <div className="block">
                <table className="table table-striped mb-0">
                    <thead>
                        <tr>
                            <th scope="col">Service name</th>
                            <th scope="col">Owner</th>
                            <th scope="col">Rights</th>
                            <th scope="col">Token</th>
                            <th scope="col" style={{ width: 0 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.auth.as.services.map((service: Service) => (
                            <tr>
                                <td style={{ verticalAlign: 'middle' }}>
                                    <strong>{service.title}</strong>
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    {service.owner.firstname} "{service.owner.nickname}" {service.owner.lastname}
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    {service.rights
                                        .map((right) => `${rightToTranslation(right)} (${right})`)
                                        .join(', ')}
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    {service.token ? (
                                        <code>{service.token}</code>
                                    ) : (
                                        <span style={{ opacity: 0.5 }}>Token is visible to owner only</span>
                                    )}
                                </td>
                                <td>
                                    <DeleteService
                                        service={service}
                                        appState={props.appState}
                                        onDelete={() => refetch()}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="block">
                <h3>Create a new service</h3>
                <CreateService appState={props.appState} onCreate={() => refetch()} />
            </div>
        </div>
    );
}

function DeleteService(props: { appState: AppState; service: Service; onDelete?: () => void }) {
    const [remove, { loading, error }] = useMutation(gql`
        mutation DeleteToken($memberId: Int!, $id: Int!) {
            auth {
                as(memberId: $memberId) {
                    deleteService(id: $id)
                }
            }
        }
    `);

    if (error) return <ErrorDisplay error={error} />;

    return (
        <ConfirmButton
            label={'Delete'}
            onConfirm={async () => {
                await remove({
                    variables: {
                        memberId: props.appState.activeMember.id,
                        id: props.service.id,
                    },
                });
                props.onDelete && props.onDelete();
            }}
            disabled={loading}
        />
    );
}

function CreateService(props: { appState: AppState; onCreate?: () => void }) {
    const [rights, setRights] = useState<string[]>([]);
    const [title, setTitle] = useState('New service');

    const [create, { loading, error }] = useMutation(gql`
        mutation TokenCreate($memberId: Int!, $title: String!, $rights: [String]!) {
            auth {
                as(memberId: $memberId) {
                    createService(title: $title, rights: $rights) {
                        id
                    }
                }
            }
        }
    `);

    if (error) return <ErrorDisplay error={error} />;

    return (
        <div>
            <FormInput type="text" value={title} onChange={(value) => setTitle(value)} label={'Service name'} />

            <h3 className="mt-5">Rights</h3>
            <div style={{ columns: 3 }}>
                {Object.entries(
                    Object.values(Rights).reduce(
                        (prev, right) => ({
                            ...prev,
                            [right.split('.')[0]]: [...(prev[right.split('.')[0]] || []), right],
                        }),
                        {} as { [key: string]: string[] },
                    ),
                ).map(([group, groupRights], j) => (
                    <div style={{ breakInside: 'avoid-column' }} key={j}>
                        <h4 className="mt-3 d-inline-block">
                            {Translate.message(
                                `rights.group.${group
                                    .split('-')
                                    .map((word, i) => (i === 0 ? word : capitalize(word)))
                                    .join('')}`,
                            )}
                        </h4>
                        {groupRights.map((right, i) => (
                            <div style={{ breakInside: 'avoid-column', marginTop: -8 }} key={i}>
                                <FormInput
                                    disabled={rights.includes('admin') && right !== 'admin'}
                                    className="d-inline-block"
                                    type="checkbox"
                                    value={rights.includes(right) || rights.includes('admin')}
                                    label={
                                        <div
                                            style={{
                                                marginTop: -10,
                                                fontWeight: right === 'admin' ? 'bold' : 'unset',
                                            }}
                                            className={right === 'admin' ? 'text-danger' : ''}
                                        >
                                            {rightToTranslation(right)}
                                            {(right === 'admin' ||
                                                group === 'role' ||
                                                right === 'generic.allow-html') && (
                                                <>
                                                    {' '}
                                                    <IconAlertTriangle />
                                                </>
                                            )}
                                            <br />
                                            <span style={{ opacity: 0.5 }}>{right}</span>
                                        </div>
                                    }
                                    onChange={(value) => {
                                        let temp = rights.filter((r) => r !== right);
                                        if (value) {
                                            temp.push(right);
                                        }
                                        setRights(temp);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <ConfirmButton
                label={
                    <>
                        Create a service
                        <IconAlertTriangle />
                    </>
                }
                onConfirm={async () => {
                    await create({
                        variables: {
                            memberId: props.appState.activeMember.id,
                            title,
                            rights,
                        },
                    });
                    props.onCreate && props.onCreate();
                }}
                disabled={loading}
                color="warning"
            />
        </div>
    );
}
