export function paragraphs(text: string | null | undefined | number, noSpace?: boolean) {
    if (!text) {
        return <></>;
    }
    const string = text.toString();
    const split = string.split('\n');
    return split.map((row, i) => (
        <p key={i} className={noSpace && i < split.length - 1 ? 'm-0' : ''}>
            {row}
        </p>
    ));
}
