import * as React from 'react';
import { classNames } from '../../utils/classNames';

interface ISpinnerProps {
    light?: true;
}

export function Spinner(props: ISpinnerProps) {
    return (
        <div className="spinner">
            <div
                className={classNames(
                    'spinner-border spinner-border mx-auto',
                    props.light ? 'text-light' : 'text-dark',
                )}
                style={{ width: '3rem', height: '3rem' }}
            >
                <span className="visually-hidden">Načítání...</span>
            </div>
        </div>
    );
}
