import * as React from 'react';
import { Translate } from '../../../../../utils/Translate';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { nonempty, required } from '../../../../utils/inputs/inputValidators/validators';
import { IStepProps } from '../Apply';

export function StepSchool(props: IStepProps) {
    return (
        <Form
            defaultValues={{}}
            onSubmit={(values) => props.next({ ...values, isStudent: true })}
            validators={(data) => ({
                school: [required, nonempty],
                schoolClass: [required, nonempty],
            })}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <h2 className="mb-4">
                        <Translate.Component name="application.school.title" alt="Kam chodíš do školy?" />
                    </h2>
                    <div className="mb-5">
                        <p className="mb-4">
                            <Translate.Component
                                name="application.school.subtitle"
                                alt="Aby se nám s tebou lépe pracovalo, hodilo by se nám vědět, <<strong&kam chodíš do školy>>. Pokud nejsi student, můžeš <<link&pokračovat dál>>."
                                wrappers={{
                                    link: (children, key) => (
                                        <span
                                            key={key}
                                            className="link blue"
                                            onClick={() => props.next({ isStudent: false })}
                                        >
                                            {children}
                                        </span>
                                    ),
                                }}
                            />
                        </p>
                        <FormInput
                            {...inputProps('school')}
                            type="text"
                            label={Translate.message('application.school.school', 'Škola')}
                        />
                        <FormInput
                            {...inputProps('schoolClass')}
                            type="text"
                            label={Translate.message('application.school.schoolClass', 'Třída')}
                        />
                    </div>
                    <div className="mt-5 d-flex flex-row align-items-center">
                        <div className="d-inline-block text-start">
                            <span className="link blue" onClick={() => props.prev()}>
                                {Translate.message('application.prev', 'Zpět')}
                            </span>
                        </div>
                        <div className="d-inline-block text-end flex-grow-1">
                            <span
                                className="link blue me-3"
                                onClick={() => {
                                    props.setValues({ isStudent: false });
                                    props.setStep(props.step + 1);
                                }}
                            >
                                {Translate.message('application.school.notAStudent', 'Nejsem student')}
                            </span>
                            <button {...buttonProps}>{Translate.message('application.next', 'Další')}</button>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
}
