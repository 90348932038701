import { MutationOptions } from '@apollo/client';
import { startAuthentication } from '@simplewebauthn/browser';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from '../../../AppState';
import { Api } from '../../../api/Api';
import { Translate } from '../../../utils/Translate';
import { classNames } from '../../../utils/classNames';
import { Routes } from '../Routes';

interface ILoginProps {
    appState: AppState;
}

interface ILoginState {
    status: 'READY' | 'REQUESTING' | 'LOGGING' | 'OK' | 'ERROR';
    message: string;
    email: string;
    password: string;
    rememberedUsers: string[];
    useDifferentUser?: boolean;
}

export const LS_REMEMBERED_USERS = AppState.LS_PREFIX + 'webauthnUsers';

class Login extends React.Component<RouteComponentProps<{}> & ILoginProps, ILoginState> {
    state: ILoginState = {
        status: 'READY',
        message: '',
        email: '',
        password: '',
        rememberedUsers: JSON.parse(localStorage.getItem(LS_REMEMBERED_USERS) || '[]'),
        useDifferentUser: false,
    };

    async sendApiRequest(options: MutationOptions): Promise<Object | null> {
        return new Promise<Object | null>((resolve) =>
            Api.client
                .mutate(options)
                .catch((error) => {
                    this.setState({
                        status: 'ERROR',
                        message: Translate.message('error.networkError'),
                    });
                    resolve(null);
                })
                .then(async (result) => {
                    if (!result) {
                        resolve(null);
                        return;
                    }
                    if (result.errors && result.errors.length > 0) {
                        const error = Translate.message(
                            `error.${result.errors[0].extensions?.messageId || 'unknownError'}`,
                            result.errors[0].message,
                            result.errors[0].extensions?.context,
                        );
                        this.setState({
                            status: 'ERROR',
                            message: error,
                        });

                        resolve(null);
                        return;
                    } else {
                        resolve(result);
                        return;
                    }
                }),
        );
    }

    async login() {
        this.setState({
            status: 'LOGGING',
            message: '',
        });

        const result = await this.sendApiRequest({
            mutation: Api.mutations.login,
            variables: {
                email: this.state.email,
                password: this.state.password,
            },
        });
        if (result) {
            this.setState(
                {
                    status: 'OK',
                    message: '',
                },
                async () => {
                    await Api.client.clearStore();
                    await this.props.appState.checkAuthorization();
                },
            );
        }
    }

    async webauthn(email: string) {
        this.setState({
            status: 'REQUESTING',
            message: '',
        });

        const options = (
            (await this.sendApiRequest({
                mutation: Api.mutations.generateLoginOptions,
                variables: {
                    email,
                },
            })) as any
        )?.data?.generateLoginOptions;
        if (!options) return;

        console.log(options);

        let asseResp;
        try {
            // Pass the options to the authenticator and wait for a response
            asseResp = await startAuthentication(JSON.parse(options));
        } catch (error) {
            this.setState({
                status: 'ERROR',
                message: Translate.message('error.webauthnError', 'Při ověření došlo k chybě.'),
            });
            return;
        }

        const result = await this.sendApiRequest({
            mutation: Api.mutations.verifyLoginResponse,
            variables: {
                email,
                response: JSON.stringify(asseResp),
            },
        });

        if (result) {
            this.setState(
                {
                    status: 'OK',
                    message: '',
                },
                async () => {
                    await Api.client.clearStore();
                    await this.props.appState.checkAuthorization();
                },
            );
        }
    }

    render() {
        return (
            <div className="wrapper center-screen spot-rh">
                <main>
                    <form
                        onSubmit={(event) => {
                            console.log('submit');
                            event.preventDefault();
                            this.login();
                        }}
                    >
                        <img className="mb-5 brand" src={`${process.env.PUBLIC_URL}/media/logo-dark.png`} alt="Logo" />
                        {this.state.rememberedUsers.length > 0 && !this.state.useDifferentUser ? (
                            <div>
                                <h3>{Translate.message('login.quick.title', 'Vítej zpátky!')}</h3>
                                {this.state.rememberedUsers.length > 1 ? (
                                    <>
                                        {this.state.rememberedUsers.map((email, key) => (
                                            <button
                                                key={key}
                                                className="mt-4 w-100 btn btn-lg btn-primary text-white"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    this.webauthn(email);
                                                }}
                                            >
                                                {email}
                                            </button>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <h4>{this.state.rememberedUsers[0]}</h4>
                                        <button
                                            className="mt-4 w-100 btn btn-lg btn-primary text-white"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.webauthn(this.state.rememberedUsers[0]);
                                            }}
                                        >
                                            {Translate.message('login.submit', 'Přihlásit se')}
                                        </button>
                                    </>
                                )}
                                {this.state.message.length > 0 && (
                                    <div className="invalid-feedback d-block">{this.state.message}</div>
                                )}

                                <button
                                    className="mt-4 w-100 btn btn-light text-dark"
                                    onClick={() => this.setState({ useDifferentUser: true })}
                                >
                                    {Translate.message('login.quick.switch', 'Použít jiný účet')}
                                </button>
                            </div>
                        ) : (
                            <>
                                <div className="form-floating">
                                    <input
                                        type="email"
                                        className={classNames(
                                            'form-control',
                                            this.state.status === 'ERROR' && 'is-invalid',
                                        )}
                                        id="floatingInput"
                                        placeholder={Translate.message('login.email', 'Emailová adresa')}
                                        value={this.state.email}
                                        onChange={(event) => this.setState({ email: event.target.value })}
                                    />
                                    <label htmlFor="floatingInput">
                                        {Translate.message('login.email', 'Emailová adresa')}
                                    </label>
                                </div>
                                <div className="mt-3 form-floating">
                                    <input
                                        type="password"
                                        className={classNames(
                                            'form-control',
                                            this.state.status === 'ERROR' && 'is-invalid',
                                        )}
                                        id="floatingPassword"
                                        placeholder={Translate.message('login.password', 'Heslo')}
                                        value={this.state.password}
                                        onChange={(event) => this.setState({ password: event.target.value })}
                                    />
                                    <label htmlFor="floatingPassword">
                                        {Translate.message('login.password', 'Heslo')}
                                    </label>
                                    {this.state.message.length > 0 && (
                                        <div className="invalid-feedback">{this.state.message}</div>
                                    )}
                                </div>
                                <button
                                    className="mt-4 w-100 btn btn-lg btn-primary text-white"
                                    disabled={!(this.state.status === 'READY' || this.state.status === 'ERROR')}
                                    type="submit"
                                    onClick={() => this.login()}
                                >
                                    {(this.state.status === 'READY' || this.state.status === 'ERROR') &&
                                        Translate.message('login.submit', 'Přihlásit se')}
                                    {this.state.status === 'LOGGING' && (
                                        <div className="spinner-border spinner-border-sm text-light" role="status">
                                            <span className="visually-hidden">
                                                {Translate.message('login.loading', 'Přihlašování...')}
                                            </span>
                                        </div>
                                    )}
                                </button>
                                <p className="mt-4 text-muted fw-lighter text-start">
                                    <Link to={Routes.passwordReset()}>
                                        {Translate.message('login.passwordReset', 'Zapomněli jste heslo?')}
                                    </Link>
                                    <br />
                                    <Link to={Routes.register()}>
                                        {Translate.message('login.register', 'Ještě nemáte účet?')}
                                    </Link>
                                    <br />
                                    <Link to={Routes.resend()}>
                                        {Translate.message('login.resend', 'Nepřišel vám ověřovací email?')}
                                    </Link>
                                </p>
                            </>
                        )}
                    </form>
                </main>
            </div>
        );
    }
}

export default withRouter(Login);
