import { Translate } from '../../../../utils/Translate';
import { filter } from '../../../utils/dataDisplayComponents/pagedView/Page';
import { FormInput } from '../../../utils/inputs/FormInput';

export const memberFilters: (orgRolesData: any) => filter<any>[] = (orgRolesData: any) => [
    {
        id: 'gender',
        title: 'filter.gender',
        createFilter: (value) => (value && value.length > 0 ? { gender: value } : {}),
        toggle: (value, onChange) => (
            <>
                <FormInput
                    type="select"
                    label={Translate.message('members.gender', 'Pohlaví')}
                    value={value}
                    onChange={onChange}
                    options={[
                        {
                            title: '',
                            value: '',
                        },
                        {
                            title: Translate.message('general.male', 'Muž'),
                            value: 'male',
                        },
                        {
                            title: Translate.message('general.female', 'Žena'),
                            value: 'female',
                        },
                        {
                            title: Translate.message('general.other', 'Jiné'),
                            value: 'other',
                        },
                    ]}
                />
            </>
        ),
    },
    {
        id: 'validMembership',
        title: 'filter.ismembershipValid',
        createFilter: (value) =>
            value && value.length > 0 ? { validMembership: value === 'true' ? true : false } : {},
        toggle: (value, onChange) => (
            <>
                <FormInput
                    type="select"
                    label={Translate.message('members.ismembershipValid', 'Platnost členství')}
                    value={value}
                    onChange={onChange}
                    options={[
                        {
                            title: '',
                            value: '',
                        },
                        {
                            title: Translate.message('general.validMembership', 'Je členem'),
                            value: 'true',
                        },
                        {
                            title: Translate.message('general.invalidMembership', 'Není členem'),
                            value: 'false',
                        },
                    ]}
                />
            </>
        ),
    },
    {
        id: 'organizationRole',
        title: 'filter.organizationRole',
        createFilter: (value) => (value && value > -1 ? { organizationRoleId: parseInt(value) } : {}),
        toggle: (value, onChange) => (
            <>
                <FormInput
                    type="select"
                    label={Translate.message('members.organizationRole', 'Role v organizaci')}
                    value={value}
                    onChange={onChange}
                    options={[
                        {
                            title: '',
                            value: -1,
                        },
                        ...orgRolesData.auth.as.organizationRoles.items.map(
                            (role: { id: number; title: string; icon: string | null }) => ({
                                title: role.title,
                                value: role.id,
                            }),
                        ),
                    ]}
                />
            </>
        ),
    },
];
