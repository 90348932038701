import * as React from 'react';
import { Translate } from '../../../../../utils/Translate';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { required } from '../../../../utils/inputs/inputValidators/validators';
import { IStepProps } from '../Apply';

export function StepNick(props: IStepProps) {
    return (
        <Form
            defaultValues={{
                nickname: props.values.firstname,
            }}
            onSubmit={(values) => props.next(values)}
            validators={(data) => ({
                nickname: [required],
            })}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <h2 className="mb-4">
                        <Translate.Component name="application.nick.title" alt="Jak chceš, abychom ti říkali?" />
                    </h2>
                    <div className="mb-5">
                        <p className="mb-4">
                            <Translate.Component
                                name="application.nick.subtitle"
                                alt="V Duze se většinou vzájemně oslovujeme <<strong&přezdívkami>>, jako třeba <<em&Pasťa>>, <<em&Zlesi>> nebo <<em&Sony>>. Pokud nemáš žádnou přezdívku v hlavě, ničemu to nevadí a určitě ti brzy nějakou vymyslíme."
                            />
                        </p>
                        <FormInput
                            {...inputProps('nickname')}
                            type="text"
                            label={Translate.message('application.nick.nickname', 'Přezdívka')}
                        />
                    </div>
                    <div className="mt-5 d-flex flex-row align-items-center">
                        <div className="d-inline-block text-start">
                            <span className="link blue" onClick={() => props.prev()}>
                                {Translate.message('application.prev', 'Zpět')}
                            </span>
                        </div>
                        <div className="d-inline-block text-end  flex-grow-1">
                            <button {...buttonProps}>{Translate.message('application.next', 'Další')}</button>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
}
