import { ApolloQueryResult, gql, QueryOptions } from '@apollo/client';
import {
    IconBallon,
    IconBriefcase,
    IconCalendarEvent,
    IconHome,
    IconMenu2,
    IconPin,
    IconPlus,
    IconUsers,
} from '@tabler/icons';
import moment from 'moment';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Api } from '../api/Api';
import { Event } from '../api/entities/Event';
import { Report } from '../api/entities/Report';
import { Rights } from '../api/Rights';
import { AppState } from '../AppState';
import { classNames } from '../utils/classNames';
import { hasRights } from '../utils/hasRights';
import { logout } from '../utils/logout';
import { Translate } from '../utils/Translate';
import { Routes } from './routes/Routes';
import { tasks } from './utils/eventTasks/tasks';
import { SidebarLink } from './utils/SidebarLink';

interface ISidebarProps {
    appState: AppState;
}

interface ISidebarState {
    requests: number;
    tasks: number;
    reports: number;
    open: boolean;
}

export class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
    state: ISidebarState = {
        requests: 0,
        tasks: 0,
        reports: 0,
        open: false,
    };

    private intervals: { [key: string]: NodeJS.Timeout } = {};

    locationDidChange() {
        this.setState({
            open: false,
        });
    }

    scheduleQuery(
        query: QueryOptions,
        evaluator: (result: ApolloQueryResult<any>) => number,
        key: keyof ISidebarState,
    ) {
        if (this.intervals[key]) return;

        const callback = () =>
            Api.client.query(query).then((result) => {
                if (!result.data) return;
                const value = evaluator(result);
                this.setState({ [key]: value } as any);
            });

        const timeout = setInterval(callback, 10 * 1000);

        this.intervals[key] = timeout;

        callback();
    }

    componentDidMount() {
        if (hasRights(this.props.appState, Rights.MEMBER_CHANGE_MEMBERSHIP)) {
            this.scheduleQuery(
                {
                    query: gql`
                        query membershipRequestsSidebar($memberId: Int!) {
                            auth {
                                as(memberId: $memberId) {
                                    membershipRequests(filter: { status: pending }) {
                                        total
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        memberId: this.props.appState.activeMember.id,
                    },
                },
                (result) => result.data.auth.as.membershipRequests.total as number,
                'requests',
            );
        }

        this.scheduleQuery(
            {
                query: gql`
                    query eventsListSidebar($memberId: Int!) {
                        auth {
                            as(memberId: $memberId) {
                                events(filter: { leaderId: $memberId }) {
                                    items {
                                        id
                                        allDay
                                        registrationStart
                                        articles {
                                            items {
                                                id
                                            }
                                        }
                                        linkPhoto
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    memberId: this.props.appState.activeMember.id,
                },
            },
            (result) =>
                (result.data.auth.as.events.items as Event[]).reduce(
                    (prev, event) =>
                        prev +
                        tasks.reduce(
                            (prev, curr) =>
                                prev + (!curr.validator || curr.validator(this.props.appState, event) ? 0 : 1),
                            0,
                        ),
                    0,
                ),
            'tasks',
        );

        this.scheduleQuery(
            {
                query: gql`
                    query reportsSidebar($memberId: Int!) {
                        auth {
                            as(memberId: $memberId) {
                                me {
                                    reports {
                                        items {
                                            ready
                                        }
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    memberId: this.props.appState.activeMember.id,
                },
            },
            (result) => result.data.auth.as.me.reports.items.filter((report: Report) => !report.ready).length as number,
            'reports',
        );
    }

    componentWillUnmount() {
        Object.entries(this.intervals).forEach(([key, interval]) => clearInterval(interval));
        this.intervals = {};
    }

    render() {
        const appState = this.props.appState;
        const backlink = window.location.pathname.replace(Routes.authAs(this.props.appState.memberId), '');

        return (
            <nav className={classNames('nav-sidebar', this.state.open && 'open')}>
                <IconMenu2
                    className="toggle link"
                    onClick={() => {
                        if (!this.state.open) window.scrollTo(0, 0);
                        this.setState({ open: !this.state.open });
                    }}
                />
                <Link to={Routes.auth()} className="brand-wrapper">
                    <img src={`${process.env.PUBLIC_URL}/media/logo.png`} alt="Logo" className="brand" />
                </Link>
                <ul className="nav nav-pills flex-column mb-auto">
                    <Dropdown>
                        <Dropdown.Toggle
                            as={
                                React.forwardRef((props, ref) => (
                                    <span {...props} role="button" className="dropdown-toggle nav-link" tabIndex={0}>
                                        <img
                                            src={appState.activeMember.image}
                                            alt=""
                                            className="profile-picture"
                                            width="28"
                                            height="28"
                                            style={{ marginLeft: -4 }}
                                        />
                                        <span>
                                            {appState.activeMember.firstname} "{appState.activeMember.nickname}"{' '}
                                            {appState.activeMember.lastname}
                                        </span>
                                    </span>
                                )) as any
                            }
                            className="text-start w-100 text-white"
                        ></Dropdown.Toggle>
                        <Dropdown.Menu variant="light" style={{ maxWidth: '100%' }}>
                            <Dropdown.Header>
                                {Translate.message('sidebar.memberSelect', 'Za koho chcete pracovat?')}
                            </Dropdown.Header>
                            {appState.members.map((member, id) => (
                                <Link
                                    key={id}
                                    to={Routes.authAs(id) + backlink}
                                    className="dropdown-item"
                                    onClick={() => this.locationDidChange()}
                                >
                                    <img src={member.image} alt="" className="profile-picture" width="28" height="28" />
                                    <span
                                        className="pe-1 align-middle"
                                        style={{
                                            maxWidth: 'calc(100% - 28px - 1em)',
                                            display: 'inline-block',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <span>
                                            {member.firstname} "{member.nickname}" {member.lastname}
                                        </span>
                                    </span>
                                </Link>
                            ))}
                            <Link
                                to={Routes.apply()}
                                className="dropdown-item"
                                onClick={() => this.locationDidChange()}
                            >
                                <IconPlus style={{ marginLeft: 4, marginRight: 'calc(0.5em + 8px)' }} size={20} />
                                <span
                                    className="pe-1 align-middle"
                                    style={{
                                        maxWidth: 'calc(100% - 28px - 1em)',
                                        display: 'inline-block',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {Translate.message('sidebar.register', 'Nová přihláška')}
                                </span>
                            </Link>

                            <Dropdown.Divider />

                            <Link
                                className="dropdown-item"
                                to={Routes.profile(appState.memberId)}
                                onClick={() => this.locationDidChange()}
                            >
                                {Translate.message('sidebar.profile', 'Profil člena')}
                            </Link>

                            <Link className="dropdown-item" to={Routes.account()}>
                                {Translate.message('auth.account', 'Nastavení účtu')}
                            </Link>

                            {/* <Dropdown.Divider />

                            <Link className="dropdown-item" to={Routes.settings(appState.memberId)}>
                                {Translate.message('sidebar.settings', 'Nastavení')}
                            </Link> */}

                            <Link
                                to={Routes.login()}
                                onClick={() => logout(this.props.appState)}
                                className="dropdown-item"
                            >
                                {Translate.message('sidebar.logout', 'Odhlásit se')}
                            </Link>
                        </Dropdown.Menu>
                    </Dropdown>
                    <hr />
                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.home(appState.memberId)}
                        title={Translate.message('sidebar.home', 'Domů')}
                        icon={IconHome}
                    />
                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.events(appState.memberId)}
                        title={Translate.message('sidebar.events.general', 'Events')}
                        icon={IconCalendarEvent}
                        badge={this.state.tasks + this.state.reports}
                    >
                        <SidebarLink
                            onClick={() => this.locationDidChange()}
                            route={Routes.upcomingEvents(appState.memberId)}
                            title={Translate.message('sidebar.events.upcoming', 'Plánované')}
                        />
                        <SidebarLink
                            onClick={() => this.locationDidChange()}
                            route={Routes.eventsCalendar(appState.memberId, moment().year(), moment().month() + 1)}
                            title={Translate.message('sidebar.events.calendar', 'Kalendář')}
                            fuzzy
                            hasIndex={2}
                        />
                        {appState.memberDetails && appState.memberDetails.leadEvents.total > 0 && (
                            <>
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.eventsMy(appState.memberId)}
                                    title={Translate.message('sidebar.events.my', 'Moje akce')}
                                    badge={this.state.tasks}
                                />
                            </>
                        )}
                        {hasRights(appState, Rights.EVENT_MODIFY) && (
                            <>
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.eventsList(appState.memberId)}
                                    title={Translate.message('sidebar.events.admin', 'Administrace')}
                                    fuzzy
                                />
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.eventsNew(appState.memberId)}
                                    title={Translate.message('sidebar.events.new', 'Nová akce')}
                                />
                            </>
                        )}
                        <SidebarLink
                            onClick={() => this.locationDidChange()}
                            route={Routes.reports(appState.memberId)}
                            title={Translate.message('sidebar.events.reports', 'Kroniky')}
                            badge={this.state.reports}
                        />
                    </SidebarLink>

                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.meetings(appState.memberId)}
                        title={Translate.message('sidebar.meetings.general', 'Schůzky')}
                        icon={IconBallon}
                    >
                        <SidebarLink
                            onClick={() => this.locationDidChange()}
                            route={Routes.meetingsMy(appState.memberId)}
                            title={Translate.message('sidebar.meetings.my', 'Moje schůzka')}
                        />
                        <SidebarLink
                            onClick={() => this.locationDidChange()}
                            route={Routes.meetingsCurrent(appState.memberId)}
                            title={Translate.message('sidebar.meetings.current', 'Letošní schůzky')}
                        />
                        {hasRights(appState, Rights.MEETING_SCHEDULE) && (
                            <SidebarLink
                                onClick={() => this.locationDidChange()}
                                route={Routes.meetingsPlanner(appState.memberId)}
                                title={Translate.message('sidebar.meetings.planner', 'Naplánovat schůzky')}
                            />
                        )}
                        {hasRights(appState, Rights.MEETING_CREATE) && (
                            <SidebarLink
                                onClick={() => this.locationDidChange()}
                                route={Routes.meetingsAdmin(appState.memberId)}
                                title={Translate.message('sidebar.meetings.admin', 'Správa schůzek')}
                                fuzzy
                            />
                        )}
                    </SidebarLink>
                    {hasRights(appState, Rights.ARTICLE_PUBLISH) && (
                        <>
                            <SidebarLink
                                onClick={() => this.locationDidChange()}
                                route={Routes.articles(appState.memberId)}
                                title={Translate.message('sidebar.articles.general', 'Lístečky')}
                                icon={IconPin}
                            >
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.articlesList(appState.memberId)}
                                    title={Translate.message('sidebar.articles.admin', 'Administrace')}
                                    fuzzy
                                />
                                {hasRights(appState, Rights.ARTICLE_CREATE) && (
                                    <>
                                        <SidebarLink
                                            onClick={() => this.locationDidChange()}
                                            route={Routes.articlesNew(appState.memberId)}
                                            title={Translate.message('sidebar.articles.new', 'Nový lísteček')}
                                        />
                                    </>
                                )}
                            </SidebarLink>
                        </>
                    )}
                    <SidebarLink
                        onClick={() => this.locationDidChange()}
                        route={Routes.members(appState.memberId)}
                        title={Translate.message('sidebar.members.general', 'Členové')}
                        icon={IconUsers}
                        badge={this.state.requests}
                    >
                        <SidebarLink
                            onClick={() => this.locationDidChange()}
                            route={Routes.counselors(appState.memberId)}
                            title={Translate.message('sidebar.members.counselors', 'Vedoucí')}
                        />
                        {hasRights(appState, Rights.MEMBER_DETAIL) && (
                            <>
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.activeMembers(appState.memberId)}
                                    title={Translate.message('sidebar.members.current', 'Současní')}
                                    fuzzy
                                />
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.membersList(appState.memberId)}
                                    title={Translate.message('sidebar.members.all', 'Všichni')}
                                    fuzzy
                                />
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.membersNew(appState.memberId)}
                                    title={Translate.message('sidebar.members.new', 'Nový člen')}
                                />
                            </>
                        )}
                        {hasRights(appState, Rights.MEMBER_CHANGE_MEMBERSHIP) && (
                            <>
                                <SidebarLink
                                    onClick={() => this.locationDidChange()}
                                    route={Routes.membersRequests(appState.memberId)}
                                    title={Translate.message('sidebar.members.requests', 'Žádosti')}
                                    fuzzy
                                    badge={this.state.requests}
                                />
                            </>
                        )}
                    </SidebarLink>
                    {(hasRights(appState, Rights.ADMIN) ||
                        hasRights(appState, Rights.ORGANIZATION_ROLE_EDIT) ||
                        hasRights(appState, Rights.ROLE_EDIT)) && (
                        <SidebarLink
                            route={Routes.admin(appState.memberId)}
                            title={Translate.message('sidebar.admin.general', 'Administrace')}
                            icon={IconBriefcase}
                        >
                            {hasRights(appState, Rights.ROLE_EDIT) && (
                                <SidebarLink
                                    route={Routes.adminRoles(appState.memberId)}
                                    title={Translate.message('sidebar.admin.roles', 'Oprávnění')}
                                />
                            )}

                            {hasRights(appState, Rights.ORGANIZATION_ROLE_EDIT) && (
                                <SidebarLink
                                    route={Routes.adminOrganizationRoles(appState.memberId)}
                                    title={Translate.message('sidebar.admin.organizationRoles', 'Role v organizaci')}
                                />
                            )}
                            {hasRights(appState, Rights.ADMIN) && (
                                <>
                                    <SidebarLink
                                        route={Routes.adminTokens(appState.memberId)}
                                        title={Translate.message('sidebar.admin.tokens', 'Aplikační tokeny')}
                                    />
                                    <SidebarLink
                                        route={Routes.adminLogs(appState.memberId)}
                                        title={Translate.message('sidebar.admin.logs', 'Nástroje pro vývojáře')}
                                    />
                                </>
                            )}
                        </SidebarLink>
                    )}
                </ul>
            </nav>
        );
    }
}
