const functions = ['log', 'warn', 'error'];

export type LogSeverity = (typeof functions)[number];
export type Log = { severity: LogSeverity; content: any[]; timestamp: Date; url: string };
export type LoggerConsole = Console & {
    _std: { [key: string]: typeof console.log };
    _logs: Log[];
};

export function setupLogger(maskConsole: boolean) {
    (console as LoggerConsole)._std = functions.reduce(
        (prev, curr) => ({
            ...prev,
            [curr]: (console as any)[curr].bind(console),
        }),
        {},
    );
    (console as LoggerConsole)._logs = [];

    functions.forEach((fn) => {
        (console as any)[fn] = function () {
            (console as LoggerConsole)._logs.push({
                severity: fn,
                content: Array.from(arguments),
                timestamp: new Date(),
                url: window.location.pathname,
            });
            if (!maskConsole) {
                (console as any)._std[fn].apply(console, arguments);
            }
        };
    });
}
