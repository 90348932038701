import { useQuery } from '@apollo/client';
import { AppState } from '../../../../AppState';
import { Api } from '../../../../api/Api';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { CounselorRenderer } from './CounselorRenderer';

interface ICounselorsProps {
    appState: AppState;
}

export function Counselors(props: ICounselorsProps) {
    const { loading, error, data } = useQuery(Api.queries.member.counselors(props.appState.activeMember.id));

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    return (
        <div className="col-12">
            <h1>{Translate.message('members.counselors.title', 'Vedoucí')}</h1>
            <h6>
                {Translate.message(
                    'members.counselors.subtitle',
                    'Vedoucí, nejsou žádní tonoucí! Tak se podívej, kdo to tady celé vede...',
                )}
            </h6>
            <CounselorRenderer {...props} members={data.auth.as.members.items} />
        </div>
    );
}
