import { useMutation } from '@apollo/client';
import * as React from 'react';
import { Api } from '../../../../../api/Api';
import { Representative } from '../../../../../api/entities/Representative';
import { Translate } from '../../../../../utils/Translate';
import { ErrorDisplay } from '../../../../utils/ErrorDisplay';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { email, nonempty, phone, required } from '../../../../utils/inputs/inputValidators/validators';
import { Spinner } from '../../../../utils/Spinner';
import { IStepProps } from '../Apply';

export function StepRepresentative(props: IStepProps) {
    const [iterator, forceUpdate] = React.useState(0);
    const [create, { loading, error }] = useMutation(Api.mutations.member.representative.addWithData);

    const added = (props.values.representatives || []) as Representative[];

    if (loading) return <Spinner />;
    if (error)
        return (
            <>
                <ErrorDisplay error={error} />
                <div className="mt-5 d-flex flex-row align-items-center">
                    <div className="d-inline-block text-start">
                        <span className="link blue" onClick={() => forceUpdate(iterator + 1)}>
                            Zpět
                        </span>
                    </div>
                    <div className="d-inline-block text-end  flex-grow-1"></div>
                </div>
            </>
        );

    return (
        <Form
            defaultValues={{}}
            onSubmit={(values) => {
                added.push(values as Representative);
                props.setValues({ representatives: added });
                create({
                    variables: {
                        memberId: props.memberId,
                        targetId: props.memberId,
                        value: values,
                    },
                });
            }}
            validators={(data) => ({
                firstname: [required, nonempty],
                lastname: [required, nonempty],
                email: [required, email],
                phone: [required, phone],
            })}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <h2 className="mb-4">
                        <Translate.Component
                            name="application.representative.title"
                            alt="Teď nám pověz něco o rodičích"
                        />
                    </h2>
                    <div className="mb-5">
                        <p>
                            <Translate.Component
                                name="application.representative.subtitle"
                                alt="Zákonný zástupce je potřeba, <<strong&pokud ti ještě nebylo 18>>."
                            />
                        </p>
                        <p className="mb-4">
                            <Translate.Component
                                name="application.representative.explanation"
                                alt="Do seznamu můžeš přidávat svoje zákonné zástupce. Až budeš spokojený, můžeš <<link&pokračovat dál>>."
                                wrappers={{
                                    link: (children, key) => (
                                        <span key={key} onClick={() => props.next({})} className="link blue">
                                            {children}
                                        </span>
                                    ),
                                }}
                            />
                        </p>
                        <p className="mb-0">
                            <strong>
                                <Translate.Component
                                    name="application.representative.added"
                                    alt="Přidaní zákonní zástupci:"
                                />
                            </strong>
                        </p>
                        {added.length === 0 ? (
                            <em>
                                <Translate.Component
                                    name="application.representative.none"
                                    alt="Zatím jste nepřidali zákonné zástupce"
                                />
                            </em>
                        ) : (
                            (props.values.representatives as Representative[]).map((representative) => (
                                <p className="mb-0">
                                    {representative.firstname} {representative.lastname}
                                </p>
                            ))
                        )}
                        <div className="mt-5">
                            <FormInput
                                {...inputProps('firstname')}
                                type="text"
                                label={Translate.message(
                                    'application.representative.firstname',
                                    'Jméno zákonného zástupce',
                                )}
                            />
                            <FormInput
                                {...inputProps('lastname')}
                                type="text"
                                label={Translate.message(
                                    'application.representative.lastname',
                                    'Příjmení zákonného zástupce',
                                )}
                            />
                        </div>
                        <div className="mt-3">
                            <FormInput
                                {...inputProps('email')}
                                type="text"
                                label={Translate.message('application.representative.email', 'Kontaktní email')}
                            />
                            <FormInput
                                {...inputProps('phone')}
                                type="text"
                                label={Translate.message('application.representative.phone', 'Kontaktní telefon')}
                            />
                        </div>
                        <div className="mt-5">
                            <button {...buttonProps}>
                                {Translate.message('application.representative.add', 'Přidat')}
                            </button>
                        </div>
                    </div>
                    <div className="mt-5 d-flex flex-row align-items-center">
                        <div className="d-inline-block text-start"></div>
                        <div className="d-inline-block text-end flex-grow-1">
                            {added.length === 0 ? (
                                <span className="link blue" onClick={() => props.next({})}>
                                    {Translate.message('application.skip', 'Přeskočit')}
                                </span>
                            ) : (
                                <button className="btn btn-primary" onClick={() => props.next({})}>
                                    {Translate.message('application.next', 'Další')}
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
}
