import * as React from 'react';
import { Translate } from '../../../../../utils/Translate';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { nonempty, required } from '../../../../utils/inputs/inputValidators/validators';
import { IStepProps } from '../Apply';

export function StepNames(props: IStepProps) {
    return (
        <Form
            defaultValues={{
                gender: 'other',
            }}
            onSubmit={(values) => props.next(values)}
            validators={(data) => ({
                firstname: [required, nonempty],
                lastname: [required, nonempty],
                gender: [required],
            })}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <h2 className="mb-4">
                        <Translate.Component name="application.names.title" alt="Tak pojďme na to" />
                    </h2>
                    <div className="mb-5">
                        <p className="mb-4">
                            <Translate.Component
                                name="application.names.subtitle"
                                alt="Nejdřív nám prozraď, <<strong&jak se jmenuješ>>, ať víme, na koho se máme těšit."
                            />
                        </p>
                        <FormInput
                            {...inputProps('firstname')}
                            type="text"
                            label={Translate.message('application.names.firstname', 'Jméno')}
                        />
                        <FormInput
                            {...inputProps('lastname')}
                            type="text"
                            label={Translate.message('application.names.lastname', 'Příjmení')}
                        />
                        <FormInput
                            {...inputProps('gender', 'input-radio mt-5')}
                            type="radio"
                            label={Translate.message('application.names.gender', 'Pohlaví')}
                            options={[
                                {
                                    title: Translate.message('application.names.genders.male', 'Kluk'),
                                    value: 'male',
                                },
                                {
                                    title: Translate.message('application.names.genders.female', 'Holka'),
                                    value: 'female',
                                },
                                {
                                    title: Translate.message('application.names.genders.other', 'Jiné'),
                                    value: 'other',
                                },
                            ]}
                            style={{ width: 110 }}
                        />
                    </div>
                    <div className="mt-5 d-flex flex-row align-items-center">
                        <div className="d-inline-block text-start">
                            <span className="link blue" onClick={() => props.prev()}>
                                {Translate.message('application.prev', 'Zpět')}
                            </span>
                        </div>
                        <div className="d-inline-block text-end  flex-grow-1">
                            <button {...buttonProps}>{Translate.message('application.next', 'Další')}</button>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
}
