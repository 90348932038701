import { useMutation } from '@apollo/client';
import { IconCheck } from '@tabler/icons';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Api } from '../../../api/Api';
import { classNames } from '../../../utils/classNames';
import { gqlErrorToString } from '../../../utils/gqlErrorToString';
import { Translate } from '../../../utils/Translate';
import { FormInput } from '../../utils/inputs/FormInput';
import { Routes } from '../Routes';

interface IResendProps {}

export function Resend(props: IResendProps) {
    const [resend, { loading, error, data }] = useMutation(Api.mutations.resend);
    const [email, setEmail] = React.useState('');

    return (
        <div className="wrapper center-screen spot-rh">
            <main>
                <form>
                    <h1 className="h2 mb-3 fw-normal">
                        {Translate.message('resend.title', 'Znovu odeslat potvrzovací email')}
                    </h1>
                    <p>
                        {Translate.message(
                            'resend.subtitle',
                            'Pokud vám nepřišel potvrzovací email, můžete si ho nechat znovu odeslat zde',
                        )}
                    </p>
                    <FormInput
                        type="email"
                        value={email}
                        onChange={(value) => setEmail(value)}
                        label={Translate.message('resend.input', 'Email')}
                        error={gqlErrorToString(error)}
                    />
                    <button
                        className={classNames(
                            'mt-4 w-100 btn btn-lg text-white',
                            data && !error ? 'btn-success' : 'btn-primary',
                        )}
                        type="button"
                        onClick={() => {
                            resend({
                                variables: {
                                    email,
                                },
                            });
                        }}
                        disabled={loading || (data && !error)}
                    >
                        {loading ? (
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden">
                                    {Translate.message('resend.sending', 'Odesílání...')}
                                </span>
                            </div>
                        ) : data && !error ? (
                            <>
                                <IconCheck /> {Translate.message('resend.sent', 'Odesláno')}
                            </>
                        ) : (
                            Translate.message('resend.send', 'Znovu odeslat')
                        )}
                    </button>
                    <p className="mt-4 text-muted fw-lighter text-start">
                        <Translate.Component
                            name="resend.login"
                            alt="Už máte ověřený email? Můžete se <<link&přihlásit>>"
                            wrappers={{
                                link: (children, key) => (
                                    <Link key={key} to={Routes.login()}>
                                        {children}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </form>
            </main>
        </div>
    );
}
