import { gql, useQuery } from '@apollo/client';
import { AppState } from '../../../../AppState';
import { OrganizationRole } from '../../../../api/entities/OrganizationRole';
import { Translate } from '../../../../utils/Translate';
import { Accordion, AccordionDefinition } from '../../../utils/Accordion';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { IconByName } from '../../../utils/IconByName';
import { Spinner } from '../../../utils/Spinner';
import { OrganizationRoleEditor } from './OrganizationRoleEditor';

interface IOrganizationRoleProps {
    appState: AppState;
}

export function OrganizationRolesPage(props: IOrganizationRoleProps) {
    const { error, loading, data, refetch } = useQuery(
        gql`
            query RolesPageOrganizationRoles($memberId: Int) {
                auth {
                    as(memberId: $memberId) {
                        organizationRoles {
                            items {
                                id
                                title
                                publicInfo
                                icon
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                memberId: props.appState.activeMember.id,
            },
        },
    );

    if (loading) return <Spinner />;
    if (error) return <ErrorDisplay error={error} />;

    return (
        <div className="col-12">
            <h1>{Translate.message('organizationRoles.title', 'Role v organizaci')}</h1>
            <h6>
                {Translate.message(
                    'organizationRoles.subtitle',
                    'Vytvořte role v organizaci a nastavte, kdo má viditelné osobní údaje.',
                )}
            </h6>

            <div className="mt-5">
                <Accordion
                    tabs={{
                        ...(data.auth.as.organizationRoles.items as OrganizationRole[]).reduce(
                            (prev: AccordionDefinition, role) => ({
                                ...prev,
                                [role.id]: {
                                    title: (
                                        <h3 className="mb-0">
                                            {role.title} <IconByName icon={role.icon as any} />
                                        </h3>
                                    ),
                                    content: (
                                        <OrganizationRoleEditor
                                            appState={props.appState}
                                            role={role}
                                            onSubmit={() => refetch()}
                                        />
                                    ),
                                },
                            }),
                            {},
                        ),

                        new: {
                            title: (
                                <h3 className="mb-0" style={{ opacity: 0.5 }}>
                                    {Translate.message('organizationRoles.createNew', 'Nová role')}
                                </h3>
                            ),
                            content: <OrganizationRoleEditor appState={props.appState} onSubmit={() => refetch()} />,
                        },
                    }}
                />
            </div>
        </div>
    );
}
