const locale = {
    plural: (num: number) => {
        num = Math.abs(num);
        if (num === 1) {
            return 1;
        }
        return 2;
    },
    order: (num: number) => {
        num = Math.abs(num) % 100;
        if (num >= 10 && num <= 20) return 4;
        num = Math.abs(num) % 10;
        switch (num) {
            case 1:
                return 1;
            case 2:
                return 2;
            case 3:
                return 3;
            default:
                return 4;
        }
    },
    gender: (gender: string) => {
        switch (gender) {
            case 'male':
                return 1;
            case 'female':
                return 2;
            default:
                return 3;
        }
    },
    direct: (value: number) => value,
};

export default locale;
