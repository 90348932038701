import { gql, useMutation } from '@apollo/client';
import { IconAlertTriangle } from '@tabler/icons';
import { useState } from 'react';
import { AppState } from '../../../../AppState';
import { Rights } from '../../../../api/Rights';
import { Role } from '../../../../api/entities/Role';
import { Translate } from '../../../../utils/Translate';
import { capitalize } from '../../../../utils/capitalize';
import { rightToTranslation } from '../../../../utils/rightToTranslation';
import { ConfirmButton } from '../../../utils/ConfirmButton';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { RightsIcon } from '../../../utils/RightsIcon';
import { FormInput } from '../../../utils/inputs/FormInput';

export interface IRoleEditorProps {
    role?: Role;
    appState: AppState;
    isOnlyAdmin: boolean;
    onSubmit?: () => void;
}

export function RoleEditor({ role, appState, isOnlyAdmin, onSubmit }: IRoleEditorProps) {
    const [rights, setRights] = useState(role?.rights || []);
    const [color, setColor] = useState(role?.color || '#000000');
    const [title, setTitle] = useState(role?.title || Translate.message('roles.roles.newRole', 'Nová role'));

    const [save, { loading: l1, error: e1 }] = useMutation(gql`
        mutation RoleEditor($memberId: Int!, $id: Int!, $value: RoleInput!) {
            auth {
                as(memberId: $memberId) {
                    updateRole(id: $id, value: $value) {
                        id
                    }
                }
            }
        }
    `);

    const [create, { loading: l2, error: e2 }] = useMutation(gql`
        mutation RoleEditorCreate($memberId: Int!, $value: RoleInput!) {
            auth {
                as(memberId: $memberId) {
                    createRole(value: $value) {
                        id
                    }
                }
            }
        }
    `);

    const [remove, { loading: l3, error: e3 }] = useMutation(gql`
        mutation RoleEditorDelete($memberId: Int!, $id: Int!) {
            auth {
                as(memberId: $memberId) {
                    deleteRole(id: $id)
                }
            }
        }
    `);

    if (e1) return <ErrorDisplay error={e1} />;
    if (e2) return <ErrorDisplay error={e2} />;
    if (e3) return <ErrorDisplay error={e3} />;

    return (
        <div>
            <h3>{Translate.message('roles.editor.group.general', 'Možnosti')}</h3>
            <FormInput
                type="text"
                value={title}
                onChange={(value) => setTitle(value)}
                label={Translate.message('roles.editor.titleField', 'Název role')}
            />
            <div className="mt-2 row">
                <div className="col-6">
                    <FormInput
                        type="color"
                        value={color}
                        onChange={(value) => setColor(value)}
                        label={Translate.message('roles.editor.color', 'Barva role')}
                    />
                </div>
                {role && (
                    <div className="col-6 mt-2" style={{ fontSize: '1.5em' }}>
                        <RightsIcon role={role} />
                    </div>
                )}
            </div>
            <h3 className="mt-5">{Translate.message('roles.editor.group.rights', 'Oprávnění')}</h3>
            <div style={{ columns: 3 }}>
                {Object.entries(
                    Object.values(Rights).reduce(
                        (prev, right) => ({
                            ...prev,
                            [right.split('.')[0]]: [...(prev[right.split('.')[0]] || []), right],
                        }),
                        {} as { [key: string]: string[] },
                    ),
                ).map(([group, groupRights], j) => (
                    <div style={{ breakInside: 'avoid-column' }} key={j}>
                        <h4 className="mt-3 d-inline-block">
                            {Translate.message(
                                `rights.group.${group
                                    .split('-')
                                    .map((word, i) => (i === 0 ? word : capitalize(word)))
                                    .join('')}`,
                            )}
                        </h4>
                        {groupRights.map((right, i) => (
                            <div style={{ breakInside: 'avoid-column', marginTop: -8 }} key={i}>
                                <FormInput
                                    disabled={
                                        (isOnlyAdmin && right === 'admin') ||
                                        (rights.includes('admin') && right !== 'admin')
                                    }
                                    error={
                                        isOnlyAdmin && right === 'admin'
                                            ? Translate.message(
                                                  'roles.editor.onlyAdmin',
                                                  'V systému musí zůstat alespoň jedna administrátorská role!',
                                              )
                                            : undefined
                                    }
                                    className="d-inline-block"
                                    type="checkbox"
                                    value={rights.includes(right) || rights.includes('admin')}
                                    label={
                                        <div
                                            style={{
                                                marginTop: -10,
                                                fontWeight: right === 'admin' ? 'bold' : 'unset',
                                            }}
                                            className={right === 'admin' ? 'text-danger' : ''}
                                        >
                                            {rightToTranslation(right)}
                                            {(right === 'admin' ||
                                                group === 'role' ||
                                                right === 'generic.allow-html') && (
                                                <>
                                                    {' '}
                                                    <IconAlertTriangle />
                                                </>
                                            )}
                                            <br />
                                            <span style={{ opacity: 0.5 }}>{right}</span>
                                        </div>
                                    }
                                    onChange={(value) => {
                                        let temp = rights.filter((r) => r !== right);
                                        if (value) {
                                            temp.push(right);
                                        }
                                        setRights(temp);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <ConfirmButton
                label={
                    <>
                        {Translate.message('roles.editor.save', 'Uložit - dej si pozor, co děláš!')}{' '}
                        <IconAlertTriangle />
                    </>
                }
                onConfirm={async () => {
                    if (role) {
                        await save({
                            variables: {
                                memberId: appState.activeMember.id,
                                id: role.id,
                                value: {
                                    title,
                                    rights,
                                    color,
                                },
                            },
                        });
                    } else {
                        await create({
                            variables: {
                                memberId: appState.activeMember.id,
                                value: {
                                    title,
                                    rights,
                                    color,
                                },
                            },
                        });
                    }
                    onSubmit && onSubmit();
                }}
                disabled={l1 || l2}
                color="warning"
            ></ConfirmButton>
            {role && (
                <ConfirmButton
                    className="ms-3"
                    label={<>{Translate.message('roles.editor.delete', 'Odstranit roli')} </>}
                    onConfirm={async () => {
                        await remove({
                            variables: {
                                memberId: appState.activeMember.id,
                                id: role.id,
                            },
                        });
                        onSubmit && onSubmit();
                    }}
                    disabled={l3 || isOnlyAdmin}
                    color="danger"
                />
            )}
        </div>
    );
}
