import { gql, useMutation, useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { AppState } from '../../../../AppState';
import { Translate } from '../../../../utils/Translate';
import { ErrorDisplay } from '../../../utils/ErrorDisplay';
import { Spinner } from '../../../utils/Spinner';
import { Tabs } from '../../../utils/Tabs';
import { MeetingInfoEditor } from './editors/MeetingInfoEditor';
import { MeetingMembersEditor } from './editors/MeetingMembersEditor';
import { MeetingOtherEditor } from './editors/MeetingOtherEditor';

interface IMeetingsEditProps {
    appState: AppState;
}

export function MeetingsEditPage(props: IMeetingsEditProps) {
    const {
        params: { detailId },
    } = useRouteMatch<{ detailId: string }>();
    const id = parseInt(detailId);

    const {
        loading: l1,
        error: e1,
        data,
        refetch,
    } = useQuery(gql`
        query MeetingDetail {
            auth {
                as(memberId: ${props.appState.activeMember.id}) {
                    meetingById(id: ${id}) {
                        id
                        title
                        seasonFrom
                        seasonTo
                        firstFrom
                        firstTo
                        frequency
                        frequencyEvery
                        current
                        members {
                            id
                            hasPublicInfo
                        }
                    }
                }
            }
        }
    `);

    const [update, { loading: l2, error: e2, data: response }] = useMutation(gql`
        mutation UpdateMeeting($memberId: Int!, $id: Int!, $value: MeetingInput!) {
            auth {
                as(memberId: $memberId) {
                    updateMeeting(meetingId: $id, value: $value) {
                        id
                    }
                }
            }
        }
    `);

    if (isNaN(id))
        return (
            <ErrorDisplay
                error={Translate.message('error.missingUrlArgument', 'Chybějící, nebo chybný argument v adrese')}
            />
        );

    if (l1 || l2) return <Spinner />;
    if (e1) return <ErrorDisplay error={e1} />;
    if (e2) return <ErrorDisplay error={e2} />;
    if (response) refetch();

    return (
        <div className="col-12">
            <h1>{Translate.message('meetings.edit.title', 'Upravit schůzku')}</h1>
            <h6 className="mb-5">
                {Translate.message('meetings.edit.subtitle', 'Uprav existující schůzku a doplň k ní účastníky')}
            </h6>

            <Tabs
                tabs={{
                    basic: {
                        title: Translate.message('meetings.edit.tabs.basic', 'Základní informace'),
                        content: (
                            <MeetingInfoEditor
                                appState={props.appState}
                                meeting={data.auth.as.meetingById}
                                onSubmit={(value) =>
                                    update({
                                        variables: {
                                            memberId: props.appState.activeMember.id,
                                            id,
                                            value,
                                        },
                                    })
                                }
                            />
                        ),
                    },
                    members: {
                        title: Translate.message('meetings.edit.tabs.members', 'Členové'),
                        content: (
                            <MeetingMembersEditor
                                appState={props.appState}
                                meeting={data.auth.as.meetingById}
                                refetch={() => refetch()}
                            />
                        ),
                    },
                    management: {
                        title: Translate.message('meetings.edit.tabs.management', 'Správa schůzky'),
                        content: <MeetingOtherEditor appState={props.appState} meeting={data.auth.as.meetingById} />,
                    },
                }}
                default="basic"
            />
        </div>
    );
}
