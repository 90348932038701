import * as React from 'react';
import { Translate } from '../../../../../utils/Translate';
import { Form } from '../../../../utils/inputs/Form';
import { FormInput } from '../../../../utils/inputs/FormInput';
import { email, phone, required } from '../../../../utils/inputs/inputValidators/validators';
import { IStepProps } from '../Apply';

export function StepContact(props: IStepProps) {
    return (
        <Form
            defaultValues={{}}
            onSubmit={(values) => props.next(values)}
            validators={(data) => ({
                email: [required, email],
                phone: [phone],
            })}
        >
            {(inputProps, state, buttonProps) => (
                <>
                    <h2 className="mb-4">
                        <Translate.Component name="application.contact.title" alt="Jak tě můžeme kontaktovat?" />
                    </h2>
                    <div className="mb-5">
                        <p className="mb-4">
                            <Translate.Component
                                name="application.contact.subtitle"
                                alt="Abychom za tebou nemuseli posílat poštovní holuby (jakkoli super by to bylo), rádi bychom na tebe dostali i nějaký trochu modernější <<strong&kontakt>>. Budeme potřebovat tvůj <<strong&email>> a (pokud máš) <<strong&telefonní číslo>>."
                            />
                        </p>
                        <FormInput
                            {...inputProps('email')}
                            type="text"
                            label={Translate.message('application.contact.email', 'Email')}
                        />
                        <FormInput
                            {...inputProps('phone')}
                            type="text"
                            label={Translate.message('application.contact.phone', 'Telefonní číslo')}
                        />
                    </div>
                    <div className="mt-5 d-flex flex-row align-items-center">
                        <div className="d-inline-block text-start">
                            <span className="link blue" onClick={() => props.prev()}>
                                {Translate.message('application.prev', 'Zpět')}
                            </span>
                        </div>
                        <div className="d-inline-block text-end  flex-grow-1">
                            <button {...buttonProps}>{Translate.message('application.next', 'Další')}</button>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
}
