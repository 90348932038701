import * as React from 'react';

interface ILoadingProps {}

export function Loading(props: ILoadingProps) {
    return (
        <div className="wrapper center-screen spot-rh">
            <div className="spinner-border spinner-border text-dark mx-auto" style={{ width: '3rem', height: '3rem' }}>
                <span className="visually-hidden">Načítání...</span>
            </div>
        </div>
    );
}
