import moment from 'moment';
import { Meeting } from '../../../../api/entities/Meeting';
import { Translate } from '../../../../utils/Translate';

export function meetingFrequencyToText(meeting: Meeting) {
    const frequency = meeting.frequency;
    const every = meeting.frequencyEvery;
    const first = moment(meeting.firstFrom);
    const firstEnd = moment(meeting.firstTo);

    if (frequency === 'irregular') {
        return Translate.message('meetings.frequency.irregular', 'Nepravidelně');
    }

    const strFrequency =
        Translate.message('meetings.frequency.every', '{{every?plural|Každý|Každé|Každých}}', { every }) +
        ' ' +
        (every > 1 ? every + ' ' : '') +
        {
            daily: Translate.message('meetings.frequency.daily', '{{every?plural|den|dny|dnů}}', { every }),
            weekly: Translate.message('meetings.frequency.weekly', '{{every?plural|týden|týdny|týdnů}}', { every }),
            monthly: Translate.message('meetings.frequency.monthly', '{{every?plural|měsíc|měsíce|měsíců}}', {
                every,
            }),
            yearly: Translate.message('meetings.frequency.yearly', '{{every?plural|rok|roky|let}}', { every }),
            irregular: '',
        }[meeting.frequency];

    const at = Translate.message('meetings.frequency.at', 'v');
    const from = Translate.message('meetings.frequency.from', 'od');
    const to = Translate.message('meetings.frequency.to', 'do');

    const strAt = {
        daily: ` ${from} ${first.format('H:mm')} ${to} ${firstEnd.format('H:mm')}`,
        weekly: ` ${at} ${first.format('dddd')} ${from} ${first.format('H:mm')} ${to} ${firstEnd.format('H:mm')}`,
        monthly: ` ${first.format('D.')} ${from} ${first.format('H:mm')} ${to} ${firstEnd.format('H:mm')}`,
        yearly: ` ${first.format('D. MMMM')} ${from} ${first.format('H:mm')} ${to} ${firstEnd.format('H:mm')}`,
        irregular: '',
    }[meeting.frequency];

    return strFrequency + ' ' + strAt;
}
