export class Routes {
    // TODO: translations

    // Routes
    public static root = () => '';
    public static auth = () => `${this.root()}/auth`;

    public static login = () => `${this.root()}/login`;
    public static register = () => `${this.root()}/register`;
    public static resend = () => `${this.root()}/resend`;
    public static registerConfirm = () => `${this.root()}/register-confirm`;
    public static passwordReset = () => `${this.root()}/password-reset`;
    public static resetConfirm = () => `${this.root()}/reset-confirm`;
    public static shadowRegister = () => `${this.root()}/shadow-register`;

    public static account = () => `${this.auth()}/account`;

    public static authAs = (memberId?: number) => `${this.auth()}/${memberId === undefined ? ':memberId' : memberId}`;
    public static home = (memberId?: number) => `${this.authAs(memberId)}`;

    public static members = (memberId?: number) => `${this.authAs(memberId)}/members`;
    public static membersNew = (memberId?: number) => `${this.members(memberId)}/new`;
    public static membersDetail = (memberId?: number, detailId?: number) =>
        `${this.members(memberId)}/detail/${detailId === undefined ? ':detailId' : detailId}`;
    public static membersEdit = (memberId?: number, detailId?: number) =>
        `${this.membersDetail(memberId, detailId)}/edit`;
    public static counselors = (memberId?: number) => `${this.members(memberId)}/counselors`;
    public static activeMembers = (memberId?: number) => `${this.members(memberId)}/active`;
    public static membersList = (memberId?: number) => `${this.members(memberId)}/all`;
    public static membersRequests = (memberId?: number) => `${this.members(memberId)}/requests`;

    public static events = (memberId?: number) => `${this.authAs(memberId)}/events`;
    public static eventsNew = (memberId?: number) => `${this.events(memberId)}/new`;
    public static eventsDetail = (memberId?: number, detailId?: number) =>
        `${this.events(memberId)}/detail/${detailId === undefined ? ':detailId' : detailId}`;
    public static eventsEdit = (memberId?: number, detailId?: number) =>
        `${this.eventsDetail(memberId, detailId)}/edit`;
    public static eventsList = (memberId?: number) => `${this.events(memberId)}/all`;
    public static eventsMy = (memberId?: number) => `${this.events(memberId)}/my`;
    public static upcomingEvents = (memberId?: number) => `${this.events(memberId)}/upcoming`;
    public static eventsCalendar = (memberId?: number, year?: number, month?: number) =>
        `${this.events(memberId)}/calendar/${year === undefined ? ':year' : year}/${
            month === undefined ? ':month' : month
        }`;
    public static reports = (memberId?: number) => `${this.events(memberId)}/reports`;

    public static articles = (memberId?: number) => `${this.authAs(memberId)}/articles`;
    public static articlesList = (memberId?: number) => `${this.articles(memberId)}/all`;
    public static articlesNew = (memberId?: number) => `${this.articles(memberId)}/new`;
    public static articlesDetail = (memberId?: number, detailId?: number) =>
        `${this.articles(memberId)}/detail/${detailId === undefined ? ':detailId' : detailId}`;
    public static articlesEdit = (memberId?: number, detailId?: number) =>
        `${this.articlesDetail(memberId, detailId)}/edit`;

    public static camps = (memberId?: number) => `${this.authAs(memberId)}/camps`;

    public static finances = (memberId?: number) => `${this.authAs(memberId)}/finances`;

    public static profile = (memberId?: number) => `${this.authAs(memberId)}/profile`;
    public static apply = () => `${this.auth()}/application`;

    public static admin = (memberId?: number) => `${this.authAs(memberId)}/admin`;
    public static adminRoles = (memberId?: number) => `${this.admin(memberId)}/roles`;
    public static adminOrganizationRoles = (memberId?: number) => `${this.admin(memberId)}/organization-roles`;
    public static adminLogs = (memberId?: number) => `${this.admin(memberId)}/logs`;
    public static adminTokens = (memberId?: number) => `${this.admin(memberId)}/tokens`;

    public static meetings = (memberId?: number) => `${this.authAs(memberId)}/meetings`;
    public static meetingsMy = (memberId?: number) => `${this.meetings(memberId)}/my`;
    public static meetingsMyDetail = (memberId?: number, detailId?: number) =>
        `${this.meetings(memberId)}/${detailId === undefined ? ':detailId' : detailId}`;
    public static meetingsCurrent = (memberId?: number) => `${this.meetings(memberId)}/current`;
    public static meetingsPlanner = (memberId?: number) => `${this.meetings(memberId)}/planner`;
    public static meetingsAdmin = (memberId?: number) => `${this.meetings(memberId)}/admin`;
    public static meetingsNew = (memberId?: number) => `${this.meetings(memberId)}/new`;
    public static meetingsEdit = (memberId?: number, detailId?: number) =>
        `${this.meetings(memberId)}/edit/${detailId === undefined ? ':detailId' : detailId}`;

    // Others
    public static backlinkArg = () => `backlink`;
    public static authBacklink = (from: string, to: string) => `${from}?${this.backlinkArg()}=${to}`;
}
