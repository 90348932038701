import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import ReactQuill from 'react-quill';
import { Translate } from '../../utils/Translate';
import { ErrorDisplay } from './ErrorDisplay';

interface IFormattedRenderProps {
    content: string;
}

export function FormattedRender(props: IFormattedRenderProps) {
    const { content } = props;
    const match = content.match(/^!([a-z]*)\s(.*)/s);
    if (match) {
        const [, type, content] = match;
        switch (type) {
            /* text, html, quill, md */
            case 'quill':
                const json = JSON.parse(content);
                return (
                    <div className="wysiwyg-content">
                        <ReactQuill
                            theme="bubble"
                            defaultValue={json as any}
                            readOnly={true}
                            ref={(obj) => {
                                if (!obj) return;
                                const editor = obj.getEditor().root;
                                if (!editor) return;
                                editor.className = '';
                                const container = editor.parentElement!;
                                if (!container) return;
                                container.className = '';
                                const clipboard = container.querySelector('.ql-clipboard');
                                if (clipboard) container.removeChild(clipboard);
                                const tooltip = container.querySelector('.ql-tooltip');
                                if (tooltip) container.removeChild(tooltip);
                            }}
                        />
                    </div>
                );
            case 'html':
                return <div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: content }} />;
            case 'text':
                return <div className="wysiwyg-content">{content}</div>;
            case 'md':
                return (
                    <div className="wysiwyg-content">
                        <ReactMarkdown>{content}</ReactMarkdown>
                    </div>
                );
        }
    }
    console.error('Invalid content format', content);
    return (
        <ErrorDisplay
            error={Translate.message('error.invalidContentFormat', 'Invalid content format', {
                type: match ? match[1] : 'unset',
            })}
        />
    );
}
